// import inspection from "src/store/sagas/network/watchers/inspection";
import {
  StrategicPartnerService,
  LocalityService
} from "./modules";
import { QuestionnaireState } from "../components/organisms/Forms/QuestionnaireForm";

export interface FormattedAnswer {
  id?: string;
  inspectionId: string;
  questionId: string;
  caseId?: string;
  value: string;
  comment?: string;
  src?: string;
}

type FormattedAnswers = FormattedAnswer[];

class Ecogas {
  private token: string;
  private scheme: "https://" | "http://" = "https://";
  private host?: string = process.env.SERVER_ENDPOINT;

  public constructor(token: string) {
    this.token = token;
  }

  public strategicPartners = () => {
    const sps = new StrategicPartnerService(this.token);
    const strategicPartnersCalls = sps.strategicPartners();
    return strategicPartnersCalls;
  }

  /**
   * Allow to access to the localities service calls
   */
  public localities = () => {
    const localities = new LocalityService(this.token);
    const localityCalls = localities.locality();
    return localityCalls;
  }

  public inspections = () => {
    const endpoint = "/inspections";

    return {
      getAll: () => this.GETInspections(endpoint),
      getOne: (inspectionId: string) => this.GETInspection(endpoint, inspectionId),
      postAnswers: (inspectionId: string, answers: FormattedAnswers) =>
        this.POSTInspectionAnswers(endpoint, inspectionId, answers),
      post: (body: object) => this.POSTInspection(endpoint, body),
      delete: (inspectionId: string) => this.DELETEInspection(endpoint, inspectionId),
      uploadImage: (body: object) => this.POSTImage(body),
    };
  }

  public questionnaires = () => {
    const endpoint = "/questionnaires";

    return {
      getAll: () => this.GETQuestionnaires(endpoint),
      getOne: (questionnaireId: string) => this.GETQuestionnaire(endpoint, questionnaireId),
      post: (body: QuestionnaireState) => this.POSTQuestionnaire(endpoint, body),
      delete: (questionnaireId: string) => this.DELETEQuestionnaire(endpoint, questionnaireId)
    };
  }

  public cases = () => {
    const endpoint = "/cases";

    return {
      getAll: () => this.GETCases(endpoint),
      getOne: (caseId: string) => this.GETCase(endpoint, caseId),
      post: (body: object) => this.POSTCase(endpoint, body),
      update: (caseId: string, body: object) => this.PUTCase(endpoint, caseId, body),
      delete: (caseId: string) => this.DELETECase(endpoint, caseId)
    };
  }

  public ACRs = () => {
    const endpoint = "/acrs";

    return {
      getAll: () => this.GETACRs(endpoint),
      getOne: (acrId: string) => this.GETACR(endpoint, acrId),
      post: (body: object) => this.POSTACR(endpoint, body),
      update: (acrId: string, body: object) => this.PUTACR(endpoint, acrId, body),
      delete: (acrId: string) => this.DELETEACR(endpoint, acrId)
    };
  }

  private GETInspections = (endpoint: string) => {
    return this.fetch(`${endpoint}?_expand=locality&_embed=answers&_delay=1000`);
  }

  private GETInspection = (endpoint: string, inspectionId: string) => {
    return this.fetch(`${endpoint}/${inspectionId}?_expand=questionnaire&_embed=answers&_delay=1000`);
  }

  private POSTInspectionAnswers = (endpoint: string, inspectionId: string, answers: FormattedAnswers) => {
    return this.fetch(`${endpoint}/${inspectionId}/answers?_delay=1000`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(answers)
    });
  }

  private POSTInspection = (endpoint: string, body: object) => {
    return this.fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  private POSTCase = (endpoint: string, body: object) => {
    return this.fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...body, status: "OPEN" })
    });
  }

  private POSTACR = (endpoint: string, body: object) => {
    return this.fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  private PUTACR = (endpoint: string, acrId: string, body: object) => {
    return this.fetch(`${endpoint}/${acrId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  private GETQuestionnaires = (endpoint: string) => {
    return this.fetch(`${endpoint}?_embed=sections&_delay=1000`);
  }

  private GETQuestionnaire = (endpoint: string, qId: string) => {
    return this.fetch(`${endpoint}/${qId}?_embed=sections&_delay=1000`);
  }

  private POSTQuestionnaire = (endpoint: string, questionnaire: QuestionnaireState) => {
    const q = {
      id: questionnaire.id,
      title: questionnaire.title,
      description: questionnaire.description
    };

    return this.fetch(`${endpoint}?_delay=1000`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(q)
    })
      .then((response) => {
        const sectionsValues = Object.values(questionnaire.sections);
        const questionsValues = Object.values(questionnaire.questions);

        sectionsValues.forEach((section: any) => {
          const sectionQuestions = questionsValues.filter((question) => question.sectionId === section.id);
          section.questions = sectionQuestions;
        });

        return this.fetch(`/sections?_delay=1000`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(sectionsValues)
        })
          .then(() => response);
      });
  }

  private DELETEQuestionnaire = (endpoint: string, questionnaireId: string) => {
    return this.fetch(`${endpoint}/${questionnaireId}`, {
      method: "DELETE"
    });
  }

  private GETCases = (endpoint: string) => {
    return this.fetch(`${endpoint}`);
  }

  private GETCase = (endpoint: string, caseId: string) => {
    return this.fetch(`${endpoint}/${caseId}`);
  }

  private PUTCase = (endpoint: string, caseId: string, body: object) => {
    return this.fetch(`${endpoint}/${caseId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
  }

  private DELETECase = (endpoint: string, caseId: string) => {
    return this.fetch(`${endpoint}/${caseId}`, {
      method: "DELETE"
    });
  }

  private DELETEInspection = (endpoint: string, inspectionId: string) => {
    return this.fetch(`${endpoint}/${inspectionId}`, {
      method: "DELETE"
    });
  }

  private POSTImage = (body: object) => {
    const data = new FormData();
    data.append("data", JSON.stringify(body));

    return fetch(`http://www.mocky.io/v2/5d1fb0f8310000a4b4ebee5a`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      body: data
    })
      .then((response) => response.json().then((json) => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok) {
          return Promise.reject(json);
        }

        return json;
      })
      .then((response) => ({ response }))
      .catch((error) => ({ response: undefined, error }));
  }

  private GETACRs = (endpoint: string) => {
    return this.fetch(`${endpoint}?_embed=crs&_delay=500`);
  }

  private GETACR = async (endpoint: string, acrId: string) => {
    return this.fetch(`${endpoint}/${acrId}?_delay=500`);
  }

  private DELETEACR = (endpoint: string, acrId: string) => {
    return this.fetch(`${endpoint}/${acrId}`, {
      method: "DELETE"
    });
  }

  private fetch = (endpoint: string, fetchOptions?: object) => {
    return fetch(`${this.scheme}${this.host}${endpoint}`, fetchOptions)
      .then((response) => response.json().then((json) => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok) {
          return Promise.reject(json);
        }

        return json;
      })
      .then((response) => ({ response }))
      .catch((error) => ({ response: undefined, error }));
  }
}

export default Ecogas;
