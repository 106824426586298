import React, { Fragment } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { RootState } from "../../store/modules";
import { withPageTitle } from "../HOCs";

import { actions as strategicPartnerActions } from "../../store/modules/strategicPartner/actions";
import { actions as UIActions } from "../../store/modules/UI/actions";
import * as StrategicPartnerSelectors from "../../store/modules/strategicPartner/selectors";
import StrategicPartnerDetailsTemplate from "../templates/StrategicPartnerDetailsTemplate";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type StrategicPartnerDetailsPageProps = StateProps & DispatchProps & RouteChildrenProps<{id: string}>;

/**
 * Shows de details of an strategic partner. It includes
 * a table of casos tracking cases
 */
class StrategicPartnerDetailsPage extends React.Component<StrategicPartnerDetailsPageProps> {
  public componentDidMount() {
    const { loadStrategicPartnerDetails, match } = this.props;
    loadStrategicPartnerDetails(match.params.id, "STRATEGIC_PARTNER_DETAILS");
  }

  public render() {
    return (
      <Fragment>
        <StrategicPartnerDetailsTemplate {...this.props} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: RootState, props: RouteChildrenProps<{ id: string }>) => ({
  strategicPartner: StrategicPartnerSelectors.selectStrategicPartner(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadStrategicPartnerDetails: strategicPartnerActions.loadStrategicPartner.request,
    setComponentState: UIActions.setComponentState
  }, dispatch);

const ConnectedStrategicPartnerDetailsPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPageTitle(StrategicPartnerDetailsPage,
    "Detalles del socio estratégico")); // This is a default page tab title

export default ConnectedStrategicPartnerDetailsPage;
