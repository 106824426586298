import { Reducer } from "redux";
import { message } from "antd";
import { getType } from "typesafe-actions";

import copy from "../../../copy.json";
import { bugsnagClient } from "../../../bugsnag";
import { Inspection } from "../../../types/Inspection";
import { actions, InspectionAction } from "./actions";

export interface InspectionState {
  [key: string]: Inspection;
}

export const initialState: InspectionState = {};

const inspectionReducer: Reducer<InspectionState, InspectionAction> =
  (state = initialState, action: InspectionAction) => {
    switch (action.type) {
      // LOAD_INSPECTIONS
      case getType(actions.loadInspections.success): {
        const { inspections } = action.payload;

        return {
          ...state,
          ...inspections
        };
      }
      case getType(actions.loadInspections.error): {
        message.error(copy.inspections.loadError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      case getType(actions.loadInspection.success): {
        const { inspection } = action.payload;

        return {
          ...state,
          ...inspection
        };
      }
      case getType(actions.loadInspection.error): {
        message.error(copy.inspections.singleLoadError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      // CREATE_INSPECTION
      case getType(actions.createInspection.success): {
        const { inspection } = action.payload;

        return {
          ...state,
          ...inspection
        };
      }
      case getType(actions.createInspection.error): {
        message.error(copy.inspections.postError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      // DELETE_INSPECTION
      case getType(actions.deleteInspection.success): {
        const { inspectionId } = action.payload;
        const { [inspectionId]: deleted, ...newState } = state;

        return newState;
      }
      case getType(actions.deleteInspection.error): {
        message.error(copy.inspections.deleteError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      default:
        return state;
    }
  };

export default inspectionReducer;
