import React from "react";
import { Button, Tag, Modal } from "antd";
import format from "date-fns/format";
import ESLocale from "date-fns/locale/es";

import styles from "./styles.less";
import {
  Content,
  TableContainer,
  Table,
  TableFooter,
  LoadingContent
} from "../../atoms";
import { PageHeading } from "../../molecules";
import { InspectionDetailsPageProps } from "../../pages/InspectionDetailsPage";
import { Inspection } from "../../../types/Inspection";
import { Section, AnswerType } from "../../../types/Questionnaire";
import { QuestionnaireTable } from "./components";
import withUIState, { ComponentProps } from "../../HOCs/withUIState";

type TemplateProps = InspectionDetailsPageProps;

interface TemplateState {
  currentSectionIdx: string;
  answers: {
    [key: string]: {
      value: AnswerType;
      comment?: string;
      src?: any;
      caseId?: string;
      _case?: object;
    }
  };
}

const PlainSaveButton: React.FC<{ onClick: () => void } & ComponentProps> = ({ onClick, ...props }) => (
  <Button
    id="saveInspectionBtn"
    onClick={onClick}
    type="primary"
    size="large"
    loading={props.componentState.loading}
    style={{ flex: 2, borderRadius: 8 }}
    block
  >
    Guardar
  </Button>
);

const SaveButton = withUIState("SAVE_INSPECTION", PlainSaveButton);

class InspectionDetailsTemplate extends React.Component<TemplateProps, TemplateState> {
  public state: TemplateState = {
    currentSectionIdx: "0",
    answers: {}
  };

  public componentDidMount() {
    this.setQuestionnaireAnswers();
  }

  public componentDidUpdate() {
    this.setQuestionnaireAnswers();
  }

  public render() {
    const { currentSectionIdx, answers } = this.state;
    const { inspection, questionnaire } = this.props;

    if (inspection && questionnaire) {
      const totalAnswers = Object.keys(answers).filter((answer) => (answers[answer] || {}).value !== "null");
      const sections = questionnaire.sections || [];
      const currentSection = this.getCurrentSection();
      const questions = (sections as Section[]).map((section) => section.questions).flat();

      return (
        <Content>
          <PageHeading
            title={`Inspección ${inspection.folio}`}
            renderRight={() => (
              <div style={{ display: "flex", width: 320 }}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="delete"
                  style={{ marginRight: 20 }}
                  onClick={this.showDeleteConfirm}
                />
                <SaveButton onClick={this.handleOnSave} />
                <Button
                  onClick={() => this.handleOnClose(totalAnswers)}
                  size="large"
                  style={{ marginLeft: 20, flex: 1, borderRadius: 8 }}
                  block
                >
                  Cerrar
                </Button>
              </div>
            )}
          />
          <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 20 }}>
              {this.renderHeaderTable(inspection)}
            </div>
            <TableContainer>
              <div className={styles.sectionHeader}>
                <div style={{ padding: "10px 15px" }}>
                  Sección {parseInt(currentSectionIdx, 10) + 1} – {currentSection && currentSection.title}
                </div>
                {!this.isCurrentSectionComplete() && (
                  <div>
                    <Button
                      type="link"
                      size="large"
                      onClick={this.setCurrentSectionAsComplete}>
                      Marcar sección como completada
                  </Button>
                  </div>
                )}
              </div>
              <QuestionnaireTable
                questionnaire={questionnaire}
                currentSectionIdx={currentSectionIdx}
                answers={answers}
                onAnswerChange={this.setAnswer}
                onAnswerCommentChange={this.setAnswerComment}
                onAnswerPhotoChange={this.setAnswerPhoto}
                onAnswerCaseChange={this.setAnswerCase}
              />
              <TableFooter
                label={`preguntas de sección ${parseInt(currentSectionIdx, 10) + 1}`}
                showing={currentSection && currentSection.questions.length}
                total={questions.length}
                pagination={{ total: ((questionnaire.sections || []).length) * 10 }}
                onChange={(page) => this.setState({ currentSectionIdx: String(page - 1) })}
              />
            </TableContainer>
          </div>
        </Content>
      );
    }

    return <LoadingContent />;
  }

  private handleOnSave = () => {
    const { answers } = this.state;
    const { inspection, saveInspectionAnswers } = this.props;

    if (inspection) {
      saveInspectionAnswers(inspection.id, answers, "SAVE_INSPECTION");
    }
  }

  private handleOnClose = (totalAnswers: string[]) => {
    const { push } = this.props;
    if (totalAnswers.length > 0) {
      return Modal.confirm({
        title: "¿Estás seguro que deseas cerrar esta inspección?",
        content: "Los cambios hechos a esta inspección no serán guardados si procedes.",
        okText: "Cerrar",
        cancelText: "Cancelar",
        onOk() {
          push("/inspecciones");
        },
        onCancel() {
          return;
        }
      });
    }

    return push("/inspecciones");
  }

  private setQuestionnaireAnswers = () => {
    const { answers } = this.state;
    const { questionnaire, inspectionAnswers } = this.props;

    if (Object.keys(answers).length === 0) {
      if (questionnaire && questionnaire.sections.length > 0) {
        const sections = questionnaire.sections;
        const questions = (sections as Section[]).map((section: Section) => section.questions).flat();

        const ans = {};
        const questionValues = Object.values(questions);
        questionValues.map((question) => {
          const answer = inspectionAnswers.find((a) => a.questionId === String(question.id));
          const answerValue = answer && String(answer.value);
          const answerComment = answer && answer.comment;
          const answerSrc = answer && answer.src;
          const answerCase = answer && answer.caseId;

          ans[question.id] = {
            value: answerValue || "null",
            comment: answerComment,
            src: answerSrc,
            caseId: answerCase,
          };
        });

        this.setState({ answers: ans });
      }
    }
  }

  private renderHeaderTable = (inspection: Inspection) => (
    <Table
      pagination={false}
      size="middle"
      columns={
        [
          {
            title: "Tipo",
            dataIndex: "type"
          },
          {
            title: "Inspeccionado",
            dataIndex: "inspected"
          },
          {
            title: "Fecha de inspección",
            dataIndex: "completedOn",
            render: (date: string) => date
              ? format(date, "DD [de] MMMM [de] YYYY", { locale: ESLocale })
              : <Tag color="volcano">No concluida</Tag>
          },
          {
            title: "Localidad",
            dataIndex: "localityId"
          },
          {
            title: "Supervisor Ecogas",
            dataIndex: "supervisor"
          },
          {
            title: "Trabajadores observados",
            dataIndex: "observedWorkers"
          }
        ]
      }
      dataSource={
        [
          {
            type: inspection.title,
            inspected: inspection.inspected,
            completedOn: inspection.completedOn,
            localityId: inspection.localityId,
            supervisor: inspection.doneBy,
            observedWorkers: inspection.observedWorkers
          }
        ]
      }
    />
  )

  private setCurrentSectionAsComplete = () => {
    const currentSection = this.getCurrentSection();
    const answerIds = currentSection && currentSection.questions.map((question) => question.id);
    const answers = {};

    if (answerIds) {
      answerIds.map((answerId) => answers[answerId] = { value: "true" });
    }

    this.setState((state) => ({
      answers: {
        ...state.answers,
        ...answers
      }
    }));
  }

  private isCurrentSectionComplete = () => {
    const { answers } = this.state;
    const currentSection = this.getCurrentSection();
    const answerIds = currentSection && currentSection.questions.map((question) => question.id);
    return answerIds && answerIds.every((answerId) => (answers[answerId] || {}).value === "true");
  }

  private setAnswer = (questionId: string, value: AnswerType) => {
    this.setState((state) => ({
      answers: {
        ...state.answers,
        [questionId]: {
          ...(state.answers[questionId] || {}),
          value
        }
      }
    }));
  }

  private setAnswerComment = (questionId: string, comment?: string) => {
    this.setState((state) => ({
      answers: {
        ...state.answers,
        [questionId]: {
          ...(state.answers[questionId] || {}),
          comment
        }
      }
    }));
  }

  private setAnswerCase = (questionId: string, caseId: string, _case: object, isDelete?: boolean) => {
    if (!isDelete)
      this.setState((state) => ({
        answers: {
          ...state.answers,
          [questionId]: {
            ...(state.answers[questionId] || {}),
            caseId,
            _case,
          }
        }
      }));
    else {
      const { answers } = this.state;
      delete answers[questionId].caseId;
      delete answers[questionId]._case;

      this.setState((state) => ({
        answers: {
          ...state.answers,
          [questionId]: {
            ...(state.answers[questionId] || {})
          }
        }
      }));
    }
  }

  private getCurrentSection = (): Section | undefined => {
    const { currentSectionIdx } = this.state;
    const { questionnaire } = this.props;

    if (questionnaire) {
      const sections = questionnaire.sections || [];
      return sections[currentSectionIdx];
    }

    return undefined;
  }

  private showDeleteConfirm = () => {
    const onDelete = this.onDelete;

    Modal.confirm({
      title: '¿Seguro que deseas eliminar esta inspección?',
      content: 'Este proceso es irreversible, ¿Quieres proseguir con el borrado?',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        onDelete();
      },
    });
  }

  private onDelete = () => {
    const { inspection, deleteInspection } = this.props;

    if (inspection)
      deleteInspection(inspection.id);
  }

  private setAnswerPhoto = (questionId: string, file: File) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onloadend = () => {
      this.setState((state) => ({
        answers: {
          ...state.answers,
          [questionId]: {
            ...(state.answers[questionId] || {}),
            src: reader.result
          }
        }
      }));
    }
  }
}

export default InspectionDetailsTemplate;
