import { Reducer } from "redux";
import { getType } from "typesafe-actions";

import { Locality } from "../../../types/Locality";
import { actions, LocalityAction } from "./actions";

export interface LocalityState {
  [key: number]: Locality;
}

const initialState: LocalityState = {};

/**
 * Reduct the locality state
 * @param state   Acumulated state
 * @param action  Action to reduct the state
 */
const localityReducer: Reducer<LocalityState, LocalityAction> =
  (state = initialState, action: LocalityAction) => {
    switch (action.type) {
      case getType(actions.loadLocalities.success): {
        const { localities } = action.payload;

        return {
          ...state,
          ...localities
        };
      }
      case getType(actions.loadLocality.success): {
        const { locality } = action.payload;

        return {
          ...state,
          ...locality
        };
      }
      default:
        return state;
    }
  };

export default localityReducer;
