import React from "react";
import { ConfigProvider, Select, Empty, Spin } from "antd";
import { SelectProps } from "antd/lib/select";

import withUIState, { ComponentProps } from "../../HOCs/withUIState";

interface AsyncSelectOwnProps {
  optionValueExtractor?: () => void;
}

type AsyncSelectProps = AsyncSelectOwnProps & ComponentProps & SelectProps;

class AsyncSelect extends React.Component<AsyncSelectProps> {
  public render() {
    const { children, componentState, ...selectProps } = this.props;

    return (
      <ConfigProvider renderEmpty={() => this.renderEmptySelect(componentState.loading)}>
        <Select
          showSearch
          filterOption={(input, option) => {
            if (option.props.children && typeof option.props.children === "string") {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }

            return false;
          }}
          {...selectProps}
          loading={componentState.loading}
        >
          {children}
          {componentState.loading && <Select.Option value="_" disabled><Spin /> Cargando más opciones</Select.Option>}
        </Select>
      </ConfigProvider>
    );
  }

  private renderEmptySelect = (loading: boolean) => (
    <Spin spinning={loading}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sin opciones" />
    </Spin>
  )
}

export default (componentId: string) => withUIState(componentId, AsyncSelect);
