import { createSelector } from "reselect";

import { RootState } from "..";

export const selectUIState = (state: RootState) => state.UI;

export const selectComponentState = (componentId: string) =>
  createSelector(
    [selectUIState],
    (state) => state[componentId] || {}
  );
