import React from "react";

import UsersTemplate from "../templates/UsersTemplate";

class UsersPage extends React.Component {
  public componentDidMount() {
    window.document.title = window.document.title + " - Usuarios";
  }

  public componentWillUnmount() {
    window.document.title = "Ecogas";
  }

  public render() {
    return <UsersTemplate />;
  }
}

export default UsersPage;
