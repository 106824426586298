import * as React from "react";

const withPageTitle = <P extends object>(WrappedComponent: React.ComponentType<P>, title: string) => {
  return class extends React.Component<P> {
    public componentDidMount() {
      window.document.title = `Ecogas – ${title}`;
    }

    public componentWillUnmount() {
      window.document.title = "Ecogas";
    }

    public render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  };
};

export default withPageTitle;
