import React from "react";

import {
  Content,
  TableContainer,
  TableSearchInput,
  TableFooter
} from "../../atoms";
import { StrategicPartnersTable } from "./components";
import { PageHeading } from "../../molecules";
import { StrategicPartnersPageProps } from "../../pages/StrategicPartnersPage";
import { StrategicPartners } from "../../../types/StrategicPartner";

type StratregicPartnersTemplateProps = StrategicPartnersPageProps;

interface StrategicPartnersTemplateState {
  strategicPartnerFilterState: {
    selectedOption: string;
    processing: boolean;
    inputs?: {
      [key: string]: {
        value?: string;
      };
    }
  };
  strategicPartners: StrategicPartners;
}

class StrategicPartnersTemplate extends React.Component <
StratregicPartnersTemplateProps,
StrategicPartnersTemplateState> {

  public render() {
    const { strategicPartners } = this.props;
    return (
      <Content>
        <PageHeading title="Socios estratégicos" />
        <div style={{ padding: 30 }}>
          <TableContainer>
            <div style={{ padding: 30 }}>
              <TableSearchInput />
            </div>
            <StrategicPartnersTable strategicPartners={strategicPartners} />
            <TableFooter label="socios estratégicos" pagination={{ current: 1, total: 30 }} />
          </TableContainer>
        </div>
      </Content>
    );
  }
}

export default StrategicPartnersTemplate;
