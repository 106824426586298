import { normalize } from "normalizr";
import { all, call, fork, take, put, select } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { push } from "connected-react-router";

import { actions as UIActions } from "../../../modules/UI/actions";
import { actions as questionnaireActions } from "../../../modules/questionnaire/actions";
import { actions as sectionActions } from "../../../modules/section/actions";
import * as questionnaireSelectors from "../../../modules/questionnaire/selectors";
import { questionnaire, question } from "../../../../schemas";
import Ecogas from "../../../../services/Ecogas";
import { QuestionnaireState } from "../../../../components/organisms/Forms/QuestionnaireForm";

const EcogasAPI = new Ecogas("");

function* fetchQuestionnaires(componentId?: string) {
  const { response, error } = yield call(EcogasAPI.questionnaires().getAll);

  if (response) {
    const { questionnaires, sections } = normalize(response, [questionnaire]).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.loadQuestionnaires.success(questionnaires)),
      put(sectionActions.loadSections.success(sections))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.loadQuestionnaires.error(error))
    ]);
  }
}

function* fetchQuestionnaire(questionnaireId: string, componentId?: string) {
  const { response, error } = yield call(EcogasAPI.questionnaires().getOne, questionnaireId);

  if (response) {
    const { questionnaires, sections } = normalize(response, questionnaire).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.loadQuestionnaire.success(questionnaires)),
      put(sectionActions.loadSections.success(sections))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.loadQuestionnaire.error(error))
    ]);
  }
}

function* loadQuestionnaires(componentId?: string, page?: number, limit?: number) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { loading: true })),
    call(fetchQuestionnaires.bind(undefined), componentId)
  ]);
}

function* loadQuestionnaire(questionnaireId: string, componentId?: string, page?: number, limit?: number) {
  const singleQuestionnaire = yield select(questionnaireSelectors.selectQuestionnaireWithId(questionnaireId));

  if (!singleQuestionnaire) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: true })),
      call(fetchQuestionnaire.bind(undefined), questionnaireId, componentId)
    ]);
  }
}

function* createQuestionnaire(q: QuestionnaireState, componentId?: string) {
  if (componentId) {
    yield put(UIActions.setComponentState(componentId, { loading: true }));
  }

  const { response, error } = yield call(EcogasAPI.questionnaires().post, q);

  if (response) {
    const { questionnaires } = normalize(response, questionnaire).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.createQuestionnaire.success(questionnaires)),
      put(push("/cuestionarios")),
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.createQuestionnaire.error(error))
    ]);
  }
}

function* watchLoadQuestionnaires() {
  while (true) {
    const { payload } = yield take(getType(questionnaireActions.loadQuestionnaires.request));
    yield fork(loadQuestionnaires, payload.componentId);
  }
}

function* watchLoadQuestionnaire() {
  while (true) {
    const { payload } = yield take(getType(questionnaireActions.loadQuestionnaire.request));
    yield fork(loadQuestionnaire, payload.questionnaireId, payload.componentId);
  }
}

function* watchCreateQuestionnaire() {
  while (true) {
    const { payload } = yield take(getType(questionnaireActions.createQuestionnaire.request));
    yield fork(createQuestionnaire, payload.questionnaire, payload.componentId);
  }
}

//#region :::::: DELETE QUESTIONNAIRE ::::::
function* deleteQuestionnaire( questionnaireId: string, componentId?: string, page?: number, limit?: number ) {
  let { response, error } = yield call(EcogasAPI.questionnaires().delete, questionnaireId);

  if(response !== undefined) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.deleteQuestionarie.success(questionnaireId)),
      put(push("/cuestionarios"))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(questionnaireActions.loadQuestionnaire.error(error))
    ]);
  }
}

function* watchDeleteQuestionnaire() {
  while (true) {
    const { payload } = yield take(getType(questionnaireActions.deleteQuestionarie.request));
    yield fork(deleteQuestionnaire, payload.questionnaireId, payload.componentId);
  }
}
//#endregion

export default [
  fork(watchLoadQuestionnaires),
  fork(watchLoadQuestionnaire),
  fork(watchCreateQuestionnaire),
  fork(watchDeleteQuestionnaire)
];
