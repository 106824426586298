import React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { push as pushAction } from "connected-react-router";
import { Tag } from "antd";
import { ColumnProps } from "antd/lib/table/interface";
import format from "date-fns/format";
import ESLocale from "date-fns/locale/es";

import { Inspections, Inspection } from "../../../../types/Inspection";
import { Table } from "../../../atoms";
import { INSPECTION_ACTIVITIES } from "../../../../utils/maps";
import { default as withUIState, ComponentProps } from "../../../HOCs/withUIState";

interface IInspectionsTableProps {
  inspections: Inspections;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type InspectionsTableProps = IInspectionsTableProps & DispatchProps & ComponentProps;

class InspectionsTable extends React.Component<InspectionsTableProps> {
  private tableColumns: Array<ColumnProps<any>> = [
    {
      title: "FOLIO",
      dataIndex: "folio",
      key: "folio",
      align: "center"
    },
    {
      title: "TITULO",
      dataIndex: "title",
      key: "title",
      align: "center"
    },
    {
      title: "REALIZÓ",
      dataIndex: "doneBy",
      key: "doneBy",
      align: "center"
    },
    {
      title: "INSPECCIONADO",
      key: "inspected",
      dataIndex: "inspected",
      align: "center"
    },
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (name: string) => INSPECTION_ACTIVITIES[name]
    },
    {
      title: "LOCALIDAD",
      dataIndex: "localityId",
      key: "localityId",
      align: "center"
    },
    {
      title: "FECHA",
      dataIndex: "completedOn",
      key: "completedOn",
      align: "center",
      render: (date: number) => date
        ? format(date, "DD [de] MMMM [de] YYYY", { locale: ESLocale })
        : <Tag color="volcano">No concluida</Tag>
    },
    {
      title: "HALLAZGOS",
      dataIndex: "findings",
      key: "findings",
      align: "center",
      render: (findings: number) => (
        <Tag color={findings > 1 ? "magenta" : undefined}>{findings}</Tag>
      )
    }
  ];

  public render() {
    const { inspections, push, componentState } = this.props;

    return (
      <Table
        loading={componentState.loading}
        pagination={false}
        columns={this.tableColumns}
        rowKey={(_, idx) => String(idx)}
        onRowClick={(record: Inspection) => push(`/inspecciones/${record.id}`)}
        dataSource={Object.values(inspections)}
        locale={{ emptyText: "Sin datos" }}
        scroll={{ x: true }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    push: pushAction
  }, dispatch);

const ConnectedInspectionsTable = connect(undefined, mapDispatchToProps)(InspectionsTable);

export default withUIState("INSPECTIONS_TABLE", ConnectedInspectionsTable);
