import React from "react";
import {
  Button,
  Tabs,
  DatePicker,
  Col,
  Row,
  Form,
  Input,
  Radio,
  Progress,
  Collapse,
  Modal
} from "antd";
import moment from "moment";

import { Content, TableContainer, Title, LoadingContent } from "../../atoms";
import { ACRDetailsPageProps } from "../../pages/AcrDetailsPage";
import { PageHeading } from "../../molecules";
import { ACRModal } from "../../organisms/Modals";
import styles from "./stylus.less";

type TemplateProps = ACRDetailsPageProps;

class AcrDetailsTemplate extends React.Component<TemplateProps> {
  private dateFormat = "DD [de] MMMM [de] YYYY";

  public render() {
    const { ACR, updateACR } = this.props;

    if (ACR) {
      return (
        <>
          <ACRModal ACR={ACR} editing submitAction={updateACR} />
          <Content>
            <PageHeading
              title={`ACR - ${ACR.title}`}
              backTo="../acrs"
              renderRight={() => (
                <div>
                  <Button
                    type="primary"
                    shape="circle"
                    icon="delete"
                    style={{ marginRight: 20 }}
                    onClick={this.onClickDeleteACR}
                  />
                  <Button
                    type="primary"
                    shape="circle"
                    icon="edit"
                    onClick={this.onClickEditACR}
                  />
                </div>
              )}
            />
            <div style={{ padding: 30 }}>
              <TableContainer>
                <div style={{ padding: "30px 5px" }}>
                  <Tabs type="card">
                    <Tabs.TabPane tab="Información" key="1">
                      <div style={{ padding: 15 }}>
                        <Form>
                          <Row>
                            <Col span={12}>
                              <Title size={26}> Información general de ACR </Title>
                            </Col>
                            <Col span={12}>
                              <Form.Item>
                                <DatePicker
                                  value={moment(new Date(ACR.date), this.dateFormat)}
                                  format={this.dateFormat}
                                  style={{ float: "right" }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <div style={{ marginRight: 200 }}>
                            <Row>
                              <Col span={12}>
                                <h3>Titulo</h3>
                                <Form.Item style={{ marginRight: 20 }} >
                                  <Input value={ACR.title} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <h3>Localidad</h3>
                                <Form.Item>
                                  <Input value={ACR.localityId} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <h3>Reportó</h3>
                                <Form.Item style={{ marginRight: 20 }} >
                                  <Input value={ACR.reportedBy} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <h3>Departamento o socio estratégico involucrado</h3>
                                <Form.Item>
                                  <Input value={ACR.implicatedId} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <h3>Descripción</h3>
                                <Form.Item>
                                  <Input value={ACR.description} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <h3>¿Cómo sucedió?</h3>
                                <Form.Item>
                                  <Input value={ACR.how} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <h3>¿Dónde sucedió?</h3>
                                <Form.Item>
                                  <Input value={ACR.location} />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <h3>Existió lesión</h3>
                                <Form.Item>
                                  <Radio.Group value={ACR.injury}>
                                    <Radio value={true}>Si</Radio>
                                    <Radio value={false}>No</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <h3>Resumen</h3>
                                <span className={styles.subTitle}> 3 / 6 Acciones resueltas</span>
                                <Progress
                                  strokeColor="#52c41a"
                                  percent={50}
                                  showInfo={false}
                                  strokeWidth={15}
                                  status="active"
                                  style={{ width: "30%" }}
                                />
                                <span className={styles.subTitle}> 50% Completado </span>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </div>
                    </Tabs.TabPane>
                    {
                      ACR.CRs && ACR.CRs.map((cr, index) =>
                        <Tabs.TabPane tab={`CR ${index + 1}`} key={String(index + 2)}>
                          <div style={{ padding: 15 }}>
                            <Form>
                              <Row>
                                <Col span={24}>
                                  <Title size={26}>{`Causa raíz ${index + 1}`}</Title>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <Form.Item>
                                    <Input.TextArea
                                      style={{ marginTop: 15 }}
                                      value={cr.description}
                                      placeholder={"Describe a detalle la causa raíz"}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <Collapse defaultActiveKey={["1"]}>
                                    {/* TODO: map of ACR.crs.actions */}
                                    <Collapse.Panel header="Acción 1" key="1" >
                                      <div style={{ marginRight: 100 }}>
                                        <Form.Item style={{ marginBottom: 10 }}>
                                          <Input.TextArea
                                            autosize={{ minRows: 4 }}
                                            placeholder={"Escribe una descripción de la acción propuesta"}
                                          />
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 10 }}>
                                          <Input.TextArea placeholder={"¿Porqué se debe realizar esta acción"} />
                                        </Form.Item>
                                        <Form.Item style={{ marginBottom: 10 }}>
                                          <Input.TextArea placeholder={"¿Cómo se debe resolver esta acción"} />
                                        </Form.Item>
                                        <Row>
                                          <Col span={12}>
                                            <Form.Item style={{ marginBottom: 10, marginRight: 20 }}>
                                              <Input placeholder={"Selecciona responsable de la acción"} />
                                            </Form.Item>
                                          </Col>
                                          <Col span={12}>
                                            <Form.Item style={{ marginBottom: 10 }}>
                                              <Input placeholder={"Escribe el lugar de resolución"} />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col span={12}>
                                            <Form.Item style={{ marginRight: 20 }}>
                                              <DatePicker
                                                defaultValue={moment(new Date(), this.dateFormat)}
                                                format={this.dateFormat}
                                                style={{ width: "100%" }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Collapse.Panel>
                                  </Collapse>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </Tabs.TabPane>
                      )
                    }
                  </Tabs>
                </div>
              </TableContainer>
            </div>
          </Content>
        </>
      );
    }

    return <LoadingContent />;
  }

  private onClickEditACR = () => {
    const { setComponentState } = this.props;
    setComponentState("ACR_MODAL", { visible: true });
  }

  private onClickDeleteACR = () => {
    const { deleteACR, ACR } = this.props;

    if (ACR) {
      Modal.confirm({
        title: "Eliminar ACR",
        content: "Este proceso es irreversible, ¿Quieres proseguir con el borrado?",
        okText: "Eliminar",
        okType: "danger",
        onOk() {
          deleteACR(ACR.id, "DELETE_ACR_BUTTON");
        },
        cancelText: "Cancelar"
      });
    }
  }
}

export default AcrDetailsTemplate;
