import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { push } from "connected-react-router";

import { RootState } from "../../store/modules";
import { actions as caseActions } from "../../store/modules/case/actions";
import * as caseSelectors from "../../store/modules/case/selectors";
import { CaseDetailsTemplate } from "../templates";
import withPageTitle from "../../components/HOCs/withPageTitle";
import { actions as UIActions } from "../../store/modules/UI/actions";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type CaseDetailsPageProps = StateProps & DispatchProps & RouteChildrenProps<{ id: string }>;

class CaseDetailsPage extends React.Component<CaseDetailsPageProps> {
  public componentDidMount() {
    const { _case, loadCase, match } = this.props;
    if (!_case && match) {
      loadCase(match.params.id);
    }
  }

  public render() {
    return (
      <CaseDetailsTemplate {...this.props} />
    );
  }
}

const mapStateToProps = (state: RootState, props: any) => ({
  _case: caseSelectors.selectCase(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadCase: caseActions.loadCase.request,
    updateCase: caseActions.updateCase.request,
    deleteCase: caseActions.deleteCase.request,
    setComponentState: UIActions.setComponentState,
    push
  }, dispatch);

const ConnectedCaseDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CaseDetailsPage);

export default withPageTitle(ConnectedCaseDetailsPage, "Casos de seguimiento");
