import { History } from "history";
import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";
import { connectRouter } from "connected-react-router";

// Import reducers
import { default as caseReducer } from "./case/reducer";
import { default as inspectionReducer } from "./inspection/reducer";
import { default as localityReducer } from "./locality/reducer";
import { default as UIReducer } from "./UI/reducer";
import { default as QuestionnaireReducer } from "./questionnaire/reducer";
import { default as SectionReducer } from "./section/reducer";
import { default as AnswerReducer } from "./answer/reducer";
import { default as ACRReducer } from "./ACR/reducer";
import { default as strategicPartnerReducer } from "./strategicPartner/reducer";

// Combine reducers
const createRootReducer = (history: History) => combineReducers({
  inspection: inspectionReducer,
  case: caseReducer,
  locality: localityReducer,
  UI: UIReducer,
  questionnaire: QuestionnaireReducer,
  section: SectionReducer,
  answer: AnswerReducer,
  ACR: ACRReducer,
  strategicPartner: strategicPartnerReducer,
  router: connectRouter(history)
});

export type RootState = StateType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;
