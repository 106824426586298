import { normalize } from "normalizr";
import { all, call, fork, take, put } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { actions as UIActions } from "../../../modules/UI/actions";
import { actions as localityActions } from "../../../modules/locality/actions";
import {
  locality as localitySchema
 } from "../../../../schemas";
import Ecogas from "../../../../services/Ecogas";

const EcogasAPI = new Ecogas("");

//#region :::::: LOAD LOCALITIES ::::::
function* fetchLocalities(componentId?: string) {
  const { response, error } = yield call(EcogasAPI.localities().getAll);

  if (response) {
    const { localities } = normalize(response, [localitySchema]).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(localityActions.loadLocalities.success(localities))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(localityActions.loadLocalities.error(error))
    ]);
  }
}

function* loadLocalities(componentId?: string, page?: number, limit?: number) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { loading: true })),
    call(fetchLocalities, componentId)
  ]);
}

function* watchLoadLocalities() {
  while (true) {
    const { payload } = yield take(getType(localityActions.loadLocalities.request));
    yield fork(loadLocalities, payload.componentId);
  }
}
//#endregion

//#region :::::: LOAD LOCALITY ::::::
function* fetchLocality(localityId: string, componentId?: string) {
  const { response, error } = yield call(EcogasAPI.localities().getOne, localityId);
  if (response) {
    const { localities } = normalize(response, localitySchema).entities;
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(localityActions.loadLocality.success(localities))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(localityActions.loadLocality.error(error))
    ]);
  }
}

function* loadLocality(localityId: string, componentId?: string, page?: number, limit?: number) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { loading: true })),
    call(fetchLocality, localityId, componentId)
  ]);
}

function* watchLoadLocality() {
  while (true) {
    const { payload } = yield take(getType(localityActions.loadLocality.request));
    yield fork(loadLocality, payload.localityId, payload.componentId);
  }
}
//#endregion

export default [
  fork(watchLoadLocalities),
  fork(watchLoadLocality)
];
