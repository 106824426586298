import { normalize } from "normalizr";
import { all, call, fork, take, put, select } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import { actions as strategicPartnerActions } from "../../../modules/strategicPartner/actions";
import { actions as UIActions } from "../../../modules/UI/actions";

import * as strategicPartnerSelectors from "../../../modules/strategicPartner/selectors";

import {
  strategicPartner as strategicPartnerSchema,
  strategicPartners as strategicPartnersSchema } from "../../../../schemas";

import Ecogas from "../../../../services/Ecogas";
import StrategicPartner from "../../../../services/modules/StrategicPartnerService";
const EcogasAPI = new Ecogas("no token provided");

//#region :::::: LOAD STRATEGIC PARTNERS ::::::
/**
 * Generator which fetchs strategic partners
 * @param componentId
 */
function* fetchStrategicPartners(componentId?: string) {
  const { response, error } = yield EcogasAPI.strategicPartners().getAll();

  if (response) {
    const { strategicPartners } = normalize(response, [strategicPartnersSchema]).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(strategicPartnerActions.loadStrategicPartners.success(  strategicPartners  ))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(strategicPartnerActions.loadStrategicPartners.error(error))
    ]);
  }
}

/**
 * Executes the fetch function to load data
 * @param componentId
 * @param page
 * @param limit
 */
function* loadStrategicPartners(componentId?: string, page?: number, limit?: number) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { loading: true })),
    call(fetchStrategicPartners.bind(undefined), componentId)
  ]);
}

/**
 * This middlware it's attached to the actions, so it will be fired when the action is actioned
 */
function* watchLoadStrategicPartners() {
  while (true) {
    const { payload } = yield take(getType(strategicPartnerActions.loadStrategicPartners.request));
    yield fork(loadStrategicPartners, payload.componentId);
  }
}
//#endregion

//#region :::::: LOAD STRATEGIC PARTNER ::::::

/**
 * Generator which fetchs strategic partners
 * @param componentId
 */
function* fetchStrategicPartner(strategicPartnerId?: string, componentId?: string) {
  const { response, error } = yield EcogasAPI.strategicPartners().getOne(strategicPartnerId);

  if (response) {
    const partners = [response];
    const {strategicPartners} = normalize(partners, [strategicPartnersSchema]).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(strategicPartnerActions.loadStrategicPartner.success( strategicPartners))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(strategicPartnerActions.loadStrategicPartners.error(error))
    ]);
  }
}

/**
 * Executes the fetch function to load data
 * @param componentId
 * @param page
 * @param limit
 */
function* loadStrategicPartner(strategicPartnerId?: string, componentId?: string, page?: number, limit?: number) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { loading: true })),
    call(fetchStrategicPartner.bind(undefined), strategicPartnerId, componentId)
  ]);
}

/**
 * This middlware it's attached to the actions, so it will be fired when the action is actioned
 */
function* watchLoadStrategicPartner() {
  while (true) {
    const { payload } = yield take(getType(strategicPartnerActions.loadStrategicPartner.request));
    yield fork(loadStrategicPartner, payload.strategicPartnerId, payload.componentId);
  }
}
//#endregion

export default [
  fork(watchLoadStrategicPartners),
  fork(watchLoadStrategicPartner)
];
