import React from "react";

import { Content } from "../../atoms";
import { PageHeading } from "../../molecules";
import { LocalitiesTable } from "./components";
import { default as withUIState, ComponentProps } from "../../HOCs/withUIState";

import { Localities } from "../../../types/Locality";
import { LocalitiesPageProps } from "../../pages/LocalitiesPage";

interface ILocalitiesTemplateState {
  localities?: Localities;
}

type LocalitiesTemplateProps = LocalitiesPageProps
                              & ComponentProps
                              & ILocalitiesTemplateState;

class LocalitiesTemplate extends React.Component<LocalitiesTemplateProps> {

  public render() {
    const { localities } = this.props;

    return (
      <Content>
        <PageHeading title="Localidades" />
        <div style={{ padding: 30 }}>
          <LocalitiesTable localities={ localities }/>
        </div>
      </Content>
    );
  }
}

export default withUIState("LOCALITIES_LIST", LocalitiesTemplate);
