import React from "react";
import { Table as AntTable } from "antd";
import { TableProps } from "antd/lib/table";

import styles from "./styles.less";

const Table: React.FC<TableProps<any>> = (props) =>
  <AntTable className={styles.table} {...props} />;

export default Table;
