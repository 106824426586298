import React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { push as pushAction } from "connected-react-router";
import { ColumnProps } from "antd/lib/table/interface";

import {
  BussinessUnits
} from "../../../../types/BussinessUnit";

import { Table } from "../../../atoms";
import { default as withUIState, ComponentProps } from "../../../HOCs/withUIState";

interface IBussinessUnitsTableProps {
  bussinessUnits: BussinessUnits;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type BussinessUnitsTableProps = IBussinessUnitsTableProps & DispatchProps & ComponentProps;

class BussinessUnitsTable extends React.Component<BussinessUnitsTableProps> {

  // this is the definition of the table columns as Ant expects
  private tableColumns: Array<ColumnProps<any>> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "UNIDAD DE NEGOCIO",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      align: "center",
    },
    {
      title: "DEPARTAMENTOS",
      dataIndex: "departments",
      key: "departments",
      align: "center",
    }
  ];

  public render() {
    const { componentState, bussinessUnits } = this.props; // props given by the parent page
    return (
      <Table
        loading={ componentState.loading }
        pagination={false}
        columns={this.tableColumns}
        rowKey={(_, idx) => String(idx)}
        dataSource={ Object.values(bussinessUnits) } // this is needed because normalizr
        locale={{ emptyText: "Sin datos" }}
        scroll={{ x: false, y: false }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    push: pushAction
  }, dispatch);

const ConnectedBussinessUnitsTable = connect(undefined, mapDispatchToProps)(BussinessUnitsTable);

// SPECIFY STATE STORAGE TO USE WITH THIS COMPONENT
export default withUIState("LOCALITY_DETAILS", ConnectedBussinessUnitsTable);
