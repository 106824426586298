import React from "react";
import { Icon } from "antd";

import styles from "./styles.less";

interface PhotoThumbnailProps {
  onChangePhoto: (questionId: string, ev: any) => void;
  questionId: string;
  src?: string;
}

const PhotoThumbnail: React.FC<PhotoThumbnailProps> = ({ src, questionId, onChangePhoto }) => {
  return (
    <>
      <input
        accept=".jpg, .JPG, .png"
        id="thumbnail"
        style={{ display: "none", cursor: "pointer" }}
        type="file"
        onChange={(ev) => onChangePhoto("-1", ev)}
      />
      <label htmlFor="thumbnail" onClick={(ev) => onChangePhoto(questionId, ev)}>
        <div className={styles.thumbnailContainer}>
          {src
            ? (<img src={src} className={styles.photo} />)
            : (
              <div className={styles.photoIconContainer}>
                <Icon type="camera" theme="filled" style={{ fontSize: 36, color: "#BDBDBD" }} />
              </div>
            )}
        </div>
      </label>
    </>
  );
};

export default PhotoThumbnail;
