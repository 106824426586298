import { createSelector } from "reselect";
import { RouteChildrenProps } from "react-router";

import { RootState } from "..";
import { selectSectionState } from "../section/selectors";
import { Section } from "../../../types/Questionnaire";

export const selectQuestionnaireState = (state: RootState) => state.questionnaire;

export const selectQuestionnaireIdFromRouteProps =
  (state: RootState, props: RouteChildrenProps<{ id: string }>) =>
    props.match ? props.match.params.id : undefined;

export const selectQuestionnaireWithId = (questionnaireId: string) =>
  createSelector(
    [selectQuestionnaireState],
    (state) => state[questionnaireId]
  );

export const selectQuestionnaireWithIdFromProps = createSelector(
  [selectQuestionnaireState, selectSectionState, selectQuestionnaireIdFromRouteProps],
  (questionnaireState, sectionState, questionnaireId) => {
    if (questionnaireId) {
      const questionnaire = questionnaireState[questionnaireId];
      const sections: Section[] = [];

      if (questionnaire) {
        ((questionnaire.sections || []) as string[]).forEach((section) => {
          const s = sectionState[section];
          if (s) {
            sections.push(s);
          }
        });

        if (sections.length === (questionnaire.sections || []).length) {
          questionnaire.sections = sections;
          return questionnaire;
        }
      }

      return undefined;
    }

    return undefined;
  }
);

export const selectQuestionnaires = createSelector(
  [selectQuestionnaireState, selectSectionState],
  (questionnaires, sections) => {
    const q = Object.values(questionnaires);
    const results = [];

    while (q.length) {
      q.forEach((questionnaire) => {
        questionnaire.questions = [];
        ((questionnaire.sections || []) as string[]).forEach((section) => {
          const s = sections[section] || { questions: [] };
          const questions = Object.values(s.questions);
          questions.forEach((question) => (questionnaire.questions || []).push(question.id));
        });
      });
      results.push(q.splice(0, 3));
    }

    return results;
  }
);
