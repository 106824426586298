import React from "react";
import { Layout, Icon } from "antd";
import { NavLink } from "react-router-dom";

import styles from "./styles.less";
import Inspection from "../../../assets/icons/inspection.svg";
import Error from "../../../assets/icons/error.svg";
import Location from "../../../assets/icons/location.svg";
import Person from "../../../assets/icons/person.svg";
import Suitcase from "../../../assets/icons/suitcase.svg";
import Broken from "../../../assets/icons/broken.svg";
import Questionnaire from "../../../assets/icons/questionnaire.svg";

const logotype = require("../../../assets/images/logotype_50x@2x.png");

const Sidebar: React.FC = () =>
  <Layout.Sider
    width={256}
    theme="light"
    style={{ boxShadow: "6px 0px 18px rgba(0, 0, 0, 0.06)" }}
  >
    <div className={styles.logoContainer}>
      <img src={logotype} style={{ height: 50 }} />
    </div>
    <div className={styles.linksContainer}>
      <NavLink
        to="/inspecciones"
        className={styles.sidebarLink}
        activeClassName={styles.activeSidebarLink}
      >
        <Icon
          className={styles.icon}
          component={({ width, height, ...rest }) => <Inspection width={24} {...rest} />}
        />
        Inspecciones
      </NavLink>
      <NavLink
        to="/casos"
        className={styles.sidebarLink}
        activeClassName={styles.activeSidebarLink}
      >
        <Icon
          className={styles.icon}
          component={({ width, height, ...rest }) => <Error width={24} {...rest} />}
        />
        Casos de seguimiento
      </NavLink>
      <NavLink
        to="/acrs"
        className={styles.sidebarLink}
        activeClassName={styles.activeSidebarLink}
      >
        <Icon
          className={styles.icon}
          component={({ width, height, ...rest }) => <Broken width={24} {...rest} />}
        />
        ACR
      </NavLink>
      <NavLink
        to="/socios"
        className={styles.sidebarLink}
        activeClassName={styles.activeSidebarLink}
      >
        <Icon
          className={styles.icon}
          component={({ width, height, ...rest }) => <Suitcase width={24} {...rest} />}
        />
        Socios estratégicos
      </NavLink>
      <NavLink
        to="/localidades"
        className={styles.sidebarLink}
        activeClassName={styles.activeSidebarLink}
      >
        <Icon
          className={styles.icon}
          component={({ width, height, ...rest }) => <Location width={24} {...rest} />}
        />
        Localidades
      </NavLink>
      <NavLink
        to="/usuarios"
        className={styles.sidebarLink}
        activeClassName={styles.activeSidebarLink}
      >
        <Icon
          className={styles.icon}
          component={({ width, height, ...rest }) => <Person width={24} {...rest} />}
        />
        Usuarios
      </NavLink>
      <NavLink
        to="/cuestionarios"
        className={styles.sidebarLink}
        activeClassName={styles.activeSidebarLink}
      >
        <Icon
          className={styles.icon}
          component={({ width, height, ...rest }) => <Questionnaire width={24} {...rest} />}
        />
        Cuestionarios
      </NavLink>
    </div>
  </Layout.Sider>;

export default Sidebar;
