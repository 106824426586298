import React from "react";
import { Button, Icon } from "antd";

import {
  Content,
  TableContainer,
  TableSearchInput,
  TableFooter
} from "../../atoms";
import { PageHeading } from "../../molecules";
import { Inspections } from "../../../types/Inspection";
import { InspectionsPageProps } from "../../pages/InspectionsPage";
import { InspectionsTable, CreateInspectionModal } from "./components";

type InspectionsTemplateProps = InspectionsPageProps;

interface InspectionsTemplateState {
  createInspectionModal: {
    visible: boolean;
    processing: boolean;
    inputs?: {
      [key: string]: {
        value?: string;
      };
    }
  };
  inspections: Inspections;
}

class InspectionsTemplate extends React.Component<InspectionsTemplateProps, InspectionsTemplateState> {
  public state: InspectionsTemplateState = {
    createInspectionModal: {
      visible: false,
      processing: false,
      inputs: {}
    },
    inspections: {}
  };

  public render() {
    const { inspections } = this.props;

    return (
      <>
        <CreateInspectionModal
          onInputChange={this.onInputChange}
          onSubmitInspectionForm={this.onSubmitInspectionForm}
        />
        <Content>
          <PageHeading
            title="Inspecciones"
            renderRight={() => (
              <Button
                id="createInspectionBtn"
                type="primary"
                size="large"
                style={{ borderRadius: 8 }}
                onClick={this.onClickCreateInspection}
              >
                <Icon type="plus-circle" theme="filled" />
                Nueva inspección
              </Button>
            )}
          />
          <div style={{ padding: 30 }}>
            <TableContainer>
              <div style={{ padding: 30 }}>
                <TableSearchInput />
              </div>
              <InspectionsTable inspections={inspections} />
              <TableFooter
                label="inspecciones"
                showing={Object.keys(inspections).length}
                total={Object.keys(inspections).length}
                pagination={{ current: 0, total: Object.keys(inspections).length }}
              />
            </TableContainer>
          </div>
        </Content>
      </>
    );
  }

  private onClickCreateInspection = () => {
    const { setComponentState } = this.props;
    setComponentState("CREATE_INSPECTION", { visible: true });
  }

  private onInputChange = (changedField: object) =>
    this.setState((state) => ({
      createInspectionModal: {
        ...state.createInspectionModal,
        inputs: {
          ...(state.createInspectionModal.inputs || {}),
          ...changedField
        }
      }
    }))

  private onSubmitInspectionForm = () => {
    const { createInspectionModal } = this.state;
    const { createInspection, setComponentState } = this.props;

    const args: { [key: string]: string | undefined } = {};
    const inputs = createInspectionModal.inputs || {};
    Object.keys(inputs).map((input) => args[input] = inputs[input].value);

    if (
      args.questionnaire !== undefined &&
      args.title !== undefined &&
      args.localityId !== undefined &&
      args.inspected !== undefined &&
      args.name !== undefined &&
      args.activity !== undefined &&
      args.observedWorkers !== undefined
    ) {
      setComponentState("CREATE_INSPECTION", { visible: true, processing: true });

      createInspection(
        args.questionnaire,
        args.title,
        args.localityId,
        args.inspected,
        args.name,
        args.activity,
        parseInt(args.observedWorkers, 10),
        args.supervisor
      );
    }
  }
}

export default InspectionsTemplate;
