import React from "react";
import { Spin } from "antd";

import styles from "./styles.less";

const LoadingContent: React.FC = () => (
  <div className={styles.loadingContent}>
    <Spin />
  </div>
);

export default LoadingContent;
