import { ActionType, createAction } from "typesafe-actions";

import { Answer } from "../../../types/Questionnaire";

const receiveAnswers = createAction("@answer/RECEIVE_ANSWERS", (action) =>
  (answers: { [key: string]: Answer }) => action({ answers }));

export const actions = {
  receiveAnswers
};

export type AnswerAction = ActionType<typeof actions>;
