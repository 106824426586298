import { RouteChildrenProps } from "react-router";
import { createSelector } from "reselect";
import { RootState } from "..";

/**
 * Returns the strategicPartner data stored in the global state
 * @param state The current state
 */
export const selectStrategicPartnerState = (state: RootState) => state.strategicPartner;

export const selectStrategicPartnerIdFromRouteProps =
  (state: RootState, props: RouteChildrenProps<{ id: string }>) => (
    // This double comparition avoid accessing to null objects and return an empty string at least
    (props != null) ?
      (props.match != null) ? props.match.params.id : ""
      : "");

export const selectStrategicPartners = createSelector(
  [selectStrategicPartnerState], (strategicPartners) => {
    return strategicPartners;
  });

export const selectStrategicPartner = createSelector(
  [selectStrategicPartnerState, selectStrategicPartnerIdFromRouteProps],
  (strategicPartners, strategicPartnerId: string) => {
    return strategicPartnerId !== undefined && strategicPartners[strategicPartnerId];
  });
