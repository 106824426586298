import { ActionType, createAction } from "typesafe-actions";

import { Answer, Questionnaire } from "../../../types/Questionnaire";
import { QuestionnaireState } from "../../../components/organisms/Forms/QuestionnaireForm";

const loadQuestionnaires = {
  request: createAction("@questionnaire/LOAD_QUESTIONNAIRES_REQUEST", (action) =>
    (componentId?: string) => action({ componentId })),
  success: createAction("@questionnaire/LOAD_QUESTIONNAIRES_SUCCESS", (action) =>
    (questionnaires: { [key: string]: Questionnaire }) => action({ questionnaires })),
  error: createAction("@questionnaire/LOAD_QUESTIONNAIRES_ERROR", (action) =>
    (error: any) => action({ error }))
};

const loadQuestionnaire = {
  request: createAction("@questionnaire/LOAD_QUESTIONNAIRE_REQUEST", (action) =>
    (questionnaireId: string, componentId?: string) => action({ questionnaireId, componentId })),
  success: createAction("@questionnaire/LOAD_QUESTIONNAIRE_SUCCESS", (action) =>
    (questionnaire: { [key: string]: Questionnaire }) => action({ questionnaire })),
  error: createAction("@questionnaire/LOAD_QUESTIONNAIRE_ERROR", (action) =>
    (error: any) => action({ error }))
};

const createQuestionnaire = {
  request: createAction("@questionnaire/CREATE_QUESTIONNAIRE_REQUEST", (action) =>
    (questionnaire: QuestionnaireState, componentId?: string) => action({ questionnaire, componentId })),
  success: createAction("@questionnaire/CREATE_QUESTIONNAIRE_SUCCESS", (action) =>
    (questionnaire: { [key: string]: Questionnaire }) => action({ questionnaire })),
  error: createAction("@questionnaire/CREATE_QUESTIONNAIRE_ERROR", (action) =>
    (error: any) => action({ error }))
};

const receiveAnswers = createAction("@questionnaire/RECEIVE_ANSWERS", (action) =>
  (answers: Answer[]) => action({ answers }));

const deleteQuestionarie = {
  request: createAction("@questionnaire/DELETE_QUESTIONNAIRE_REQUEST", (action) =>
    (questionnaireId: string, componentId?: string) => action({ questionnaireId, componentId })),
  success: createAction("@questionnaire/DELETE_QUESTIONNAIRE_SUCCESS", (action) =>
    (questionnaireId: string) => action({ questionnaireId })),
  error: createAction("@questionnaire/DELETE_QUESTIONNAIRE_ERROR", (action) =>
    (error: any) => action({ error }))
};

export const actions = {
  loadQuestionnaires,
  loadQuestionnaire,
  receiveAnswers,
  createQuestionnaire,
  deleteQuestionarie
};

export type QuestionnaireAction = ActionType<typeof actions>;
