import { Reducer } from "redux";
import { message } from "antd";
import { getType } from "typesafe-actions";

import copy from "../../../copy.json";
import { bugsnagClient } from "../../../bugsnag";
import { ACR as ACRType } from "../../../types/ACR";
import { actions, ACRAction } from "./actions";

export interface AcrState {
  [key: string]: ACRType;
}

export const initialState: AcrState = {};

const ACRReducer: Reducer<AcrState, ACRAction> =
  (state = initialState, action: ACRAction) => {
    switch (action.type) {
      // LOAD_ACRS
      case getType(actions.loadACRs.success): {
        const { ACRs } = action.payload;
        message.success(copy.ACRs.loadSuccess);

        return {
          ...state,
          ...ACRs
        };
      }
      case getType(actions.loadACRs.error): {
        message.error(copy.ACRs.loadError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      case getType(actions.loadACR.success): {
        const { ACR } = action.payload;
        // message.success(copy.ACRs.loadSuccess);

        return {
          ...state,
          ...ACR
        };
      }
      case getType(actions.loadACR.error): {
        message.error(copy.ACRs.loadError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      // CREATE_ACR
      case getType(actions.createACR.success): {
        const { ACR } = action.payload;
        return {
          ...state,
          ...ACR
        };
      }
      // UPDATE_ACR
      // TODO: BLOCKER: Pending on strategy to udpate ACRs
      case getType(actions.updateACR.success): {
        return state;
      }
      // DELETE_ACR
      case getType(actions.deleteACR.success): {
        const { acrId } = action.payload;
        const { [acrId]: deleted, ...newState } = state;

        return newState;
      }
      default:
        return state;
    }
  };

export default ACRReducer;
