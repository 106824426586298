import React from "react";
import ReactDOM from "react-dom";
import { Spin, Icon, DatePicker } from "antd";

const LoadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
Spin.setDefaultIndicator(LoadingIcon);
DatePicker.defaultProps = { ...DatePicker.defaultProps, format: "DD [de] MMMM [de] YYYY" };

import App from "./App";

const root = document.getElementById("root");

ReactDOM.render(<App />, root);
