import React from "react";
import { Modal as AntModal } from "antd";
import { ModalProps } from "antd/lib/modal";

import styles from "./styles.less";

const Modal: React.FC<ModalProps> = (props) =>
  <AntModal {...props} className={styles.modal} />;

export default Modal;
