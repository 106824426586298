import React from "react";
import { Select, DatePicker, Button } from "antd";
import moment from "moment";

import { Modal } from "../../../atoms";
import { CreateCaseForm } from "../../../organisms";
import { caseTypes, caseStatusesWithCompleted } from "../../../../utils/maps";
import { FormProps } from "antd/lib/form";
import withUIState, { ComponentProps } from "../../../HOCs/withUIState";
import { CaseType, Case, CaseStatus } from "../../../../types/Case";

interface CreateCaseModalOwnProps {
  onMetadataChange: (type: string, value: CaseType | CaseStatus | number) => void;
  onInputChange: (changedField: object) => void;
  onSubmitCaseForm: () => void;
  onDeleteCase?: (caseId: string) => void;
  onChangeStatus?: (ev: string) => void;
  onCancel?: () => void;
  _case?: Case;
}

type CreateCaseModalProps = CreateCaseModalOwnProps & ComponentProps;

class CreateCaseModal extends React.Component<CreateCaseModalProps> {
  private creatCaseForm?: React.Component<FormProps>;
  private dateFormat = "DD [de] MMMM [de] YYYY";

  public render() {
    const {
      componentState,
      onMetadataChange,
      onInputChange,
      onSubmitCaseForm,
      onDeleteCase,
      onChangeStatus,
      onCancel,
      _case
    } = this.props;

    return (
      <Modal
        title={
          <div>
            <div style={{ fontSize: 30, fontWeight: 600, paddingTop: 30 }}>
              <span>Nuevo caso</span>
            </div>
            <div style={{ marginTop: 20 }}>
              {
                _case
                ? <Select
                    style={{ width: 190, marginRight: 16 }}
                    placeholder="Selecciona el tipo de caso"
                    onChange={(value: CaseType) => onMetadataChange("caseType", value)}
                    value={caseTypes[_case.caseType] as CaseType}
                  >
                    {Object.keys(caseTypes).map((type) => (
                      <Select.Option key={type} value={type}>
                        {caseTypes[type]}
                      </Select.Option>
                    ))}
                  </Select>
                : <Select
                    style={{ width: 190, marginRight: 16 }}
                    placeholder="Selecciona el tipo de caso"
                    defaultOpen
                    onChange={(value: CaseType) => onMetadataChange("caseType", value)}
                  >
                    {Object.keys(caseTypes).map((type) => (
                      <Select.Option key={type} value={type}>
                        {caseTypes[type]}
                      </Select.Option>
                    ))}
                  </Select>
              }
              {
                _case
                ? <DatePicker
                    allowClear={false}
                    onChange={(date) => onMetadataChange("caseDate", date.valueOf())}
                    value={moment(new Date(_case.caseDate), this.dateFormat)}
                    format={this.dateFormat}
                  />
                : <DatePicker
                    allowClear={false}
                    onChange={(date) => onMetadataChange("caseDate", date.valueOf())}
                    defaultValue={moment(new Date(), this.dateFormat)}
                    format={this.dateFormat}
                  />
              }
              {
                _case && 
                onDeleteCase &&
                <Button
                  type="primary"
                  shape="circle"
                  icon="delete"
                  onClick={() => onDeleteCase(_case.id)}
                  style={{ float: "right" }}
                />
              }
              {
                onChangeStatus &&
                <Select
                  value={_case && caseStatusesWithCompleted[_case.status] as CaseStatus}
                  placeholder="Seleccionar estatus"
                  style={{ float: "right" }}
                  onChange={onChangeStatus}
                >
                  {Object.keys(caseStatusesWithCompleted).map((status) => (
                    <Select.Option key={status} value={status}>
                      {caseStatusesWithCompleted[status]}
                    </Select.Option>
                  ))}
                </Select>
              }
            </div>
          </div>
        }
        width={920}
        footer={[
          <Button
            id="cancelCaseBtn"
            size="large"
            onClick={this.closeModal}
          >
            Cancelar
          </Button>,
          <Button
            id="saveCaseBtn"
            type="primary"
            size="large"
            form="createCase"
            htmlType="submit"
            loading={componentState.processing}
          >
            Guardar
          </Button>
        ]}
        closable={false}
        onCancel={this.closeModal}
        visible={componentState.visible}
      >
        <CreateCaseForm
          id="createCase"
          wrappedComponentRef={(form: React.Component<FormProps>) => (this.creatCaseForm = form)}
          onChange={onInputChange}
          onSubmit={onSubmitCaseForm}
          data={_case}
        />
      </Modal>
    );
  }

  private closeModal = () => {
    const { setComponentState, onCancel } = this.props;
    setComponentState("CREATE_CASE", { visible: false, processing: false });

    if (this.creatCaseForm && this.creatCaseForm.props.form) {
      this.creatCaseForm.props.form.resetFields();
      if (onCancel) {
        onCancel();
      }
    }
  }
}

export default withUIState("CREATE_CASE", CreateCaseModal);
