import React from "react";
import { Icon } from "antd";

import styles from "./style.less";
import { Card } from "../../atoms";
import QuestionnaireSections from "../../../assets/icons/questionnaire_sections.svg";
import QuestionnaireQuestions from "../../../assets/icons/questionnaire_questions.svg";

interface QuestionnaireCardProps {
  title: string;
  description: string;
  sections: number;
  questions: number;
}

const QuestionnaireCard: React.FC<QuestionnaireCardProps> = ({ title, description, sections, questions }) => {
  return (
    <Card>
      <div className={styles.cardTitle}>{title}</div>
      <div className={styles.cardDescription}>{description}</div>
      <div className={styles.cardDetails}>
        <div>
          <div>
            <Icon
              component={({ width, height, ...rest }) => <QuestionnaireSections width={24} {...rest} />}
            />
          </div>
          <div>{sections} Secciones</div>
        </div>
        <div>
          <div>
            <Icon
              component={({ width, height, ...rest }) => <QuestionnaireQuestions width={24} {...rest} />}
            />
          </div>
          <div>{questions} Preguntas</div>
        </div>
      </div>
    </Card>
  );
};

export default QuestionnaireCard;
