import { RouteChildrenProps } from "react-router";
import { createSelector } from "reselect";

import { RootState } from "..";

export const selectAcrState = (state: RootState) => state.ACR;

export const selectAcrIdFromRouteProps =
  (state: RootState, props: RouteChildrenProps<{ id: string }>) =>
    props.match ? props.match.params.id : undefined;

export const selectAcr = createSelector(
  [selectAcrState, selectAcrIdFromRouteProps],
  (state, acrId) => acrId && state[acrId]
);

export const selectAcrWithId = (acrId: string) => createSelector(
  [selectAcrState],
  (state) => state[acrId]
);
