import React from "react";
import { Avatar } from "antd";

interface AvatarProps {
  src?: string;
  size?: number;
}
/**
 * Shows a circle image if source is specified, or a default icon if not
 */
const AvatarTumbnail: React.FC<AvatarProps> = ({ src, size }) => {
  
  return (
    <>
      {
        src 
        ? <Avatar src={src} size={ size ? size : 20 }/>
        : <Avatar icon="user" size={ size ? size : 20 }  />
      }
    </>
  )
};

export default AvatarTumbnail;
