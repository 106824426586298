import React from "react";

import {
  Content,
  TableContainer,
  TableSearchInput,
  TableFooter
} from "../../atoms";
import { BussinessUnitsTable } from "./components";
import { PageHeading } from "../../molecules";
import { LocalityDetailsPageProps } from "../../pages/LocalityDetailsPage";
import { Locality } from "../../../types/Locality";

type LocalityDetailsPageTemplateProps = LocalityDetailsPageProps;

interface LocalityDetailsTemplateState {
  locality: Locality;
}

class LocalityDetailsTemplate extends React.Component <
LocalityDetailsPageTemplateProps,
LocalityDetailsTemplateState> {

  public render() {
    const { locality } = this.props;

    return (
      <Content>
        <PageHeading 
        backTo="/localidades"
        title={locality ? locality.name : ""}
        />
        <div style={{ padding: 30 }}>
          <TableContainer>
            <div style={{ padding: 30 }}>
              <TableSearchInput />
            </div>
            <BussinessUnitsTable
            bussinessUnits={
              locality ? (locality.bussinessUnits ? locality.bussinessUnits : []) : []
            }
            />
            <TableFooter label="localidades" pagination={{ current: 1, total: 30 }} />
          </TableContainer>
        </div>
      </Content>
    );
  }
}

export default LocalityDetailsTemplate;
