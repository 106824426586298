import React from "react";
import { Form, Input, Select, Row, Col, Tabs, Radio, Collapse, DatePicker, Button, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import uuidv4 from "uuid/v4";
import moment from "moment";

import { ACRs } from "../../../copy.json";
import { LocalitySelect } from "../../molecules/AsyncSelects";
import _case from "../../../store/sagas/network/watchers/case";
import { Title } from "../../../components/atoms";

interface ACRFormState {
  touched: boolean;
  ACR: {
    id: string;
  };
  CRs: {
    [crId: string]: {
      id: string;
      acrId: string;
    };
  };
  actions: {
    [actionId: string]: {
      id: string;
      crId: string;
    };
  };
}

interface CreateACRFormOwnProps extends FormComponentProps {
  onSubmit: (values: object) => void;
  visible: boolean;
  data?: any;
}

type CreateACRFormProps = CreateACRFormOwnProps;

export class CreateACRForm extends React.Component<CreateACRFormProps, ACRFormState> {
  private static acrId = uuidv4();
  private static crId = uuidv4();
  private static actionId = uuidv4();

  private static initialState: ACRFormState = {
    touched: false,
    ACR: {
      id: CreateACRForm.acrId
    },
    CRs: {
      [CreateACRForm.crId]: {
        id: CreateACRForm.crId,
        acrId: CreateACRForm.acrId
      }
    },
    actions: {
      [CreateACRForm.actionId]: {
        id: CreateACRForm.actionId,
        crId: CreateACRForm.crId
      }
    }
  };

  public state = CreateACRForm.initialState;

  public componentDidMount() {
    this.setFormFields();
  }

  public componentDidUpdate(prevProps: CreateACRFormProps) {
    if (!prevProps.visible && this.props.visible) {
      CreateACRForm.acrId = uuidv4();
      CreateACRForm.crId = uuidv4();
      CreateACRForm.actionId = uuidv4();
      this.setFormFields();
    }
  }

  public render() {
    const { CRs, actions } = this.state;
    const { form: { getFieldDecorator } } = this.props;

    const CRIds = Object.keys(CRs);

    return (
      <Form id="ACRForm" layout="vertical" onSubmit={this.handleSubmit}>
        {getFieldDecorator("ACR.id", { initialValue: CreateACRForm.acrId })(<Input type="hidden" />)}
        <Tabs
          type="editable-card"
          onEdit={this.editTabs}
        >
          <Tabs.TabPane tab="Información" key="information" closable={false}>
            <div style={{ margin: "20px 0" }}>
              <Title size={24}>Información general del ACR</Title>
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Título">
                  {getFieldDecorator("ACR.title", {
                    rules: [
                      {
                        required: true,
                        message: ACRs.formErrors.ACR.title
                      }
                    ]
                  })(
                    <Input size="large" />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Localidad">
                  {getFieldDecorator("ACR.localityId", {
                    rules: [
                      {
                        required: true,
                        message: ACRs.formErrors.ACR.localityId
                      }
                    ]
                  })(
                    <LocalitySelect />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Departamento o socio estratégico involucrado">
                  {getFieldDecorator("ACR.implicatedId", {
                    rules: [
                      {
                        required: true,
                        message: ACRs.formErrors.ACR.implicatedId
                      }
                    ]
                  })(
                    <Input size="large" />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Reportó">
                  {getFieldDecorator("ACR.reportedBy", {
                    rules: [
                      {
                        required: true,
                        message: ACRs.formErrors.ACR.reportedBy
                      }
                    ]
                  })(
                    <LocalitySelect />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item label="Descripción">
                {getFieldDecorator("ACR.description", {
                  rules: [
                    {
                      required: true,
                      message: ACRs.formErrors.ACR.description
                    }
                  ]
                })(
                  <Input size="large" />
                )}
              </Form.Item>
            </Row>
            <Row>
              <Form.Item label="¿Cómo sucedió?">
                {getFieldDecorator("ACR.how", {
                  rules: [
                    {
                      required: true,
                      message: ACRs.formErrors.ACR.how
                    }
                  ]
                })(
                  <Input size="large" />
                )}
              </Form.Item>
            </Row>
            <Row>
              <Form.Item label="¿Dónde sucedió?">
                {getFieldDecorator("ACR.where", {
                  rules: [
                    {
                      required: true,
                      message: ACRs.formErrors.ACR.where
                    }
                  ]
                })(
                  <Input size="large" />
                )}
              </Form.Item>
            </Row>
            <Row>
              <Form.Item label="¿Existió lesión?">
                {getFieldDecorator("ACR.injury", {
                  rules: [
                    {
                      required: true,
                      message: ACRs.formErrors.ACR.injury
                    }
                  ]
                })(
                  <Radio.Group size="large">
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Row>
          </Tabs.TabPane>
          {CRIds.map((CRId, idx) => {
            const CRActions = Object.values(actions).filter((action) => (action.crId === CRId));
            const CRActionIds = CRActions.map((CRAction) => CRAction.id);

            return (
              <Tabs.TabPane tab={`CR${idx + 1}`} key={`CRs.${CRId}`} closable={idx !== 0}>
                {getFieldDecorator(`CRs.${CRId}.id`, { initialValue: CRId })(<Input type="hidden" />)}
                {getFieldDecorator(`CRs.${CRId}.acrId`, { initialValue: CreateACRForm.acrId })(<Input type="hidden" />)}
                <div style={{ margin: "20px 0" }}>
                  <Title size={24}>Causa raíz {idx + 1}</Title>
                </div>
                <Form.Item>
                  {getFieldDecorator(`CRs.${CRId}.description`, {
                    rules: [
                      {
                        required: true,
                        message: ACRs.formErrors.CRs.description
                      }
                    ]
                  })(
                    <Input.TextArea placeholder="Describe a detalle la causa raíz" />
                  )}
                </Form.Item>
                <Collapse defaultActiveKey={[`actions.${CRActionIds[0]}`]}>
                  {CRActions.map((CRAction, actionIdx) => {
                    const actionId = CRAction.id;
                    return (
                      <Collapse.Panel
                        header={`Acción ${actionIdx + 1}`}
                        key={`actions.${actionId}`}
                        extra={this.renderExtraNode(actionId!, actionIdx)}
                      >
                        {getFieldDecorator(`actions.${actionId}.id`, { initialValue: actionId })(
                          <Input type="hidden" />
                        )}
                        {getFieldDecorator(`actions.${actionId}.crId`, { initialValue: CRId })(
                          <Input type="hidden" />
                        )}
                        <Row>
                          <Form.Item>
                            {getFieldDecorator(`actions.${actionId}.description`, {
                              rules: [
                                {
                                  required: true,
                                  message: ACRs.formErrors.actions.description
                                }
                              ]
                            })(
                              <Input.TextArea placeholder="Escribe una descripción de la acción propuesta" />
                            )}
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item>
                            {getFieldDecorator(`actions.${actionId}.why`, {
                              rules: [
                                {
                                  required: true,
                                  message: ACRs.formErrors.actions.why
                                }
                              ]
                            })(
                              <Input placeholder="¿Porque se debe realizar esta acción?" size="large" />
                            )}
                          </Form.Item>
                        </Row>
                        <Row>
                          <Form.Item>
                            {getFieldDecorator(`actions.${actionId}.how`, {
                              rules: [
                                {
                                  required: true,
                                  message: ACRs.formErrors.actions.how
                                }
                              ]
                            })(
                              <Input placeholder="¿Cómo se debe resolver esta acción?" size="large" />
                            )}
                          </Form.Item>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              {getFieldDecorator(`actions.${actionId}.responsibleId`, {
                                rules: [
                                  {
                                    required: true,
                                    message: ACRs.formErrors.actions.responsibleId
                                  }
                                ]
                              })(
                                <Input placeholder="Selecciona responsable de acción" size="large" />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item>
                              {getFieldDecorator(`actions.${actionId}.resolutionLocation`, {
                                rules: [
                                  {
                                    required: true,
                                    message: ACRs.formErrors.actions.resolutionLocation
                                  }
                                ]
                              })(
                                <Input placeholder="Escribe el lugar de resolución" size="large" />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item>
                              {getFieldDecorator(`actions.${actionId}.resolutionDate`, {
                                rules: [
                                  {
                                    required: true,
                                    message: ACRs.formErrors.actions.resolutionDate
                                  }
                                ]
                              })(
                                <DatePicker placeholder="Fecha de resolución" size="large" style={{ width: "100%" }} />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Collapse.Panel>
                    );
                  })}
                </Collapse>
                <div style={{ marginTop: 20 }}>
                  <Button
                    size="large"
                    icon="plus-circle"
                    onClick={() => this.addAction(CRId)}
                    block
                  >
                    Agregar nueva acción
                  </Button>
                </div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </Form>
    );
  }

  private setFormFields = () => {
    const { data, form } = this.props;
    if (data) {
      const { crs, ...ACR } = data;

      const CRs = {};
      const CRsForState = {};

      const actions = {};
      const actionsForState = {};

      crs.map((CR: any) => {
        const { actions: CRActions = [], ...CROnly } = CR;
        CRs[CR.id] = CR;
        CRsForState[CR.id] = { id: CROnly.id, acrId: ACR.id };
        CRActions.map((action: any) => {
          actions[action.id] = action;
          actionsForState[action.id] = { id: action.id, crId: action.crId };
        });
      });

      this.setState(
        (state) => ({
          ...state,
          ACR: {
            id: ACR.id
          },
          CRs: CRsForState,
          actions: actionsForState
        }),
        () => {
          const fields = {
            "ACR.id": ACR.id,
            "ACR.title": ACR.title,
            "ACR.localityId": ACR.localityId,
            "ACR.implicatedId": ACR.implicatedId,
            "ACR.reportedBy": ACR.reportedBy,
            "ACR.description": ACR.description,
            "ACR.how": ACR.how,
            "ACR.where": ACR.where,
            "ACR.injury": ACR.injury
          };

          const CRsValues = Object.values(CRs);
          const actionsValues = Object.values(actions);

          CRsValues.map((CR: any) => {
            fields[`CRs.${CR.id}.id`] = CRs[CR.id].id;
            fields[`CRs.${CR.id}.acrId`] = CRs[CR.id].acrId;
            fields[`CRs.${CR.id}.description`] = CRs[CR.id].description;
          });

          actionsValues.map((action: any) => {
            Object.keys(action).map((actionFieldKey: any) => {
              if (actionFieldKey === "resolutionDate") {
                fields[`actions.${action.id}.${actionFieldKey}`] = moment(actions[action.id][actionFieldKey]);
              } else {
                fields[`actions.${action.id}.${actionFieldKey}`] = actions[action.id][actionFieldKey];
              }
            });
          });

          form.setFieldsValue(fields);
        }
      );
    }
  }

  private addCR = () => {
    const CRId = uuidv4();
    const actionId = uuidv4();
    return this.setState((state) => ({
      ...state,
      touched: true,
      CRs: {
        ...state.CRs,
        [CRId]: {
          id: CRId,
          acrId: CreateACRForm.acrId,
          description: undefined
        }
      },
      actions: {
        ...state.actions,
        [actionId]: {
          id: actionId,
          crId: CRId,
          description: undefined,
          why: undefined,
          how: undefined,
          responsibleId: undefined,
          location: undefined,
          date: undefined
        }
      }
    }));
  }

  private removeCR = (CRId: string) => {
    const { actions } = this.state;
    const { [CRId]: removed, ...newCRs } = this.state.CRs;
    const actionsToKeep = Object.values(actions).filter((action) => action.crId !== CRId);
    const newActions = {};
    actionsToKeep.map((action) => {
      newActions[action.id!] = action;
    });

    return this.setState((state) => ({
      ...state,
      touched: true,
      CRs: newCRs,
      actions: newActions
    }));
  }

  private addAction = (CRId: string) => {
    const id = uuidv4();
    return this.setState((state) => ({
      ...state,
      touched: true,
      actions: {
        ...state.actions,
        [id]: {
          id,
          crId: CRId,
          description: undefined,
          why: undefined,
          how: undefined,
          responsibleId: undefined,
          location: undefined,
          date: undefined
        }
      }
    }));
  }

  private duplicateAction = (evt: React.MouseEvent<HTMLElement, MouseEvent>, actionId: string) => {
    evt.stopPropagation();

    const { actions } = this.state;
    const { form } = this.props;

    const newActionId = uuidv4();
    const actionToDuplicate = { ...actions[actionId] };
    actionToDuplicate.id = newActionId;

    this.setState((state) => ({
      ...state,
      touched: true,
      actions: {
        ...state.actions,
        [newActionId]: actionToDuplicate
      }
    }), () => {
      const fieldId = `actions.${actionId}`;
      const newFieldId = `actions.${newActionId}`;

      const fields = [
        `${fieldId}.description`,
        `${fieldId}.why`,
        `${fieldId}.how`,
        `${fieldId}.responsibleId`,
        `${fieldId}.resolutionLocation`,
        `${fieldId}.resolutionDate`
      ];

      const values = form.getFieldsValue(fields);
      const newValues = {};

      if (values.actions[actionId]) {
        Object.keys(values.actions[actionId]).map((valueKey) => {
          newValues[`${newFieldId}.${valueKey}`] = values.actions[actionId][valueKey];
        });

        form.setFieldsValue(newValues);
      }
    });
  }

  private removeAction = (evt: React.MouseEvent<HTMLElement, MouseEvent>, actionId: string) => {
    evt.stopPropagation();

    const { [actionId]: removed, ...newActions } = this.state.actions;
    return this.setState((state) => ({
      ...state,
      touched: true,
      actions: newActions
    }));
  }

  private editTabs = (targetKey: string | React.MouseEvent<HTMLElement, MouseEvent>, action: string) => {
    if (action === "add") {
      return this.addCR();
    }

    if (typeof targetKey === "string" && action === "remove") {
      const splitKey = targetKey.split(".");
      if (splitKey[0] === "CRs" && splitKey[1]) {
        return this.removeCR(splitKey[1]);
      }
    }
  }

  private renderExtraNode = (actionId: string, idx: number) => (
    <>
      <Icon
        style={{ fontSize: 18 }}
        type="copy"
        theme="filled"
        onClick={(e) => this.duplicateAction(e, actionId)}
      />
      {idx > 0 && (
        <Icon
          style={{ fontSize: 18, marginLeft: 10 }}
          type="delete"
          theme="filled"
          onClick={(e) => this.removeAction(e, actionId)}
        />
      )}
    </>
  )

  private handleSubmit = (evt: React.FormEvent<any>) => {
    const { onSubmit, form: { validateFields }, form } = this.props;

    if (evt) {
      evt.preventDefault();
    }

    validateFields((err, values: ACRFormState) => {
      if (err) { return; }
      onSubmit(values);
      return form.resetFields();
    });
  }
}

export default Form.create({ name: "ACRForm" })(CreateACRForm);
