import { RouteChildrenProps } from "react-router";
import { createSelector } from "reselect";

import { RootState } from "..";

export const selectCaseState = (state: RootState) => state.case;
export const selectCaseIdFromRouteProps =
  (state: RootState, props: RouteChildrenProps<{ id: string }>) =>
    props.match ? props.match.params.id : undefined;

export const selectCase = createSelector(
  [selectCaseState, selectCaseIdFromRouteProps],
  (state, caseId) => caseId && state[caseId]
);

export const selectCaseWithId = (caseId: string) => createSelector(
  [selectCaseState],
  (state) => state[caseId]
);