import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { RootState } from "../../store/modules";
import { withPageTitle } from "../HOCs";

import { StrategicPartnersTemplate } from "../templates";
import { actions as strategicPartnerActions } from "../../store/modules/strategicPartner/actions";
import { actions as UIActions } from "../../store/modules/UI/actions";
import * as strategicPartnerSelectors from "../../store/modules/strategicPartner/selectors";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type StrategicPartnersPageProps = StateProps & DispatchProps;

/**
 * Page of Socios Estratégicos
 * Displays a list of strategic partners
 */
class StrategicPartnersPage extends React.Component<StrategicPartnersPageProps> {
  public componentDidMount() {
    const { loadStrategicPartners } = this.props; // it's connected to the component thanks to the mapDispatchToProps
    loadStrategicPartners("STRATEGIC_PARTNERS_TABLE");
  }

  public render() {
    return <StrategicPartnersTemplate {...this.props}/>;
  }
}
/**
 * Take the state properties returned by the selector and returns it as props
 * @param state The current state
 */
const mapStateToProps = (state: RootState) => ( {
  strategicPartners: strategicPartnerSelectors.selectStrategicPartners(state)
} );

/**
 * Take the dispatch functions given by the action creators and returns it as props
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadStrategicPartners: strategicPartnerActions.loadStrategicPartners.request,
    setComponentState: UIActions.setComponentState
  }, dispatch);

/**
 * Allows to use the props given by the mapStateToProps and mapDispatchToProps
 * functions as props of the component
 */
const ConnectedStrategicPartnersPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPageTitle(StrategicPartnersPage, "Socios Estratégicos"));

export default ConnectedStrategicPartnersPage;
