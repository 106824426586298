import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { push } from "connected-react-router";

import { QuestionnaireDetailsTemplate } from "../templates";
import { RootState } from "../../store/modules";
import { actions as questionnaireActions } from "../../store/modules/questionnaire/actions";
import * as questionnaireSelectors from "../../store/modules/questionnaire/selectors";
import { withPageTitle } from "../HOCs";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type QuestionnaireDetailsPageProps = StateProps & DispatchProps & RouteChildrenProps<{ id: string }>;

class QuestionnaireDetailsPage extends React.Component<QuestionnaireDetailsPageProps> {
  public componentDidMount() {
    const { questionnaire, loadQuestionnaire, match } = this.props;
    if (!questionnaire && match) {
      loadQuestionnaire(match.params.id, "QUESTIONNAIRE_DETAILS");
    }
  }

  public render() {
    return <QuestionnaireDetailsTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: RootState, props: RouteChildrenProps<{ id: string }>) => ({
  questionnaire: questionnaireSelectors.selectQuestionnaireWithIdFromProps(state, props)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadQuestionnaire: questionnaireActions.loadQuestionnaire.request,
    deleteQuestionnaire: questionnaireActions.deleteQuestionarie.request,
    push
  }, dispatch);

const ConnectedQuestionnaireDetailsPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuestionnaireDetailsPage);

export default withPageTitle(ConnectedQuestionnaireDetailsPage, "Cuestionario");
