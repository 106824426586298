import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import { message } from "antd";

import copy from "../../../copy.json";
import { Questionnaire } from "../../../types/Questionnaire";
import { actions, QuestionnaireAction } from "./actions";
import { bugsnagClient } from "../../../bugsnag";

export interface QuestionnaireState {
  [key: string]: Questionnaire;
}

const initialState: QuestionnaireState = {};

const questionnaireReducer: Reducer<QuestionnaireState, QuestionnaireAction> =
  (state = initialState, action: QuestionnaireAction) => {
    switch (action.type) {
      // LOAD_QUESTIONNARIES
      case getType(actions.loadQuestionnaires.success): {
        const { questionnaires } = action.payload;
        message.success(copy.questionnaires.loadSuccess);

        return {
          ...state,
          ...questionnaires
        };
      }
      case getType(actions.loadQuestionnaires.error): {
        message.error(copy.questionnaires.loadError);
        return state;
      }
      case getType(actions.loadQuestionnaire.success): {
        const { questionnaire } = action.payload;
        message.success(copy.questionnaire.loadSuccess);

        return {
          ...state,
          ...questionnaire
        };
      }
      // LOAD_QUESTIONNARIE
      case getType(actions.loadQuestionnaire.error): {
        message.error(copy.questionnaire.loadError);
        return state;
      }
      case getType(actions.createQuestionnaire.success): {
        const { questionnaire } = action.payload;

        return {
          ...state,
          ...questionnaire
        };
      }
      // DELETE_QUESTIONNARIE
      case getType(actions.deleteQuestionarie.success): {
        const { questionnaireId } = action.payload;
        const { [questionnaireId]: deleted, ...newState } = state;

        return newState;
      }
      case getType(actions.deleteQuestionarie.error): {
        message.error(copy.questionnaire.deleteError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      default:
        return state;
    }
  };

export default questionnaireReducer;
