import React from "react";
import { Button, Modal as AntModal } from "antd";
import { FormProps } from "antd/lib/form";

import { Modal } from "../../atoms";
import { CreateACRForm } from "../../organisms";
import withUIState, { ComponentProps } from "../../HOCs/withUIState";

interface ACRModalOwnProps {
  ACR?: any; // BLOCKER: Define this once we are on server
  editing?: boolean;
  submitAction: (ACR: any, componentId?: string) => void;
}

type ACRModalProps = ACRModalOwnProps & ComponentProps;

class ACRModal extends React.Component<ACRModalProps> {
  private createACRForm?: React.Component<FormProps>;

  public render() {
    const { componentState, editing, ACR } = this.props;
    const title = editing ? "Editar ACR" : "Nueva ACR";

    return (
      <Modal
        title={
          <div>
            <div style={{ fontSize: 30, fontWeight: 600, paddingTop: 30 }}>
              <span>{title}</span>
            </div>
          </div>
        }
        width={920}
        footer={[
          <Button
            id="cancelACRBtn"
            size="large"
            onClick={this.onCloseModal}
          >
            Cancelar
          </Button>,
          <Button
            id="saveACRBtn"
            type="primary"
            size="large"
            form="ACRForm"
            htmlType="submit"
            loading={componentState.processing}
          >
            Guardar
          </Button>
        ]}
        closable={false}
        onCancel={this.onCloseModal}
        visible={componentState.visible}
      >
        <CreateACRForm
          wrappedComponentRef={(form: React.Component<FormProps>) => (this.createACRForm = form)}
          onSubmit={this.onSubmit}
          visible={componentState.visible || false}
          data={ACR}
        />
      </Modal>
    );
  }

  private onSubmit = (values: any) => {
    const { submitAction } = this.props;

    const CRsValues = Object.values(values.CRs);
    const actionsValues = Object.values(values.actions);

    const crs = {};

    CRsValues.map((CR: any) => {
      const actions = actionsValues.filter((action: any) => action.crId === CR.id);
      crs[CR.id] = { ...CR, actions };
    });

    const ACR = {
      ...values.ACR,
      crs: Object.values(crs)
    };

    submitAction(ACR, "ACR_MODAL");
  }

  private onCloseModal = () => {
    if (
      this.createACRForm &&
      this.createACRForm.props.form &&
      this.createACRForm.props.form.isFieldsTouched()
    ) {
      const self = this;
      AntModal.confirm({
        title: "Cancelar sin guardar cambios.",
        content: "Los cambios hechos se perderán, ¿Estás seguro que deseas cancelar la edición?",
        okText: "Sí",
        onOk() {
          self.closeModal();
        },
        cancelText: "No"
      });
    } else {
      this.closeModal();
    }
  }

  private closeModal = () => {
    const { setComponentState } = this.props;
    setComponentState("ACR_MODAL", { visible: false, processing: false });

    if (this.createACRForm && this.createACRForm.props.form) {
      this.createACRForm.props.form.resetFields();
    }
  }
}

export default withUIState("ACR_MODAL", ACRModal);
