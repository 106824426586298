import React, { Fragment } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { RootState } from "../../store/modules";
import { withPageTitle } from "../HOCs";

import { actions as localityActions } from "../../store/modules/locality/actions";
import { actions as UIActions } from "../../store/modules/UI/actions";
import * as localitySelectors from "../../store/modules/locality/selectors";
import LocalityDetailsTemplate from "../templates/LocalityDetailsTemplate";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type LocalityDetailsPageProps = StateProps & DispatchProps & RouteChildrenProps<{id: string}>;

/**
 * Shows the details of a locality. It includes
 * a table of the bussiness units in this location
 */
class LocalityDetailsPage extends React.Component<LocalityDetailsPageProps> {
  public componentDidMount() {
    const { loadLocalityDetails, match } = this.props;
    loadLocalityDetails(match.params.id, "LOCALITY_DETAILS");
  }

  public render() {
    return (
      <Fragment>
        <LocalityDetailsTemplate {...this.props} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: RootState, props: RouteChildrenProps<{ id: string }>) => ({
  locality: localitySelectors.selectLocality(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadLocalityDetails: localityActions.loadLocality.request,
    setComponentState: UIActions.setComponentState
  }, dispatch);

const ConnectedLocalityDetailsPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPageTitle(LocalityDetailsPage, "Detalles"));

export default ConnectedLocalityDetailsPage;
