// Main pages
export { default as InspectionsPage } from "./InspectionsPage";
export { default as InspectionDetailsPage } from "./InspectionDetailsPage";

export { default as CasesPage } from "./CasesPage";
export { default as CaseDetailsPage } from "./CaseDetailsPage";

export { default as ACRsPage } from "./ACRsPage";
export { default as ACRDetailsPage } from "./ACRDetailsPage";

export { default as StrategicPartnersPage } from "./StrategicPartnersPage";
export { default as StrategicPartnerDetailsPage } from "./StrategicPartnerDetailsPage";

export { default as LocalitiesPage } from "./LocalitiesPage";
export { default as LocalityDetailsPage } from "./LocalityDetailsPage";

export { default as UsersPage } from "./UsersPage";

export { default as QuestionnairesPage } from "./QuestionnairesPage";
export { default as QuestionnaireDetailsPage } from "./QuestionnaireDetailsPage";
export { default as QuestionnairePage } from "./QuestionnairePage";

// Misc pages
export { default as NotFoundPage } from "./NotFoundPage";
