import Service from "./Service";

type serviceType = Service;

/**
 * Contains the Strategic Partner calls
 */
export default class StrategicPartner {
  private service: serviceType;

  public constructor(token: string) {
    this.service = new Service(token);
  }

  /**
   * HEY OVER HERE!
   * THIS COULD BE USED DIRECTLY INSTEAD OF USING THE
   * Allow access to all the service calls about strategic partners
   */
  public strategicPartners = () => {
    const endpoint = "/strategic-partners"; // resource identifier

    return {
      getAll: () => this.GETStrategicPartners(endpoint),
      getOne: (id: string) => this.GETStrategicPartner(endpoint, id),
    };
  }

  /**
   * Retrieves all the existing strategic partners from the service
   * @param endpoint The base route for this service
   */
  public GETStrategicPartners = (endpoint: string) => {
    return this.service.fetch(`${endpoint}?_expand=locality&_embed=answers&_delay=1000`);
  }

  /**
   * Retrives the data of this strategic partner specified by its ID
   * @param endpoint The base route for this service
   * @param strategicPartnerId The ID of the strategic partner
   */
  public GETStrategicPartner = (endpoint: string, strategicPartnerId: string) => {
    return this.service.fetch(`${endpoint}/${strategicPartnerId}`);
  }
}
