import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withPageTitle } from "../HOCs";
import { InspectionsTemplate } from "../templates";
import { RootState } from "../../store/modules";
import { actions as inspectionActions } from "../../store/modules/inspection/actions";
import { actions as UIActions } from "../../store/modules/UI/actions";
import * as inspectionSelectors from "../../store/modules/inspection/selectors";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type InspectionsPageProps = StateProps & DispatchProps;

class InspectionsPage extends React.Component<InspectionsPageProps> {
  public componentDidMount() {
    const { loadInspections } = this.props;
    loadInspections("INSPECTIONS_TABLE");
  }

  public render() {
    return <InspectionsTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: RootState) => ({
  inspections: inspectionSelectors.selectInspectionState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadInspections: inspectionActions.loadInspections.request,
    createInspection: inspectionActions.createInspection.request,
    setComponentState: UIActions.setComponentState
  }, dispatch);

const ConnectedInspectionsPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPageTitle(InspectionsPage, "Inspecciones"));

export default ConnectedInspectionsPage;
