import React from "react";
import { Button, Icon, Tag, Modal as AntModal } from "antd";
import { ColumnProps } from "antd/lib/table";

import { cases } from "../../../copy.json";
import { Content, TableContainer, Table, TableSearchInput, TableFooter } from "../../atoms";
import { PageHeading } from "../../molecules";
import { CaseType } from "../../../types/Case";
import { reportTypes, caseTypes, caseStatuses } from "../../../utils/maps";
import { CreateCaseModal } from "./components";
import { CasesPageProps } from "../../pages/CasesPage";

type CasesTemplateProps = CasesPageProps;

interface CasesTemplateState {
  createCaseModal: {
    visible: boolean;
    processing: boolean;
    inputs?: {
      [key: string]: {
        value?: string | number;
      };
    }
  };
}

class CasesTemplate extends React.Component<CasesTemplateProps, CasesTemplateState> {
  public state: CasesTemplateState = {
    createCaseModal: {
      visible: false,
      processing: false,
      inputs: {
        caseDate: {
          value: new Date().getTime()
        }
      }
    }
  };

  private statusColors = {
    CLOSED: "green",
    OPEN: "red",
    ASSIGNED: "orange",
  };

  private dateFormat = "DD [de] MMMM [de] YYYY";

  private tableColumns: Array<ColumnProps<any>> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "REPORTE",
      dataIndex: "reportType",
      key: "reportType",
      align: "center",
      render: (reportType) => reportTypes[reportType]
    },
    {
      title: "INVOLUCRADO",
      dataIndex: "implicatedId",
      key: "implicatedId",
      align: "center",
    },
    {
      title: "TIPO",
      key: "caseType",
      dataIndex: "caseType",
      align: "center",
      render: (caseType) => caseTypes[caseType]
    },
    {
      title: "ASIGNADO",
      dataIndex: "assignedId",
      key: "assignedId",
      align: "center",
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "ESTADO",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => (
        <Tag style={{ width: "100%" }} color={this.statusColors[status]}>
          {caseStatuses[status]}
        </Tag>
      )
    }
  ];

  public render() {
    // const { push } = this.props;

    return (
      <>
        <CreateCaseModal
          onMetadataChange={this.onCaseMetadataChange}
          onInputChange={this.onInputChange}
          onSubmitCaseForm={this.onSubmitCaseForm}
        />
        <Content>
          <PageHeading
            title="Casos de seguimiento"
            renderRight={() => (
              <Button
                id="createCaseBtn"
                type="primary"
                size="large"
                style={{ borderRadius: 8 }}
                onClick={this.onClickCreateCase}
              >
                <Icon type="plus-circle" theme="filled" />
                Nuevo caso
              </Button>
            )}
          />
          <div style={{ padding: 30 }}>
            <TableContainer>
              <div style={{ padding: 30 }}>
                <TableSearchInput />
              </div>
              <Table
                pagination={false}
                columns={this.tableColumns}
                dataSource={[]}
                // onRowClick={(record: Case) => push(`/casos/${record.id}`)}
                locale={{ emptyText: "Sin datos" }}
              />
              <TableFooter
                label="casos de seguimiento"
                showing={[].length}
                total={[].length}
                pagination={{ current: 0, total: [].length }} />
            </TableContainer>
          </div>
        </Content>
      </>
    );
  }

  private onClickCreateCase = () => {
    const { setComponentState } = this.props;
    setComponentState("CREATE_CASE", { visible: true });
  }

  private onCaseMetadataChange = (type: string, value: CaseType | number) => {
    this.setState((state) => ({
      createCaseModal: {
        ...state.createCaseModal,
        inputs: {
          ...(state.createCaseModal.inputs || {}),
          [type]: { value }
        }
      }
    }));
  }

  private onInputChange = (changedField: object) => {
    this.setState((state) => ({
      createCaseModal: {
        ...state.createCaseModal,
        inputs: {
          ...(state.createCaseModal.inputs || {}),
          ...changedField
        }
      }
    }));
  }

  private onSubmitCaseForm = () => {
    const { createCaseModal } = this.state;
    const { setComponentState, createCase } = this.props;

    const args: { [key: string]: string | number | undefined } = {};
    const inputs = createCaseModal.inputs || {};
    Object.keys(inputs).map((input) => args[input] = inputs[input].value);

    if (
      args.assignedId !== undefined &&
      args.category !== undefined &&
      args.correctiveActions !== undefined &&
      args.description !== undefined &&
      args.implicatedId !== undefined &&
      args.localityId !== undefined &&
      args.location !== undefined &&
      args.reportedBy !== undefined
    ) {
      if (args.caseType !== undefined && args.caseDate !== undefined) {
        setComponentState("CREATE_CASE", { visible: true, processing: true });

        createCase(
          args.additionalComments ? String(args.additionalComments) : undefined,
          String(args.assignedId),
          String(args.category),
          Number(args.caseDate),
          args.caseType as CaseType,
          String(args.correctiveActions),
          String(args.description),
          String(args.implicatedId),
          String(args.localityId),
          String(args.location),
          String(args.reportedBy)
        );
      } else {
        if (args.caseType === undefined) {
          AntModal.error({
            title: "Información requerida",
            content: cases.formErrors.caseType
          });
        }
      }
    }
  }
}

export default CasesTemplate;
