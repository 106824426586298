import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

import styles from "./styles.less";

import { Title } from "../../atoms";

interface PageHeadingProps {
  title: string;
  titleSize?: number;
  backTo?: string;
  renderRight?: () => React.ReactNode;
}

const PageHeading: React.FC<PageHeadingProps> = ({ title, titleSize, backTo, renderRight }) => (
  <div className={styles.pageHeading}>
    {backTo && (
      <div className={styles.backToButton}>
        <Link to={backTo}>
          <Button type="primary" shape="circle" icon="arrow-left" />
        </Link>
      </div>
    )}
    <Title size={titleSize}>{title}</Title>
    {renderRight && (
      <div className={styles.renderRightArea}>{renderRight()}</div>
    )}
  </div>
);

export default PageHeading;
