import { ActionType, createAction } from "typesafe-actions";

import { Section } from "../../../types/Questionnaire";

const loadSections = {
  request: createAction("@section/LOAD_SECTIONS_REQUEST", (action) =>
    () => action()),
  success: createAction("@section/LOAD_SECTIONS_SUCCESS", (action) =>
    (sections: { [key: string]: Section }) => action({ sections })),
  error: createAction("@section/LOAD_SECTIONS_ERROR", (action) =>
    (error: any) => action({ error }))
};

export const actions = {
  loadSections
};

export type SectionAction = ActionType<typeof actions>;
