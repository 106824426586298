import { ActionType, createAction } from "typesafe-actions";

const setComponentState = createAction("@UI/SET_COMPONENT_STATE", (action) =>
  (componentId: string, state: object) => action({ componentId, state }));

export const actions = {
  setComponentState
};

export type UIAction = ActionType<typeof actions>;
