import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { QuestionnaireTemplate } from "../templates";
import { withPageTitle } from "../HOCs";
import { actions as questionnaireActions } from "../../store/modules/questionnaire/actions";

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type QuestionnairePageProps = DispatchProps;

class QuestionnairePage extends React.Component<QuestionnairePageProps> {
  public render() {
    return <QuestionnaireTemplate {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    createQuestionnaire: questionnaireActions.createQuestionnaire.request
  }, dispatch);

const ConnectedQuestionnairePage =
  connect(
    null,
    mapDispatchToProps
  )(QuestionnairePage);


export default withPageTitle(ConnectedQuestionnairePage, "Cuestionario");
