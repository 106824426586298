import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { push } from "connected-react-router";

import { RootState } from "../../store/modules";
import { actions as inspectionActions } from "../../store/modules/inspection/actions";
import { actions as questionnaireActions } from "../../store/modules/questionnaire/actions";
import * as inspectionSelectors from "../../store/modules/inspection/selectors";
import * as UISelectors from "../../store/modules/UI/selectors";
import { InspectionDetailsTemplate } from "../templates";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type InspectionDetailsPageProps = StateProps & DispatchProps & RouteChildrenProps<{ id: string }>;

class InspectionDetailsPage extends React.Component<InspectionDetailsPageProps> {
  public componentDidMount() {
    const { inspection, loadInspection, match } = this.props;
    if (!inspection && match) {
      loadInspection(match.params.id);
    }
  }

  public render() {
    const { inspection, loadQuestionnaire, questionnaire } = this.props;

    if (inspection && questionnaire.sections.length === 0) {
      loadQuestionnaire(inspection.questionnaireId, "QUESTIONNAIRE_TABLE");
    }

    return (
      <InspectionDetailsTemplate {...this.props} />
    );
  }
}

const mapStateToProps = (state: RootState, props: any) => ({
  inspection: inspectionSelectors.selectInspection(state, props),
  inspectionAnswers: inspectionSelectors.selectInspectionAnswers(state, props),
  questionnaire: inspectionSelectors.selectInspectionQuestionnaire(state, props)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadInspection: inspectionActions.loadInspection.request,
    loadQuestionnaire: questionnaireActions.loadQuestionnaire.request,
    saveInspectionAnswers: inspectionActions.saveInspectionAnswers.request,
    deleteInspection: inspectionActions.deleteInspection.request,
    push
  }, dispatch);

const ConnectedInspectionDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionDetailsPage);

export default ConnectedInspectionDetailsPage;
