import format from "date-fns/format";
import ESLocale from "date-fns/locale/es";

/**
 * Convert a unix timestamp number to a human understandable date
 * @param unixTimeStampDate A timestamp number
 */
export const getHumanDate = (unixTimeStampDate: number) => {
  let date = "";
  const diference = getDateDifference(unixTimeStampDate);

  if (diference > -2 && diference < 0) {
    date = "Ayer";
  } else if (diference === 0) {
    date = "Hoy";
  } else {
    date = format(unixTimeStampDate, "DD [de] MMMM [de] YYYY", { locale: ESLocale });
  }

  return date;
};

/**
 * Determines the difference between a Unix TimeStamp
 * and the today date
 * @param unixTimeStampDate A numeric unix timestamp
 * @returns An integer number
 * - equals to 0    | means it's the same day
 * - minor than 0   | means it's a date before today
 * - greater than 0 | means it's a date after today
 */
export const getDateDifference = (unixTimeStampDate: number) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24; // Miliseconds per day

  const nowDate = new Date();
  const otherDate = new Date(unixTimeStampDate * 1000);

  const utc1 = Date.UTC(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate());
  const utc2 = Date.UTC(otherDate.getFullYear(), otherDate.getMonth(), otherDate.getDate());

  const diference =  Math.floor((utc2 - utc1) / _MS_PER_DAY);
  return diference;
};
