import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push as pushAction } from "connected-react-router";
import { ColumnProps } from "antd/lib/table/interface";
import { default as withUIState, ComponentProps } from "../../../HOCs/withUIState";

import { TableContainer, Table, TableSearchInput, TableFooter } from "../../../atoms";
import { Localities, Locality } from "../../../../types/Locality";

interface ILocalitiesTableProps {
  localities: Localities;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type LocalitiesTableProps = ILocalitiesTableProps & DispatchProps & ComponentProps;

/**
 * Display a table listing the available Localities
 */
class LocalitiesTable extends React.Component<LocalitiesTableProps> {
 
  private tableColumns: Array<ColumnProps<any>> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "DIRECCIÓN",
      dataIndex: "address",
      key: "address",
      align: "center",
    },
    {
      title: "UNIDADES DE NEGOCIO",
      key: "bussinessUnits",
      dataIndex: "bussinessUnits",
      align: "center",
      render: (bussinessUnits?: any[]) =>
        bussinessUnits !== undefined
        ? bussinessUnits.length.toString()
        : "0"
    }
  ];

  public render() {

    const { localities, push, componentState } = this.props;

    return (
      <TableContainer>
        <div style={{ padding: 30 }}>
          <TableSearchInput />
        </div>
        <Table
        columns={this.tableColumns}
        dataSource={ Object.values(localities) }
        pagination={false}
        scroll={{x: false, y: false}}
        rowKey={(_, idx) => String(idx)}
        onRowClick={(record: Locality) => {
          push(`/localidades/${record.id}`);
        }}
        locale={{ emptyText: "Sin datos" }} />
        <TableFooter label="localidades" pagination={{ current: 1, total: 30 }} />
      </TableContainer>
    );
  }
}

/**
 * Allow to access to the pushAction as a prop
 * inside the component
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    push: pushAction
  }, dispatch);

const ConnectedLocaltiesTable = connect(undefined, mapDispatchToProps)(LocalitiesTable);

// SPECIFY STATE STORAGE TO USE WITH THIS COMPONENT
export default withUIState("LOCALITIES_LIST", ConnectedLocaltiesTable);
