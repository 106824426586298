import React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { push as pushAction } from "connected-react-router";
import { Tag } from "antd";
import { ColumnProps } from "antd/lib/table/interface";
import format from "date-fns/format";
import ESLocale from "date-fns/locale/es";

import {
  StrategicPartner,
  StrategicPartners,
} from "../../../../types/StrategicPartner";
import { Table, Avatar } from "../../../atoms";
import { strategicPartnerStatus } from "../../../../utils/maps";

import { default as withUIState, ComponentProps } from "../../../HOCs/withUIState";

interface IStrategicPartnersTableProps {
  strategicPartners: StrategicPartners;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type StrategicPartnersTableProps = IStrategicPartnersTableProps & DispatchProps & ComponentProps;

class StrategicPartnersTable extends React.Component<StrategicPartnersTableProps> {

  // this is the definition of the table columns as Ant expects
  private tableColumns: Array<ColumnProps<any>> = [
    {
      title: "",
      dataIndex: "avatar",
      key: "avatar",
      align: "center",
      render: (src?: string) =>  <Avatar src={src} size={40}/>
    },
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name",
      align: "center"
    },
    {
      title: "ÚLTIMA INSPECCIÓN",
      dataIndex: "lastInspectionDate",
      key: "lastInspectionDate",
      align: "center",
      render: (date: number) => date
        ? format(date, "DD [de] MMMM [de] YYYY", { locale: ESLocale })
        : <Tag color="volcano">Fecha no disponible</Tag>
    },
    {
      title: "INSPECCIONES",
      dataIndex: "totalInspections",
      key: "totalInspections",
      align: "center"
    },
    {
      title: "STRIKES",
      key: "strikes",
      dataIndex: "strikes",
      align: "center",
      render: (strikesNumber: number) =>
            <Tag color={ this.getStrikesTagColor(strikesNumber) }>
              {strikesNumber}
            </Tag>
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (statusKeyName: string) =>
            <Tag color={ this.getStatusTagColor(statusKeyName) }>
              {strategicPartnerStatus[statusKeyName]}
            </Tag>
      // The rows values at this column will be replaced using this enumeration. E: INACTIVE->Inactivo
    }
  ];

  public render() {
    const { strategicPartners, push, componentState } = this.props; // props given by the parent page

    return (
      <Table
        loading={ componentState.loading }
        pagination={false}
        columns={this.tableColumns}
        rowKey={(_, idx) => String(idx)}
        onRowClick={(record: StrategicPartner) => {
          push(`/socios/${record.id}`);
        }}
        dataSource={ Object.values(strategicPartners) } // this is needed because normalizr
        locale={{ emptyText: "Sin datos" }}
        scroll={{ x: false, y: false }}
      />
    );
  }

  /**
   * Retrieve the colour acording the status string provided
   * @param status the status string
   * @returns a color name
   */
  private getStatusTagColor = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "green";
      case "INACTIVE":
        return "";
      case "SUSPENDED":
        return "volcano";
        default:
          return "";
    }
  }

  /**
   * Retrieve the colour acording the number of strikes string provided
   * @param numberOfStrikes the count of strikes to evaluate string
   * @returns a color name
   */
  private getStrikesTagColor = (numberOfStrikes: number) => {
    switch (true) {
      case  (numberOfStrikes < 3):
        return "";
      case  (numberOfStrikes >= 3):
        return "volcano";
      default:
          return "";
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    push: pushAction
  }, dispatch);

const ConnectedStrategicPartnerTable = connect(undefined, mapDispatchToProps)(StrategicPartnersTable);

// SPECIFY STATE STORAGE TO USE WITH THIS COMPONENT
export default withUIState("STRATEGIC_PARTNERS_TABLE", ConnectedStrategicPartnerTable);
