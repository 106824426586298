export { default as InspectionsTemplate } from "./InspectionsTemplate";
export { default as InspectionDetailsTemplate } from "./InspectionDetailsTemplate";

export { default as CasesTemplate } from "./CasesTemplate";
export { default as CaseDetailsTemplate } from "./CaseDetailsTemplate";

export { default as ACRsTemplate } from "./ACRsTemplate";
export { default as ACRDetailsTemplate } from "./ACRDetailsTemplate";

export { default as StrategicPartnersTemplate } from "./StrategicPartnersTemplate";
export { default as StrategicPartnerDetailsTemplate } from "./StrategicPartnerDetailsTemplate";

export { default as LocalitiesTemplate } from "./LocalitiesTemplate";
export { default as LocalityDetailsTemplate } from "./LocalityDetailsTemplate";

export { default as QuestionnairesTemplate } from "./QuestionnairesTemplate";
export { default as QuestionnaireDetailsTemplate } from "./QuestionnaireDetailsTemplate";
export { default as QuestionnaireTemplate } from "./QuestionnaireTemplate";
