import { all } from "redux-saga/effects";

import * as watchers from "./watchers";

const allWatchers: any[] = [];
Object.keys(watchers).map((watcher) => {
  Array.prototype.push.apply(allWatchers, watchers[watcher]);
});

export default function* saga() {
  yield all(allWatchers);
}
