import { ActionType, createAction } from "typesafe-actions";
import { Localities, Locality } from "../../../types/Locality";

const loadLocalities = {
  request: createAction("@locality/LOAD_LOCALITIES_REQUEST", (action) =>
    (componentId?: string) => action({ componentId })),
  success: createAction("@locality/LOAD_LOCALITIES_SUCCESS", (action) =>
    (localities: Localities) => action({ localities })),
  error: createAction("@locality/LOAD_LOCALITIES_ERROR", (action) =>
    (error: any) => action({ error }))
};

const loadLocality = {
  request: createAction("@locality/LOAD_LOCALITY_REQUEST", (action) =>
  (localityId: string, componentId?: string) => action({ localityId, componentId })),
  success: createAction("@locality/LOAD_LOCALITY_SUCCESS", (action) =>
    (locality: Locality) => action({ locality })),
  error: createAction("@locality/LOAD_LOCALITY_ERROR", (action) =>
    (error: any) => action({ error }))
};

export const actions = {
  loadLocality,
  loadLocalities
};

export type LocalityAction = ActionType<typeof actions>;
