import React from "react";
import { Input, Row, Col, Divider } from "antd";

import {
  Content,
  TableContainer,
  TableSearchInput,
  TableFooter,
  TableFilterSelect,
} from "../../atoms";
import { Options } from "../../atoms/TableFilterSelect";
import { PageHeading } from "../../molecules";
import { default as withUIState, ComponentProps } from "../../HOCs/withUIState";
import { alterPageTitle } from "../../../utils/pageTitle";


import { TopForm, StrategicPartnerCasesTable } from "./components";
import { StrategicPartnerDetailsPageProps } from "../../pages/StrategicPartnerDetailsPage";
import { StrategicPartner } from "../../../types/StrategicPartner";

type StratregicPartnersTemplateProps = StrategicPartnerDetailsPageProps
                                      & ComponentProps
                                      & StrategicPartnerDetailsTemplateState;

interface StrategicPartnerDetailsTemplateState {
  strategicPartner?: StrategicPartner;
}

/**
 * Component
 */
class StrategicPartnersTemplate extends React.Component<
  StratregicPartnersTemplateProps> {

  /**
   * Used to populate the case status dropdown filter
   */
  private caseStatus: Options = [
    { value: "ALL", label: "Todas" },
    { value: "UNRESOLVED", label: "No resueltas" },
    { value: "RESOLVED", label: "Resueltas" },
  ];

  /**
   * Used to populate the strategic partner status dropdown filter
   */
  private strategicPartnerStatus: Options = [
    { value: "ACTIVE", label: "Activo" },
    { value: "INACTIVE", label: "Inactivo" },
    { value: "SUSPENDED", label: "Suspendido" },
  ];

  public constructor(props) {
    super(props)
    this.props.setComponentState("STRATEGIC_PARTNER_DETAILS",
    {
      filteredCases: [],
      isFirstLoad: true, // Allows to determinate if is the component is recently loaded
    });
  }

  public render() {
    const { strategicPartner } = this.props;

    return (
      <Content>
        <PageHeading
          title={strategicPartner ? strategicPartner.name : ""}
          backTo="/socios"
          renderRight={
            () =>
              (<TableFilterSelect
                options={this.strategicPartnerStatus}
                defaultValue={strategicPartner ? strategicPartner.status : ""} />)
          } />
        <TopForm
          responsibleName={strategicPartner ? strategicPartner.responsible.name : ""}
          assignatedLocality={strategicPartner ? strategicPartner.locality.name : ""}
        />
        <div style={{ padding: 30 }}>
          <TableContainer>
            <Row style={{ padding: 30 }}>
              <Col span={12}>
                <TableSearchInput />
              </Col>
              <Col span={12}>
                <TableFilterSelect
                  onChange={this.onCaseStatusChange}
                  options={this.caseStatus}
                  defaultValue={"ALL"} />
              </Col>
            </Row>
            <StrategicPartnerCasesTable
            strategicPartnerCases={this.getTableCasesDataSource(strategicPartner)}/>
            <TableFooter label="socios estratégicos" pagination={{ current: 1, total: 30 }} />
          </TableContainer>
        </div>
      </Content>
    );
  }

  /**
   * Receives all the strategic partner cases and returns a
   * filtered array of cases which matches whith the given
   * status
   * @param cases   Array of cases of the strategic partner
   * @param status  Status of the wanted cases
   */
  private filterCases = (cases: any, status: string) => {
    let filteredCases = [];
    if (status === "ALL") {
      filteredCases = cases;
    } else {
      filteredCases = (cases.length > 0) ? cases.filter(item => item.inspection.status === status) : [];
    }

    return filteredCases;
  }

  /**
   * Handle the onChange event. Allows to filter the cases
   * list an update the state
   * @param event   The event object provided by the input
   */
  private onCaseStatusChange = (value) => {
    const { componentState, setComponentState, strategicPartner } = this.props;
    if (componentState.isFirstLoad) { // Indicate that is not the first load
      setComponentState("STRATEGIC_PARTNER_DETAILS", { isFirstLoad: false });
    }

    const selectedStatus = value;
    const cases = strategicPartner ? strategicPartner.cases : [];
    const filteredCases = this.filterCases(cases, selectedStatus);
    setComponentState("STRATEGIC_PARTNER_DETAILS", { filteredCases });
  }

  /**
   * Returns the cases to populate the cases table
   * @param   strategicPartner Strategic partner data loaded
   */
  private getTableCasesDataSource = (strategicPartner: any) => {
      const { componentState } = this.props;
      let dataSource = [];
      if ( componentState.isFirstLoad ) {
        dataSource = strategicPartner ? strategicPartner.cases : [];
      } else {
        dataSource = componentState.filteredCases ? componentState.filteredCases : [];
      }

      return dataSource; // Always will have a value, an empty array at least
  }

  public render() {
    const { strategicPartner } = this.props;

    // This sentence allows to print the selected partner in the tab title
    if (strategicPartner !== undefined) {
      alterPageTitle(strategicPartner.name ? strategicPartner.name : "");
    }

    return (
      <Content>
        <PageHeading
          title={strategicPartner ? strategicPartner.name : ""}
          backTo="/socios"
          renderRight={
            () =>
              (<TableFilterSelect
                options={this.strategicPartnerStatus}
                defaultValue={strategicPartner ? strategicPartner.status : ""} />)
          } />
        <TopForm
          responsibleName={strategicPartner ? strategicPartner.responsible.name : ""}
          assignatedLocality={strategicPartner ? strategicPartner.locality.name : ""}
        />
        <div style={{ padding: 30 }}>
          <TableContainer>
            <Row style={{ padding: 30 }}>
              <Col span={12}>
                <TableSearchInput />
              </Col>
              <Col span={12}>
                <TableFilterSelect
                  onChange={this.onCaseStatusChange}
                  options={this.caseStatus}
                  defaultValue={"ALL"} />
              </Col>
            </Row>
            <StrategicPartnerCasesTable
            strategicPartnerCases={this.getTableCasesDataSource(strategicPartner)}/>
            <TableFooter label="socios estratégicos" pagination={{ current: 1, total: 30 }} />
          </TableContainer>
        </div>
      </Content>
    );
  }
}

export default withUIState("STRATEGIC_PARTNER_DETAILS", StrategicPartnersTemplate);
