import { schema } from "normalizr";

export const locality = new schema.Entity("localities");

export const answer = new schema.Entity("answers");

export const question = new schema.Entity("questions");

export const section = new schema.Entity("sections", {
  question
});

export const questionnaire = new schema.Entity("questionnaires", {
  sections: [section]
});

export const inspection = new schema.Entity("inspections", {
  locality,
  questionnaire,
  answers: [answer]
});

export const _case = new schema.Entity("cases", {
  locality
});

export const action = new schema.Entity("actions");

export const CR = new schema.Entity("crs", {
  actions: [action]
});

export const ACR = new schema.Entity("acrs", {
  locality
});

/**
 * Schema for strategic partners normalizr
 */
export const strategicPartners = new schema.Entity("strategicPartners");
