import { ActionType, createAction } from "typesafe-actions";

import { ACRs as ACRsType, ACR as ACRType } from "../../../types/ACR";

const loadACRs = {
  request: createAction("@ACR/LOAD_ACRS_REQUEST", (action) =>
    (componentId?: string) => action({ componentId })),
  success: createAction("@ACR/LOAD_ACRS_SUCCESS", (action) =>
    (ACRs: ACRsType) => action({ ACRs })),
  error: createAction("@ACR/LOAD_ACRS_ERROR", (action) =>
    (error: any) => action({ error }))
};

const loadACR = {
  request: createAction("@ACR/LOAD_ACR_REQUEST", (action) =>
    (acrId: string, componentId?: string) => action({ acrId, componentId })),
  success: createAction("@ACR/LOAD_ACR_SUCCESS", (action) =>
    (ACR: { [key: string]: ACRType }) => action({ ACR })),
  error: createAction("@ACR/LOAD_ACR_ERROR", (action) =>
    (error: any) => action({ error }))
};

const createACR = {
  request: createAction("@ACR/CREATE_ACR_REQUEST", (action) =>
    (ACR: any, componentId?: string) => action({ ACR, componentId })),
  success: createAction("@ACR/CREATE_ACR_SUCCESS", (action) =>
    (ACR: { [key: string]: ACRType }) => action({ ACR })),
  error: createAction("@ACR/CREATE_ACR_ERROR", (action) =>
    (error: any) => action({ error }))
};

const updateACR = {
  request: createAction("@ACR/UPDATE_ACR_REQUEST", (action) =>
    (ACR: any, componentId?: string) => action({ ACR, componentId })),
  success: createAction("@ACR/UPDATE_ACR_SUCCESS", (action) =>
    (ACR: { [key: string]: ACRType }) => action({ ACR })),
  error: createAction("@ACR/UPDATE_ACR_ERROR", (action) =>
    (error: any) => action({ error }))
};

const deleteACR = {
  request: createAction("@ACR/DELETE_ACR_REQUEST", (action) =>
    (acrId: string, componentId?: string) => action({ acrId, componentId })),
  success: createAction("@ACR/DELETE_ACR_SUCCESS", (action) =>
    (acrId: string) => action({ acrId })),
  error: createAction("@ACR/DELETE_ACR_ERROR", (action) =>
    (error: any) => action({ error }))
};

export const actions = {
  loadACRs,
  loadACR,
  createACR,
  updateACR,
  deleteACR
};

export type ACRAction = ActionType<typeof actions>;
