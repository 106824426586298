import { Reducer } from "redux";
import { getType } from "typesafe-actions";

import { actions, UIAction } from "./actions";

export interface UIState {
  [key: string]: {
    [key: string]: any;
  };
}

export const initialState: UIState = {};

const UIReducer: Reducer<UIState, UIAction> =
  (state = initialState, action: UIAction) => {
    switch (action.type) {
      case getType(actions.setComponentState): {
        const { componentId, state: componentState } = action.payload;

        return {
          ...state,
          [componentId]: {
            ...(state[componentId] || {}),
            ...componentState
          }
        };
      }
      default:
        return state;
    }
  };

export default UIReducer;
