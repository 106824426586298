import { Reducer } from "redux";
import { message } from "antd";
import { getType } from "typesafe-actions";

import { cases } from "../../../copy.json";
import { bugsnagClient } from "../../../bugsnag";
import { Case } from "../../../types/Case";
import { actions, CaseAction } from "./actions";

export interface CaseState {
  [key: string]: Case;
}

export const initialState: CaseState = {};

const caseReducer: Reducer<CaseState, CaseAction> =
  (state = initialState, action: CaseAction) => {
    switch (action.type) {
      // CREATE_CASE
      case getType(actions.createCase.success): {
        const { _case } = action.payload;

        return {
          ...state,
          ..._case
        };
      }
      case getType(actions.createCase.error): {
        message.error(cases.postError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      // LOAD_CASE
      case getType(actions.loadCases.success): {
        const { cases } = action.payload;

        return {
          ...state,
          ...cases
        };
      }
      case getType(actions.loadCases.error): {
        message.error(cases.loadError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      case getType(actions.loadCase.success): {
        const { _case } = action.payload;

        return {
          ...state,
          ..._case
        };
      }
      case getType(actions.loadCase.error): {
        message.error(cases.singleLoadError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      case getType(actions.updateCase.success): {
        const { _case } = action.payload;

        return {
          ...state,
          ..._case
        };
      }
      case getType(actions.updateCase.error): {
        message.error(cases.singleLoadError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      // DELETE_CASE
      case getType(actions.deleteCase.success): {
        const { caseId } = action.payload;
        const { [caseId]: deleted, ...newState } = state;
        message.success(cases.deleteSuccess);

        return newState;
      }
      case getType(actions.deleteCase.error): {
        message.error(cases.deleteError);
        bugsnagClient.notify(action.payload.error);
        return state;
      }
      default:
        return state;
    }
  };

export default caseReducer;
