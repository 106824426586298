import { RouteChildrenProps } from "react-router";
import { createSelector } from "reselect";

import { RootState } from "..";
import { selectQuestionnaireState } from "../questionnaire/selectors";
import { selectSectionState } from "../section/selectors";
import { selectAnswerState } from "../answer/selectors";
import { Section, QuestionnaireWithSections } from "../../../types/Questionnaire";

export const selectInspectionState = (state: RootState) => state.inspection;
export const selectInspectionIdFromRouteProps =
  (state: RootState, props: RouteChildrenProps<{ id: string }>) =>
    props.match ? props.match.params.id : undefined;

export const selectInspection = createSelector(
  [selectInspectionState, selectInspectionIdFromRouteProps],
  (state, inspectionId) => inspectionId && state[inspectionId]
);

export const selectInspectionAnswers = createSelector(
  [selectInspection, selectAnswerState],
  (inspection, answerState) => {
    if (inspection) {
      return Object.values(answerState).filter((answer) => answer.inspectionId === inspection.id);
    }

    return [];
  }
);

export const selectInspectionQuestionnaireId = createSelector(
  [selectInspection],
  (inspection) => inspection && inspection.questionnaireId
);

export const selectQuestionnaire = createSelector(
  [selectQuestionnaireState, selectInspectionQuestionnaireId],
  (questionnaireState, questionnaireId) => questionnaireId !== undefined && questionnaireState[questionnaireId]
);

export const selectInspectionQuestionnaire = createSelector(
  [selectQuestionnaire, selectSectionState],
  (questionnaire, sectionState): QuestionnaireWithSections => {
    if (questionnaire && questionnaire.sections) {
      const sections: Section[] = [];

      (questionnaire.sections as string[]).map((section) => {
        if (sectionState[section]) {
          sections.push(sectionState[section]);
        }
      });

      return {
        ...questionnaire,
        sections
      };
    }

    return {
      ...questionnaire,
      sections: []
    } as QuestionnaireWithSections;
  }
);

export const selectInspectionWithQuestionnaire = createSelector(
  [selectInspectionState, selectQuestionnaireState, selectInspectionIdFromRouteProps],
  (inspectionState, questionnaireState, inspectionId) => {
    const inspection = inspectionId && inspectionState[inspectionId];

    if (inspection) {
      const questionnaire = questionnaireState[inspection.questionnaireId];

      if (questionnaire) {
        return {
          ...inspection,
          questionnaire
        };
      } else {
        return inspection;
      }
    }

    return undefined;
  }
);

export const selectInspectionWithId = (inspectionId: string) => createSelector(
  [selectInspectionState],
  (state) => state[inspectionId]
);
