import * as React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { RootState } from "../../store/modules";
import { actions as UIActions } from "../../store/modules/UI/actions";
import * as UISelectors from "../../store/modules/UI/selectors";

// Temporary fix until ts-jest includes support for TS 3.5.x
type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

type StateProps = ReturnType<ReturnType<typeof mapStateToProps>>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type InjectedProps = "componentState" | "setComponentState";

export type ComponentProps = StateProps & DispatchProps;

const withUIState = <P extends ComponentProps>(componentId: string, WrappedComponent: React.ComponentType<P>) => {
  return connect<{}, {}, Omit<P, InjectedProps>, RootState>(mapStateToProps(componentId), mapDispatchToProps)
    (class extends React.Component<P> {
      public render() {
        return (
          <WrappedComponent
            {...this.props as P}
          />
        );
      }
    } as any);
};

const mapStateToProps = (componentId: string) => (state: RootState) => ({
  componentState: UISelectors.selectComponentState(componentId)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    setComponentState: UIActions.setComponentState
  }, dispatch);

export default withUIState;
