import React from "react";
import { connect, DispatchProp } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Select } from "antd";

import { AsyncSelect } from "../../atoms";
import { RootState } from "../../../store/modules";
import { actions as localityActions } from "../../../store/modules/locality/actions";
import * as localitySelectors from "../../../store/modules/locality/selectors";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type LocalitySelectProps = StateProps & DispatchProps;

const AsyncLocalitySelect = AsyncSelect("LOCALITY_SELECT");

const LocalitySelect: React.FC<LocalitySelectProps> = ({ loadLocalities, localities, ...props }) => (
  <AsyncLocalitySelect
    {...props}
    onFocus={() => loadLocalities("LOCALITY_SELECT")}
    placeholder="Selecciona una..."
    size="large"
  >
    {Object.keys(localities).map((locality) => (
      <Select.Option value={localities[locality].id}>
        {localities[locality].name}
      </Select.Option>
    ))}
  </AsyncLocalitySelect>
);

const mapStateToProps = (state: RootState) => ({
  localities: localitySelectors.selectLocalityState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadLocalities: localityActions.loadLocalities.request
  }, dispatch);

const ConnectedLocalitySelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalitySelect);

export default ConnectedLocalitySelect;
