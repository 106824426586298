import React from "react";
import { connect, DispatchProp } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Select } from "antd";

import { AsyncSelect } from "../../atoms";
import { RootState } from "../../../store/modules";
import { actions as questionnaireActions } from "../../../store/modules/questionnaire/actions";
import * as questionnaireSelectors from "../../../store/modules/questionnaire/selectors";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type QuestionnaireSelectProps = StateProps & DispatchProps;

const AsyncQuestionnaireSelect = AsyncSelect("QUESTIONNAIRE_SELECT");

const QuestionnaireSelect: React.FC<QuestionnaireSelectProps> = ({ loadQuestionnaires, questionnaires, ...props }) => (
  <AsyncQuestionnaireSelect
    {...props}
    onFocus={() => loadQuestionnaires("QUESTIONNAIRE_SELECT")}
    placeholder="Selecciona el cuestionario a aplicar."
    size="large"
  >
    {Object.keys(questionnaires).map((questionnaire) => (
      <Select.Option value={questionnaires[questionnaire].id}>
        {questionnaires[questionnaire].title}
      </Select.Option>
    ))}
  </AsyncQuestionnaireSelect>
);

const mapStateToProps = (state: RootState) => ({
  questionnaires: questionnaireSelectors.selectQuestionnaireState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadQuestionnaires: questionnaireActions.loadQuestionnaires.request
  }, dispatch);

const ConnectedQuestionnaireSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireSelect);

export default ConnectedQuestionnaireSelect;
