import React from "react";
import {
  Button,
  Select,
  DatePicker,
  Col,
  Row,
  Form,
  Input,
  List,
  Icon,
  Typography,
  Divider,
  Modal,
} from "antd";
import moment from "moment";

import { Content, LoadingContent } from "../../atoms";
import { CaseDetailsPageProps } from "../../pages/CaseDetailsPage";
import { PageHeading } from "../../molecules";
import { caseStatuses, caseTypes } from "../../../utils/maps";
import { CaseStatus, CaseType, Case } from "../../../types/Case";
import { CreateCaseModal } from "../CasesTemplate/components";
import { cases } from "../../../copy.json";
import styles from "./styles.less";

type TemplateProps = CaseDetailsPageProps;

interface TemplateState {
  currentUserId: string;
  currentCase: Case;
  createCaseModal: {
    visible: boolean;
    processing: boolean;
    inputs?: {
      [key: string]: {
        value?: string | number;
      };
    }
  };
}

class CaseDetailsTemplate extends React.Component<TemplateProps, TemplateState> {
  public state: TemplateState = {
    currentUserId: "1",
    currentCase: {
      id: "0",
      caseType: "HEALTH_AND_SECURITY",
      caseDate: "",
      folio: "",
      assignedId: "",
      implicatedId: "",
      localityId: "",
      reportedBy: "",
      location: "",
      description: "",
      resolution: "",
      category: "",
      status: "CLOSED",
      correctiveActions: "",
    },
    createCaseModal: {
      visible: false,
      processing: false,
      inputs: {
        caseDate: {
          value: new Date().getTime()
        }
      }
    }
  };

  private dateFormat = "DD [de] MMMM [de] YYYY";
  private documents = [
    "test.docx",
    "test.jpg"
  ];
  private documentsApproved = [];

  public render() {
    const { _case } = this.props;

    if (_case) {
      return (
        <Content>
          <CreateCaseModal
            onMetadataChange={this.onCaseMetadataChange}
            onInputChange={this.onInputChange}
            onSubmitCaseForm={this.onSubmitCaseForm}
            onChangeStatus={this.onChangeStatus}
            onCancel={this.onCancelCaseForm}
            _case={_case}
          />
          <PageHeading
            title={`Caso ${_case.folio}`}
            backTo="../casos"
            renderRight={() => (
              <div>
                <Button
                  type="primary"
                  shape="circle"
                  icon="edit"
                  title={"Editar caso"}
                  style={{ marginRight: 20 }}
                  onClick={this.onClickCreateCase}
                />
                <Button
                  type="primary"
                  shape="circle"
                  icon="delete"
                  title={"Eliminar caso"}
                  style={{ marginRight: 20 }}
                  onClick={this.showDeleteConfirm}
                />
                <Button
                  id="createCaseBtn"
                  type="primary"
                  size="large"
                  style={{ borderRadius: 8 }}
                  onClick={() => this.openModal("Asignar usuario para resolución", "COMPLETED", "resolution")}
                  disabled={_case.status !== "COMPLETED" as CaseStatus ? false : true}
                >
                  {_case.status !== "COMPLETED" as CaseStatus ? "Marcar completado" : "Completado"}
                </Button>
              </div>
            )}
          />
          <div style={{ margin: 30, marginTop: 20 }}>
            <Select
              value={caseTypes[_case.caseType]}
              disabled
            >
              {Object.keys(caseTypes).map((type) => (
                <Select.Option key={type} value={type}>
                  {caseTypes[type]}
                </Select.Option>
              ))}
            </Select>
            <DatePicker
              disabled
              value={moment(new Date(_case.caseDate), this.dateFormat)}
              format={this.dateFormat}
              style={{ marginLeft: 20 }}
            />
            <div style={{ float: "right" }}>
              <Select
                value={caseStatuses[_case.status]}
                onChange={this.onChangeStatus}
                placeholder="Seleccionar estatus"
              >
                {Object.keys(caseStatuses).map((status) => (
                  <Select.Option key={status} value={status}>
                    {caseStatuses[status]}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ margin: 30, marginRight: 200 }}>
            <Form>
              <Row>
                <Col span={12}>
                  <h3>Categoria</h3>
                  <Form.Item style={{ marginRight: 20 }} >
                    <Input value={_case.category} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <h3>Departamento o socio estratégico involucrado</h3>
                  <Form.Item>
                    {/* TODO: add deparments */}
                    <Input value={_case.implicatedId} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <h3>Localidad</h3>
                  <Form.Item style={{ marginRight: 20 }} >
                    <Input value={_case.localityId} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <h3>Reportó</h3>
                  <Form.Item>
                    <Input value={_case.reportedBy} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <h3>Asignado / Responsable</h3>
                  <Form.Item style={{ marginRight: 20 }} >
                    <Input value={_case.assignedId} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <h3>Ubicación exacta del hallazgo</h3>
                  <Form.Item>
                    <Input value={_case.location} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h3>Descripción del hallazgo</h3>
                  <Form.Item>
                    <Input.TextArea value={_case.description} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h3>Acciones correctivas</h3>
                  <Form.Item>
                    <Input.TextArea value={_case.correctiveActions} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h3>Comentarios adicionales</h3>
                  <Form.Item>
                    <Input.TextArea value={_case.comments} placeholder="No hay comentarios adicionales" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h3>Prueba de hallazgo</h3>
                  <Form.Item>
                    <List
                      bordered
                      dataSource={this.documents}
                      renderItem={(item) => (
                        <List.Item>
                          <Icon type="file" theme="filled" style={{ marginRight: 30, fontSize: 20 }} />
                          <Typography.Text
                            type="warning"
                            underline
                            style={{ cursor: "pointer" }}
                          >
                            {item}
                          </Typography.Text>
                        </List.Item>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Typography.Title level={2} style={{ marginTop: 20, marginBottom: 0 }}>Resolución</Typography.Title>
                  <Divider style={{ marginTop: 5 }} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h3>Resumen de solución</h3>
                  <Form.Item>
                    <Input.TextArea value={_case.resolution} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h3>Documentos probatorios</h3>
                  <Form.Item>
                    <List
                      bordered
                      dataSource={this.documentsApproved}
                      renderItem={(item) => (
                        <List.Item>
                          <Icon type="file" theme="filled" style={{ marginRight: 30, fontSize: 20 }} />
                          <Typography.Text
                            type="warning"
                            underline
                            style={{ cursor: "pointer" }}
                          >
                            {item}
                          </Typography.Text>
                        </List.Item>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      );
    }

    return <LoadingContent />;
  }

  private onChangeStatus = (ev: string) => {
    const { _case } = this.props;

    if (_case) {
      switch (ev) {
        case "ASSIGNED":
          return this.openModal("Asignar usuario responsable", ev, "assignedId");
        case "COMPLETED":
          return this.openModal("Asignar usuario para resolución", ev, "resolution");
        default:
          return this.onUpdateStatus(_case.id, ev);
      }
    }
  }

  private openModal = (title: string, status: string, field: string) => {
    const { _case, push } = this.props;

    // TODO: import users from API
    const users = [
      { id: 1, name: "Juan Perez" },
      { id: 2, name: "Pedro Garcia" }
    ];

    const onUpdateStatus = this.onUpdateStatus;

    if (_case) {
      if (status === "COMPLETED" && this.documentsApproved.length === 0 && !_case.comments) {
        Modal.confirm({
          title: "¿Seguro que deseas marcar como completado?",
          content:
            "Este caso de seguimiento aún no tiene pruebas de cumplimiento, ¿Quieres proseguir con el completado?",
          okText: "Completar",
          cancelText: "Cancelar",
          onOk() {
            onUpdateStatus(_case.id, status);
            push("/casos");
          },
        });
      } else if (status === "ASSIGNED") {
        Modal.info({
          title,
          content: (
            <Select
              defaultValue={users[0].name}
              onChange={(ev: string) => this.setState(() => ({ currentUserId: ev }))}>
              {Object.keys(users).map((index) => (
                <Select.Option key={index} value={users[index].id}>
                  {users[index].name}
                </Select.Option>
              ))}
            </Select>
          ),
          onOk() {
            onUpdateStatus(_case.id, status, field);
          },
        });
      } else {
        onUpdateStatus(_case.id, status);
      }
    }
  }

  private onUpdateStatus = (caseId: string, status: string, field?: string) => {
    const { currentUserId } = this.state;
    const { updateCase, _case } = this.props;

    if (_case) {
      if (caseId !== undefined) {

        _case.status = status as CaseStatus;

        if (field === "responsible") {
          _case.assignedId = currentUserId;
        }

        updateCase(caseId, _case, true);
      }
    }
  }

  private onClickCreateCase = () => {
    const { setComponentState, _case } = this.props;

    if (_case) {
      this.setState(() => ({ currentCase: _case }));
      setComponentState("CREATE_CASE", { visible: true });
    }
  }

  private onCaseMetadataChange = (type: string, value: CaseType | CaseStatus | number) => {
    const { updateCase, _case } = this.props;

    if (_case) {
      const mergedValues = { ..._case, [type]: value };
      updateCase(_case.id, mergedValues, true);

      this.setState((state) => ({
        createCaseModal: {
          ...state.createCaseModal,
          inputs: {
            ...(state.createCaseModal.inputs || {}),
            [type]: { value }
          }
        }
      }));
    }
  }

  private onInputChange = (changedField: object) => {
    this.setState((state) => ({
      createCaseModal: {
        ...state.createCaseModal,
        inputs: {
          ...(state.createCaseModal.inputs || {}),
          ...changedField
        }
      }
    }));
  }

  private onSubmitCaseForm = () => {
    const { createCaseModal } = this.state;
    const { updateCase, _case } = this.props;

    if (_case) {
      const args: { [key: string]: string | number | undefined } = {};
      const inputs = createCaseModal.inputs || {};
      Object.keys(inputs).map((input) => inputs[input].value ? args[input] = inputs[input].value : "");

      const mergedValues = { ..._case, ...args };

      updateCase(_case.id, mergedValues);
    }
  }

  private onCancelCaseForm = () => {
    const { updateCase } = this.props;
    const { currentCase } = this.state;

    if (currentCase) {
      updateCase(currentCase.id, currentCase);
    }
  }

  private showDeleteConfirm = () => {
    const onDelete = this.onDelete;

    Modal.confirm({
      title: "¿Seguro que deseas eliminar este caso?",
      content: "Este proceso es irreversible, ¿Quieres proseguir con el borrado?",
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        onDelete();
      },
    });
  }

  private onDelete = () => {
    const { _case, deleteCase } = this.props;

    if (_case) {
      deleteCase(_case.id);
    }
  }
}

export default CaseDetailsTemplate;
