import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import networkSaga from "./sagas/network";
import {
  RootAction,
  RootReducer as createRootReducer,
  RootState
} from "./modules";

export const history = createBrowserHistory();

// Middlewares
const sagaMiddleware = createSagaMiddleware();

// Compose middlewares
const middlewares = [
  sagaMiddleware,
  routerMiddleware(history)
];

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const rootReducer = createRootReducer(history);

const store = createStore<RootState, RootAction, {}, {}>(rootReducer, enhancer);

// Run the saga
sagaMiddleware.run(networkSaga);

export default store;
