import { Reducer } from "redux";
import { message } from "antd";
import { getType } from "typesafe-actions";

import { bugsnagClient } from "../../../bugsnag";
import { StrategicPartner, StrategicPartners } from "../../../types/StrategicPartner";
import { actions, StrategicPartnerAction } from "./actions";

import { RootReducer } from "../../../store/modules";
import { selectStrategicPartnerState, selectStrategicPartners } from "./selectors";

export interface StrategicPartnersState {
  [key: number]: StrategicPartner;
}

/**
 * Initial state which is a type of StrategicPartnersState Interface (empty)
 */
export const initialState: StrategicPartnersState = {};

/**
 * Reducts the strategic partner state
 * @param state   Acumulated state
 * @param action  Action to reduct the state
 */
const strategicPartnerReducer: Reducer<StrategicPartnersState, StrategicPartnerAction> =
  (state = initialState, action: StrategicPartnerAction) => {

    switch (action.type) {
      // LOAD_STRATEGIC_PARTNERS_SUCCESS
      case getType(actions.loadStrategicPartners.success): {
        const { strategicPartners } = action.payload;
        return {
          ...state,
          ...strategicPartners
        };
      }
      // ESTE MUCHACHO OBTIENE LOS DATOS QUE NOS HACEN FALTA, EL ACTIONDEBE USAR EL ID PARA OBTENER LOS DATOS NECESARIOS
      // CON LOS MÉTODOS NECESARIOS, UN CUANDO NOS LOS DEVUELVA, NOSOTROS JUGAMOS CON EL STATE
      // LOAD_STRATEGIC_PARTNER_SUCCESS
      case getType(actions.loadStrategicPartner.success): {
        const { strategicPartner } = action.payload; // Complete data of this partner
        return {
          ...state,
          ...strategicPartner
        };
      }
      default:
        return state;
    }
  };

export default strategicPartnerReducer;
