import Service from "./Service";

type serviceType = Service;

/**
 * Contains the Localities calls
 */
export default class Localities {
  private service: serviceType;

  public constructor(token: string) {
    this.service = new Service(token);
  }

  /**
   * Returns the calls of the localities
   */
  public locality = () => {
    const endpoint = "/localities"; // resource identifier

    return {
      getAll: () => this.GETLocalities(endpoint),
      getOne: (id: string) => this.GETLocality(endpoint, id),
    };
  }

  /**
   * Retrieves all the available localities from the service
   * @param endpoint The base route for this service
   */
  public GETLocalities = (endpoint: string) => {
    return this.service.fetch(`${endpoint}`);
  }

  /**
   * Retrives the data of the locality specified by its ID
   * @param endpoint The base route for this service
   * @param localityId The ID of locality
   */
  public GETLocality = (endpoint: string, localityId: string) => {
    return this.service.fetch(`${endpoint}/${localityId}`);
  }
}
