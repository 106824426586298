import React from "react";
import { Form, Select, InputNumber, Input, Switch } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { inspections } from "../../../copy.json";
import { QuestionnaireSelect, LocalitySelect } from "../../molecules/AsyncSelects";

interface ICreateInspectionFormProps extends FormComponentProps {
  id: string;
  onChange: (changedFields: object) => void;
  onSubmit: () => void;
}

type CreateInspectionFormProps = ICreateInspectionFormProps;

export class CreateInspectionForm extends React.Component<CreateInspectionFormProps> {
  public render() {
    const { id, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const withSupervisor = getFieldValue("withSupervisor");

    return (
      <Form id={id} layout="vertical" onSubmit={this.handleSubmit}>
        <Form.Item label="Cuestionario">
          {getFieldDecorator("questionnaire", {
            rules: [{ required: true, message: inspections.formErrors.questionnaire }]
          })(
            <QuestionnaireSelect />
          )}
        </Form.Item>
        <Form.Item label="Título">
          {getFieldDecorator("title", {
            rules: [{ required: true, message: inspections.formErrors.title }]
          })(
            <Input size="large" style={{ width: "100%" }} />
          )}
        </Form.Item>
        <Form.Item label="Localidad">
          {getFieldDecorator("localityId", {
            rules: [{ required: true, message: inspections.formErrors.locality }]
          })(
            <LocalitySelect />
          )}
        </Form.Item>
        <Form.Item label="Inspeccionado">
          {getFieldDecorator("inspected", {
            rules: [
              {
                required: true,
                message: inspections.formErrors.inspected
              }
            ]
          })(
            <Select placeholder="Selecciona uno..." size="large">
              <Select.Option value="ecogas">Departamento Ecogas</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Nombre del departamento o Socio estratégico">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: inspections.formErrors.name }]
          })(
            <Select placeholder="Selecciona uno..." size="large">
              <Select.Option value="SALES">Ventas</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Actividad">
          {getFieldDecorator("activity", {
            rules: [{ required: true, message: inspections.formErrors.activity }]
          })(
            <Select placeholder="Selecciona uno..." size="large">
              <Select.Option value="CAMBACEO_SALES">Ventas de cambaceo</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Trabajadores observados">
          {getFieldDecorator("observedWorkers", {
            rules: [
              { required: true, message: inspections.formErrors.observedWorkers.required },
              { type: "integer", message: inspections.formErrors.observedWorkers.integer },
              {
                validator: (_: any, value: string, cb: (error?: string) => void) => {
                  const num = parseInt(value, 10);
                  num <= 0 ? cb(inspections.formErrors.observedWorkers.positiveInteger) : cb();
                }
              }
            ]
          })(
            <InputNumber size="large" min={1} style={{ width: "100%" }} />
          )}
        </Form.Item>
        <Form.Item label="Supervisor Ecogas presente">
          {getFieldDecorator("withSupervisor")(
            <Switch />
          )}
        </Form.Item>
        {withSupervisor && (
          <Form.Item label="Supervisor Ecogas">
            {getFieldDecorator("supervisor", {
              rules: [{ required: withSupervisor, message: inspections.formErrors.supervisor }]
            })(
              <Select placeholder="Selecciona uno..." size="large">
                <Select.Option value={1}>Francisco Chim</Select.Option>
              </Select>
            )}
          </Form.Item>
        )}
      </Form>
    );
  }

  private handleSubmit = (evt: React.FormEvent<any>) => {
    const { onSubmit, form: { validateFields } } = this.props;

    if (evt) {
      evt.preventDefault();
    }

    validateFields((err: Error, _: any) => {
      if (err) { return; }
      return onSubmit();
    });
  }
}

const onFieldsChange =
  (props: CreateInspectionFormProps, changedFields: object) =>
    props.onChange(changedFields);

export default Form.create({ name: "createInspection", onFieldsChange })(CreateInspectionForm);
