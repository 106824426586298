import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { RootState } from "../../store/modules";
import { withPageTitle } from "../HOCs";
import { CasesTemplate } from "../templates";
import { actions as caseActions } from "../../store/modules/case/actions";
import { actions as UIActions } from "../../store/modules/UI/actions";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type CasesPageProps = StateProps & DispatchProps;

class CasesPage extends React.Component<CasesPageProps> {
  public componentDidMount() {
    window.document.title = window.document.title + " - Casos de seguimiento";
  }

  public componentWillUnmount() {
    window.document.title = "Ecogas";
  }

  public render() {
    return <CasesTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    createCase: caseActions.createCase.request,
    setComponentState: UIActions.setComponentState
  }, dispatch);

const ConnectedCasesPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPageTitle(CasesPage, "Casos"));

export default ConnectedCasesPage;
