import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { RootState } from "../../store/modules";
import { withPageTitle } from "../HOCs";
import { actions as UIActions} from "../../store/modules/UI/actions";

import { actions as localityActions } from "../../store/modules/locality/actions";
import * as localitySelectors from "../../store/modules/locality/selectors";
import { LocalitiesTemplate } from "../templates";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

// Makes the component prop type compatible with this props
export type LocalitiesPageProps = StateProps & DispatchProps;

/**
 * Display a list of available localities
 */
class LocalitiesPage extends React.Component<LocalitiesPageProps> {

  public componentDidMount() {
    // TODO: LOAD THE CONTENT HERE
    const { loadLocalities } = this.props;
    loadLocalities("LOCALITIES_LIST");
  }

  public render() {
    return <LocalitiesTemplate {...this.props}/>;
  }
}

/**
 * Allow to access the actions as props. Receives the
 * dispatch and returns the action dispatchers as handable
 * proppperties
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) => (
  bindActionCreators({
    loadLocalities: localityActions.loadLocalities.request,
    setComponentState: UIActions.setComponentState
  }, dispatch) );

/**
 * Receives the store to work with and returns the state
 * the component can access throughout a selector. This
 * state will be returned as handable propperties.
 * @param state The store to work with
 */
const mapStateToProps = (state: RootState) => ( {
  localities: localitySelectors.selectLocalityState(state)
} );

/**
 * Connects the state props to the component state
 * and the dispatch props to the component state
 * dispatchers
 */
const ConnectedLocalitiesPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withPageTitle(LocalitiesPage, "Localidades"));

export default ConnectedLocalitiesPage;
