import { normalize } from "normalizr";
import { all, call, fork, take, put } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { push } from "connected-react-router";

import { actions as ACRActions } from "../../../modules/ACR/actions";
import { actions as UIActions } from "../../../modules/UI/actions";
import { ACR } from "../../../../schemas";
import Ecogas from "../../../../services/Ecogas";

const EcogasAPI = new Ecogas("");

function* fetchACRs(componentId?: string) {
  const { response, error } = yield call(EcogasAPI.ACRs().getAll);

  if (response) {
    const { acrs } = normalize(response, [ACR]).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(ACRActions.loadACRs.success(acrs)),
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(ACRActions.loadACRs.error(error))
    ]);
  }
}

function* fetchACR(acrId: string, componentId?: string) {
  const { response, error } = yield call(EcogasAPI.ACRs().getOne, acrId);

  if (response) {
    const { acrs } = normalize(response, ACR).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(ACRActions.loadACR.success(acrs)),
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { loading: false })),
      put(ACRActions.loadACR.error(error))
    ]);
  }
}

function* postACR(ACRObject: any, componentId?: string) {
  ACRObject.date = new Date().getTime();
  const { response, error } = yield call(EcogasAPI.ACRs().post, ACRObject);

  if (response) {
    const { acrs } = normalize(response, ACR).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { visible: false, processing: false })),
      put(ACRActions.loadACR.success(acrs))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { visible: true, processing: false })),
      put(ACRActions.loadACR.error(error))
    ]);
  }
}

function* putACR(ACRObject: any, componentId?: string) {
  const { response, error } = yield call(EcogasAPI.ACRs().update, ACRObject.id, ACRObject);

  if (response) {
    const { acrs } = normalize(response, ACR).entities;

    yield all([
      componentId && put(UIActions.setComponentState(componentId, { visible: false, processing: false })),
      put(ACRActions.updateACR.success(acrs)),
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { visible: true, processing: false })),
      put(ACRActions.updateACR.error(error))
    ]);
  }
}

function* loadACRs(componentId?: string, page?: number, limit?: number) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { loading: true })),
    call(fetchACRs, componentId)
  ]);
}

function* loadACR(acrId: string, componentId?: string) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { loading: true })),
    call(fetchACR, acrId, componentId)
  ]);
}

function* createACR(ACRObject: any, componentId?: string) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { visible: true, processing: true })),
    call(postACR, ACRObject, componentId)
  ]);
}

function* updateACR(ACRObject: any, componentId?: string) {
  yield all([
    componentId && put(UIActions.setComponentState(componentId, { visible: true, processing: true })),
    call(putACR, ACRObject, componentId)
  ]);
}

function* deleteACR(acrId: string, componentId?: string) {
  const { response, error } = yield call(EcogasAPI.ACRs().delete, acrId);

  if (response !== undefined) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { processing: false })),
      put(ACRActions.deleteACR.success(acrId)),
      put(push(`/acrs`))
    ]);
  } else if (error) {
    yield all([
      componentId && put(UIActions.setComponentState(componentId, { processing: false })),
      put(ACRActions.deleteACR.error(error))
    ]);
  }
}

function* watchLoadACRs() {
  while (true) {
    const { payload } = yield take(getType(ACRActions.loadACRs.request));
    yield fork(loadACRs, payload.componentId);
  }
}

function* watchLoadACR() {
  while (true) {
    const { payload } = yield take(getType(ACRActions.loadACR.request));
    yield fork(loadACR, payload.acrId, payload.componentId);
  }
}

function* watchCreateACR() {
  while (true) {
    const { payload } = yield take(getType(ACRActions.createACR.request));
    yield fork(createACR, payload.ACR, payload.componentId);
  }
}

function* watchUpdateACR() {
  while (true) {
    const { payload } = yield take(getType(ACRActions.updateACR.request));
    yield fork(updateACR, payload.ACR, payload.componentId);
  }
}

function* watchDeleteACR() {
  while (true) {
    const { payload } = yield take(getType(ACRActions.deleteACR.request));
    yield fork(deleteACR, payload.acrId, payload.componentId);
  }
}

export default [
  fork(watchLoadACRs),
  fork(watchLoadACR),
  fork(watchCreateACR),
  fork(watchUpdateACR),
  fork(watchDeleteACR)
];
