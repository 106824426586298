import React from "react";
import { Button, Icon, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import format from "date-fns/format";
import ESLocale from "date-fns/locale/es";

import { ACRsPageProps } from "../../pages/ACRsPage";
import { PageHeading } from "../../molecules";
import { Content, TableContainer, Table, TableSearchInput, TableFooter } from "../../atoms";
import { ACR } from "../../../types/ACR";
import { ACRModal } from "../../organisms/Modals";

interface ACRTemplateState {
  createACRModal: {
    visible: boolean;
    processing: boolean;
    inputs?: {
      [key: string]: {
        value?: string | number;
      };
    }
  };
}

type ACRsTemplateProps = ACRsPageProps;

class ACRsTemplate extends React.Component<ACRsTemplateProps, ACRTemplateState> {
  private tableColumns: Array<ColumnProps<any>> = [
    {
      title: "ID",
      dataIndex: "folio",
      key: "folio",
      align: "center",
    },
    {
      title: "LOCALIDAD",
      dataIndex: "localityId",
      key: "localityId",
      align: "center",
    },
    {
      title: "INVOLUCRADO",
      dataIndex: "implicatedId",
      key: "implicatedId",
      align: "center",
    },
    {
      title: "REPORTÓ",
      key: "reportedBy",
      dataIndex: "reportedBy",
      align: "center",
    },
    {
      title: "LESIÓN",
      dataIndex: "injury",
      key: "injury",
      align: "center",
      render: (injury: boolean) => injury ? "Si" : "No"
    },
    {
      title: "FECHA",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (date: number | string) => format(date, "DD [de] MMMM [de] YYYY", { locale: ESLocale })
    },
    {
      title: "CERRADOS",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (actions: number) => (
        <Tag color={"green"}> 1 </Tag>
      )
    },
    {
      title: "ABIERTOS",
      dataIndex: "actionsOpen",
      key: "actionsOpen",
      align: "center",
      render: (actionsOpen: number) => (
        <Tag color={"magenta"}> 2 </Tag>
      )
    }
  ];

  public render() {
    const { ACRs, push, createACR } = this.props;

    return (
      <>
        <ACRModal submitAction={createACR} />
        <Content>
          <PageHeading
            title="ACR"
            renderRight={() => (
              <Button
                id="createCaseBtn"
                type="primary"
                size="large"
                style={{ borderRadius: 8 }}
                onClick={this.onClickCreateACR}
              >
                <Icon type="plus-circle" theme="filled" />
                Crear ACR
              </Button>
            )}
          />
          <div style={{ padding: 30 }}>
            <TableContainer>
              <div style={{ padding: 30 }}>
                <TableSearchInput />
              </div>
              <Table
                pagination={false}
                columns={this.tableColumns}
                dataSource={Object.values(ACRs)}
                locale={{ emptyText: "Sin datos" }}
                rowKey={(_, idx) => String(idx)}
                onRowClick={(record: ACR) => push(`/acrs/${record.id}`)}
              />
              <TableFooter
                label="acciones causa raíz"
                showing={Object.values(ACRs).length}
                total={Object.values(ACRs).length}
                pagination={{ current: 0, total: Object.values(ACRs).length }} />
            </TableContainer>
          </div>
        </Content>
      </>
    );
  }

  private onClickCreateACR = () => {
    const { setComponentState } = this.props;
    setComponentState("ACR_MODAL", { visible: true });
  }

}

export default ACRsTemplate;
