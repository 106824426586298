import React from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";

import styles from "./styles.less";

const TableSearchInput: React.FC<InputProps> = ({ ...rest }) =>
  <Input.Search className={styles.input} {...rest} />;

TableSearchInput.defaultProps = {
  placeholder: "Buscar"
};

export default TableSearchInput;
