import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { WrappedFormUtils } from "antd/lib/form/Form";

import { Content } from "../../atoms";
import { PageHeading } from "../../molecules";
import { QuestionnaireForm } from "../../organisms/Forms";
import { QuestionnairePageProps } from "../../pages/QuestionnairePage";
import withUIState, { ComponentProps } from "../../HOCs/withUIState";

type QuestionnaireTemplateProps = QuestionnairePageProps;

const PlainSaveButton: React.FC<ComponentProps> = (props) => (
  <Button
    loading={props.componentState.loading}
    form="questionnaire"
    htmlType="submit"
    type="primary"
    style={{ marginRight: 20 }}
    size="large"
  >
    Guardar
  </Button>
);

const SaveButton = withUIState("SAVE_QUESTIONNAIRE", PlainSaveButton);

class QuestionnaireTemplate extends React.Component<QuestionnaireTemplateProps> {
  public render() {
    return (
      <Content>
        <PageHeading
          title="Nuevo cuestionario"
          renderRight={() => (
            <div>
              <SaveButton />
              <Link to="../cuestionarios">
                <Button
                  style={{ marginRight: 20 }}
                  size="large"
                  onClick={() => undefined}
                >
                  Cerrar
                </Button>
              </Link>
            </div>
          )}
        />
        <div style={{ padding: 30 }}>
          <QuestionnaireForm onSubmit={this.onSubmitQuestionnaireForm} />
        </div>
      </Content>
    );
  }

  private onSubmitQuestionnaireForm = (form: WrappedFormUtils<any>) => {
    const { createQuestionnaire } = this.props;

    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      createQuestionnaire(values, "SAVE_QUESTIONNAIRE");
    });
  }
}

export default QuestionnaireTemplate;
