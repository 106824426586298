import React from "react";

import styles from "./styles.less";

interface TitleProps {
  size?: number;
}

const Title: React.FC<TitleProps> = ({ children, size }) =>
  <span className={styles.title} style={{ fontSize: size }}>{children}</span>;

Title.defaultProps = {
  size: 40
};

export default Title;
