import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Form, Input, Select, Row, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { cases } from "../../../copy.json";
import { RootState } from "../../../store/modules";
import { LocalitySelect } from "../../molecules/AsyncSelects";
import { Case } from "../../../types/Case";
import _case from "../../../store/sagas/network/watchers/case";

type StateProps = ReturnType<typeof mapDispatchToProps>;
type DispatchProps = ReturnType<typeof mapStateToProps>;

interface ICreateCaseFormProps extends FormComponentProps {
  id: string;
  onChange: (changedFields: object) => void;
  onSubmit: () => void;
  data?: Case;
}

type CreateCaseFormProps = StateProps & DispatchProps & ICreateCaseFormProps;

export class CreateCaseForm extends React.Component<CreateCaseFormProps> {
  public render() {
    const {
      id,
      form,
      data
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form id={id} layout="vertical" onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Categoría">
              {getFieldDecorator("category", {
                initialValue: data && data.category,
                rules: [{ required: true, message: cases.formErrors.category }]
              })(
                <Select size="large">
                  <Select.Option value="OPERATIONS_AND_MAINTENANCE">
                    Operaciones y mantenimiento
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Departamento o socio estratégico involucrado">
              {getFieldDecorator("implicatedId", {
                initialValue: data && data.implicatedId,
                rules: [{ required: true, message: cases.formErrors.implicated }]
              })(
                <Select size="large">
                  <Select.Option value="MAINTENANCE">
                    Mantenimiento
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Localidad">
              {getFieldDecorator("localityId", {
                initialValue: data && data.localityId,
                rules: [{ required: true, message: cases.formErrors.locality }]
              })(
                <LocalitySelect />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* TODO: Should be defaulted to the current user */}
            <Form.Item label="Reportó">
              {getFieldDecorator("reportedBy", {
                initialValue: data && data.reportedBy,
                rules: [{ required: true, message: cases.formErrors.reportedBy }]
              })(
                <Select size="large">
                  <Select.Option value="fco">
                    Francisco Chim
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Asignado / Responsable">
              {getFieldDecorator("assignedId", {
                initialValue: data && data.assignedId,
                rules: [{ required: true, message: cases.formErrors.assignedTo }]
              })(
                <Select size="large">
                  <Select.Option value="luis">
                    Luis Cervantes
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Ubicación exacta del hallazgo">
              {getFieldDecorator("location", {
                initialValue: data && data.location,
                rules: [{ required: true, message: cases.formErrors.location }]
              })(
                <Input size="large" />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Descripción del hallazgo">
          {getFieldDecorator("description", {
            initialValue: data && data.description,
            rules: [{ required: true, message: cases.formErrors.description }]
          })(
            <Input.TextArea rows={3} />
          )}
        </Form.Item>
        <Form.Item label="Acciones correctivas">
          {getFieldDecorator("correctiveActions", {
            initialValue: data && data.correctiveActions,
            rules: [{ required: true, message: cases.formErrors.correctiveActions }]
          })(
            <Input.TextArea rows={3} />
          )}
        </Form.Item>
        <Form.Item label="Comentarios adicionales">
          {getFieldDecorator("additionalComments", { initialValue: data && data.comments })(
            <Input.TextArea rows={3} placeholder="Sin comentarios adicionales." />
          )}
        </Form.Item>
      </Form>
    );
  }

  private handleSubmit = (evt: React.FormEvent<any>) => {
    const { onSubmit, form: { validateFields }, form } = this.props;

    if (evt) {
      evt.preventDefault();
    }

    validateFields((err, _) => {
      if (err) { return; }
      onSubmit();
      return form.resetFields();
    });
  }
}

const onFieldsChange =
  (props: CreateCaseFormProps, changedFields: object) =>
    props.onChange(changedFields);

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const ConnectedCreateCaseForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "createCase", onFieldsChange })(CreateCaseForm));

export default ConnectedCreateCaseForm;
