import React from "react";

import { Content, TableContainer, Table, TableSearchInput, TableFooter } from "../../atoms";
import { PageHeading } from "../../molecules";

class UsersTemplate extends React.Component {
  private tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "NOMBRE",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "CORREO ELECTRÓNICO",
      dataIndex: "emailAddress",
      key: "emailAddress"
    },
    {
      title: "ROL",
      key: "role",
      dataIndex: "role"
    }
  ];

  public render() {
    return (
      <Content>
        <PageHeading title="Usuarios" />
        <div style={{ padding: 30 }}>
          <TableContainer>
            <div style={{ padding: 30 }}>
              <TableSearchInput />
            </div>
            <Table columns={this.tableColumns} locale={{ emptyText: "Sin datos" }} />
            <TableFooter label="usuarios" pagination={{ current: 1, total: 30 }} />
          </TableContainer>
        </div>
      </Content>
    );
  }
}

export default UsersTemplate;
