/**
 * This object connects to the service throught the fetch method
 */
export default class Service {
  private token: string;
  private scheme: "https://" | "http://" = "http://";
  private host?: string = process.env.SERVER_ENDPOINT;

  /**
   * Service Construnctor
   * @param token The authentication token needed to allow connections
   */
  public constructor(token: string) {
    this.token = token;
  }

  /**
   * Fetch a resource throught the specified endpoint and options
   * @param endpoint      Resource uri
   * @param fetchOptions  Options for the request
   */
  public fetch = (endpoint: string, fetchOptions?: object) => {
    return fetch(`${this.scheme}${this.host}${endpoint}`, fetchOptions)
      .then((response) => response.json().then((json) => ({ json, response })))
      .then(({ json, response }) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
      .then((response) => ({ response }))
      .catch((error) => ({ response: undefined, error }));
  }
}
