import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { push as pushAction } from "connected-react-router";
import { Tag, Row, Col, Divider, Input } from "antd";
import { ColumnProps } from "antd/lib/table/interface";
import format from "date-fns/format";
import ESLocale from "date-fns/locale/es";

import {
  StrategicPartnerInspections,
  StrategicPartnerInspection,
  StrategicPartner,
  StrategicPartners,
  StrategicPartnerStatus
} from "../../../../types/StrategicPartner";
import { Table, Avatar } from "../../../atoms";
import { strategicPartnerStatus } from "../../../../utils/maps";

import { default as withUIState, ComponentProps } from "../../../HOCs/withUIState";

interface IStrategicPartnersDetailsFormProps {
  responsibleName: string;
  assignatedLocality: string;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type StrategicPartnersTableProps = IStrategicPartnersDetailsFormProps & DispatchProps & ComponentProps;

class TopForm extends React.Component<StrategicPartnersTableProps> {

  public render() {
    return (
      <Fragment>
        <Row style={{ padding: "10pt 30pt 5pt 30pt" }}>
          <Col span={18}>
            <Row gutter={16}>
              <Divider />
              <Col className="gutter-row" span={12}>
                <h2>Responsable de Empresa</h2>
                <Input size="large" disabled={true} value={
                  this.props.responsibleName}/>
              </Col>
              <Col className="gutter-row" span={12}>
                <h2>Localidad asignada</h2>
                <Input size="large" disabled={true} value={
                  this.props.assignatedLocality}/>
              </Col>
            </Row>
          </Col>
          <Col span={6}></Col>{/* EMPTY COLUMN */}
        </Row>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    push: pushAction
  }, dispatch);

const ConnectedStrategicPartnerForm = connect(undefined, mapDispatchToProps)(TopForm);

// SPECIFY STATE STORAGE TO USE WITH THIS COMPONENT
export default withUIState("STRATEGIC_PARTNER_CASES_FORM", ConnectedStrategicPartnerForm);
