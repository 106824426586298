import { ActionType, createAction } from "typesafe-actions";

import { Cases, Case, CaseType } from "../../../types/Case";
import { object } from "prop-types";

const loadCases = {
  request: createAction("@case/LOAD_CASES_REQUEST", (action) =>
    (componentId?: string) => action({ componentId })),
  success: createAction("@case/LOAD_CASES_SUCCESS", (action) =>
    (cases: Cases) => action({ cases })),
  error: createAction("@case/LOAD_CASES_ERROR", (action) =>
    (error: any) => action({ error }))
};

const loadCase = {
  request: createAction("@case/LOAD_CASE_REQUEST", (action) =>
    (caseId: string, componentId?: string) => action({ caseId, componentId })),
  success: createAction("@case/LOAD_CASE_SUCCESS", (action) =>
    (_case: { [key: string]: Case }) => action({ _case })),
  error: createAction("@case/LOAD_CASE_ERROR", (action) =>
    (error: any) => action({ error }))
};

const createCase = {
  request: createAction("@case/CREATE_CASE_REQUEST", (action) =>
    (
      comments: string | undefined,
      assignedId: string,
      category: string,
      date: number,
      type: CaseType,
      correctiveActions: string,
      description: string,
      implicatedId: string,
      localityId: string,
      location: string,
      reportedBy: string,
      noRedirect?: boolean
    ) => action({
      comments,
      assignedId,
      category,
      date,
      type,
      correctiveActions,
      description,
      implicatedId,
      localityId,
      location,
      reportedBy,
      noRedirect
    })),
  success: createAction("@case/CREATE_CASE_SUCCESS", (action) =>
    (_case: Cases) => action({ _case })),
  error: createAction("@case/CREATE_CASE_ERROR", (action) =>
    (error: any) => action({ error }))
};

const updateCase = {
  request: createAction("@case/UPDATE_CASE_REQUEST", (action) =>
    (
      caseId: string,
      _case: object,
      notCloseWindow?: boolean
    ) => action({
      caseId,
      _case,
      notCloseWindow
    })),
  success: createAction("@case/UPDATE_CASE_SUCCESS", (action) =>
    (_case: { [key: string]: Case }) => action({ _case })),
  error: createAction("@case/UPDATE_CASE_ERROR", (action) =>
    (error: any) => action({ error }))
};

const deleteCase = {
  request: createAction("@case/DELETE_CASE_REQUEST", (action) =>
    (caseId: string) => action({ caseId })),
  success: createAction("@case/DELETE_CASE_SUCCESS", (action) =>
    (caseId: string) => action({ caseId })),
  error: createAction("@case/DELETE_CASE_ERROR", (action) =>
    (error: any) => action({ error }))
};

export const actions = {
  loadCases,
  loadCase,
  createCase,
  updateCase,
  deleteCase
};

export type CaseAction = ActionType<typeof actions>;
