import { Reducer } from "redux";
import { getType } from "typesafe-actions";

import { actions, AnswerAction } from "./actions";
import { Answer } from "../../../types/Questionnaire";

export interface AnswerState {
  [key: string]: Answer;
}

const initialState: AnswerState = {};

const answerReducer: Reducer<AnswerState, AnswerAction> =
  (state = initialState, action: AnswerAction) => {
    switch (action.type) {
      case getType(actions.receiveAnswers): {
        const { answers } = action.payload;

        return {
          ...state,
          ...answers
        };
      }
      default:
        return state;
    }
  };

export default answerReducer;
