import React from "react";
import { Form, Input, Button, Icon } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import uuidv4 from "uuid/v4";

import { Card, Title, Chip } from "../../atoms";

interface QuestionnaireSection {
  id: string;
  questionnaireId: string;
  title: string;
}

interface SectionQuestion {
  id: string;
  sectionId: string;
  displayText: string;
}

export interface QuestionnaireState {
  id: string;
  title: string;
  description: string;
  sections: {
    [id: string]: Partial<QuestionnaireSection>;
  };
  questions: {
    [id: string]: Partial<SectionQuestion>;
  };
}

type QuestionnaireFormState = Partial<QuestionnaireState>;

interface QuestionnaireFormOwnProps {
  onSubmit: (form: WrappedFormUtils<any>) => void;
}

type QuestionnaireFormProps = QuestionnaireFormOwnProps & FormComponentProps;

export class QuestionnaireForm extends React.Component<QuestionnaireFormProps, QuestionnaireFormState> {
  private static questionnaireId = uuidv4();
  private static sectionId = uuidv4();
  private static questionId = uuidv4();

  private static initialState: QuestionnaireFormState = {
    id: QuestionnaireForm.questionnaireId,
    sections: {
      [QuestionnaireForm.sectionId]: {
        id: QuestionnaireForm.sectionId,
        questionnaireId: QuestionnaireForm.questionnaireId
      }
    },
    questions: {
      [QuestionnaireForm.questionId]: {
        id: QuestionnaireForm.questionId,
        sectionId: QuestionnaireForm.sectionId
      }
    }
  };

  public state = QuestionnaireForm.initialState;

  public componentWillMount() {
    QuestionnaireForm.questionnaireId = uuidv4();
    QuestionnaireForm.sectionId = uuidv4();
    QuestionnaireForm.questionId = uuidv4();
  }

  public render() {
    const { id, sections = {} } = this.state;
    const { form: { getFieldDecorator } } = this.props;

    const sectionsValues = Object.values(sections);

    return (
      <Form id="questionnaire" layout="vertical" onSubmit={this.onSubmit}>
        {getFieldDecorator("id", { initialValue: id })(
          <Input size="large" type="hidden" />
        )}
        <div style={{ marginBottom: 20 }}>
          <Card>
            <Form.Item label={<Title size={18}>Nombre del cuestionario</Title>}>
              {getFieldDecorator("title", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, ingresa el título."
                  }
                ]
              })(
                <Input size="large" />
              )}
            </Form.Item>
            <Form.Item label={<Title size={18}>Descripción del cuestionario</Title>}>
              {getFieldDecorator("description", {
                rules: [
                  {
                    required: true,
                    message: "Por favor, ingresa la descripción."
                  }
                ]
              })(
                <Input.TextArea rows={3} />
              )}
            </Form.Item>
          </Card>
        </div>
        {sectionsValues.map(this.renderSection)}
        <Button
          icon="plus-circle"
          type="primary"
          size="large"
          onClick={this.addSection}
          block
        >
          Nueva sección
        </Button>
      </Form>
    );
  }

  private renderSection = (section: Partial<QuestionnaireSection>, idx: number) => {
    const { questions = {} } = this.state;
    const { form: { getFieldDecorator } } = this.props;

    const questionsValues = Object.values(questions);
    const questionsForSection = questionsValues.filter((question) => question.sectionId === section.id);

    return (
      <div style={{ marginBottom: 20 }}>
        {getFieldDecorator(`sections.${section.id}.id`, { initialValue: section.id })(
          <Input size="large" type="hidden" />
        )}
        {getFieldDecorator(`sections.${section.id}.questionnaireId`, { initialValue: section.questionnaireId })(
          <Input size="large" type="hidden" />
        )}
        <Card>
          <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
            <Title size={18}>Sección {idx + 1}</Title>
            {idx > 0 && (
              <Icon
                type="delete"
                theme="filled"
                onClick={() => this.deleteSection(section.id!)}
                style={{ fontSize: 24, color: "#00548F" }}
              />
            )}
          </div>
          <Form.Item>
            {getFieldDecorator(`sections.${section.id}.title`, {
              rules: [
                {
                  required: true,
                  message: "Por favor, ingresa el título de está sección."
                }
              ]
            })(
              <Input placeholder="Título de la sección" size="large" />
            )}
          </Form.Item>
          {questionsForSection.map((question, questionIdx) => this.renderQuestion(idx, question, questionIdx))}
          <div style={{ display: "flex", cursor: "pointer" }} onClick={() => this.addQuestion(section.id!)}>
            <Chip>
              <Icon type="plus-circle" theme="filled" />
            </Chip>
            <div style={{ fontSize: 16, marginLeft: 20, alignSelf: "center" }}>Nueva pregunta</div>
          </div>
        </Card>
      </div>
    );
  }

  private renderQuestion = (sectionIdx: number, question: SectionQuestion, questionIdx: number) => {
    const { form: { getFieldDecorator } } = this.props;
    return (
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
        {getFieldDecorator(`questions.${question.id}.id`, { initialValue: question.id })(
          <Input size="large" type="hidden" />
        )}
        {getFieldDecorator(`questions.${question.id}.sectionId`, { initialValue: question.sectionId })(
          <Input size="large" type="hidden" />
        )}
        <Chip>{sectionIdx + 1}.{questionIdx + 1}</Chip>
        <Form.Item style={{ flex: 1, margin: "0 20px" }}>
          {getFieldDecorator(`questions.${question.id}.displayText`, {
            rules: [
              {
                required: true,
                message: "Por favor, ingresa la pregunta."
              }
            ]
          })(
            <Input.TextArea rows={3} />
          )}
        </Form.Item>
        <div>
          <Icon
            type="copy"
            theme="filled"
            style={{ fontSize: 24, color: "#00548F" }}
            onClick={() => this.duplicateQuestion(question.id!)}
          />
          {questionIdx > 0 && (
            <Icon
              type="delete"
              theme="filled"
              style={{ fontSize: 24, color: "#00548F" }}
              onClick={() => this.deleteQuestion(question.id!)}
            />
          )}
        </div>
      </div>
    );
  }

  private addSection = () => {
    const sectionId = uuidv4();
    const questionId = uuidv4();
    this.setState((state) => ({
      ...state,
      sections: {
        ...state.sections,
        [sectionId]: {
          id: sectionId,
          questionnaireId: QuestionnaireForm.questionnaireId
        }
      },
      questions: {
        ...state.questions,
        [questionId]: {
          id: questionId,
          sectionId
        }
      }
    }));
  }

  private deleteSection = (sectionId: string) => {
    const { sections = {}, questions = {} } = this.state;
    const { [sectionId]: deleted, ...newSections } = sections;
    const questionIds = Object.keys(questions);
    const newQuestions = {};

    const newQuestionIds = questionIds.filter((questionId) => questions[questionId].sectionId !== sectionId);
    newQuestionIds.map((questionId) => {
      newQuestions[questionId] = questions[questionId];
    });

    this.setState((state) => ({
      ...state,
      sections: newSections,
      questions: newQuestions
    }));
  }

  private addQuestion = (sectionId: string) => {
    const questionId = uuidv4();
    this.setState((state) => ({
      ...state,
      questions: {
        ...state.questions,
        [questionId]: {
          id: questionId,
          sectionId
        }
      }
    }));
  }

  private duplicateQuestion = (questionId: string) => {
    const { questions = {} } = this.state;
    const { form } = this.props;

    const newQuestionId = uuidv4();
    const questionToDuplicate = { ...questions[questionId] };
    questionToDuplicate.id = newQuestionId;

    this.setState((state) => ({
      ...state,
      questions: {
        ...state.questions,
        [newQuestionId]: questionToDuplicate
      }
    }), () => {
      const originalValue = form.getFieldValue(`questions.${questionId}.displayText`);
      form.setFieldsValue({
        [`questions.${questionToDuplicate.id}.displayText`]: originalValue
      });
    });
  }

  private deleteQuestion = (questionId: string) => {
    const { questions = {} } = this.state;
    const { [questionId]: deleted, ...newQuestions } = questions;

    this.setState((state) => ({
      ...state,
      questions: newQuestions
    }));
  }

  private onSubmit = (evt: React.FormEvent<any>) => {
    const { form, onSubmit } = this.props;
    evt.preventDefault();
    onSubmit(form);
  }
}

export default Form.create({ name: "questionnaire" })(QuestionnaireForm);
