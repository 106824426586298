import { Reducer } from "redux";
import { getType } from "typesafe-actions";

import { Section } from "../../../types/Questionnaire";
import { actions, SectionAction } from "./actions";

export interface SectionState {
  [key: string]: Section;
}

const initialState: SectionState = {};

const inspectionReducer: Reducer<SectionState, SectionAction> =
  (state = initialState, action: SectionAction) => {
    switch (action.type) {
      case getType(actions.loadSections.success): {
        const { sections } = action.payload;

        return {
          ...state,
          ...sections
        };
      }
      default:
        return state;
    }
  };

export default inspectionReducer;
