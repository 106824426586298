import React from "react";
import { Layout } from "antd";

import styles from "./styles.less";

const Content: React.FC = ({ children }) =>
  <Layout.Content className={styles.content}>
    {children}
  </Layout.Content>;

export default Content;
