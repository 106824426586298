import { ActionType, createAction } from "typesafe-actions";

import { Inspections, Inspection } from "../../../types/Inspection";
import { AnswerType } from "src/types/Questionnaire";

const loadInspections = {
  request: createAction("@inspection/LOAD_INSPECTIONS_REQUEST", (action) =>
    (componentId?: string) => action({ componentId })),
  success: createAction("@inspection/LOAD_INSPECTIONS_SUCCESS", (action) =>
    (inspections: Inspections) => action({ inspections })),
  error: createAction("@inspection/LOAD_INSPECTIONS_ERROR", (action) =>
    (error: any) => action({ error }))
};

const loadInspection = {
  request: createAction("@inspection/LOAD_INSPECTION_REQUEST", (action) =>
    (inspectionId: string, componentId?: string) => action({ inspectionId, componentId })),
  success: createAction("@inspection/LOAD_INSPECTION_SUCCESS", (action) =>
    (inspection: { [key: string]: Inspection }) => action({ inspection })),
  error: createAction("@inspection/LOAD_INSPECTION_ERROR", (action) =>
    (error: any) => action({ error }))
};

const createInspection = {
  request: createAction("@inspection/CREATE_INSPECTION_REQUEST", (action) =>
    (
      questionnaireId: string,
      title: string,
      localityId: string,
      inspected: string,
      name: string,
      activity: string,
      observedWorkers: number,
      supervisor?: string
    ) => action({
      questionnaireId,
      title,
      localityId,
      inspected,
      name,
      activity,
      observedWorkers,
      supervisor
    })),
  success: createAction("@inspection/CREATE_INSPECTION_SUCCESS", (action) =>
    (inspection: Inspections) => action({ inspection })),
  error: createAction("@inspection/CREATE_INSPECTION_ERROR", (action) =>
    (error: any) => action({ error }))
};

const saveInspectionAnswers = {
  request: createAction("@inspection/SAVE_INSPECTION_ANSWERS_REQUEST", (action) =>
    (inspectionId: string, answers: { [key: string]: { value: AnswerType, comment?: string } }, componentId?: string) =>
      action({ inspectionId, answers, componentId })),
  success: createAction("@inspection/SAVE_INSPECTION_ANSWERS_SUCCESS", (action) =>
    (inspection: { [key: string]: Inspection }) => action({ inspection })),
  error: createAction("@inspection/SAVE_INSPECTION_ANSWERS_ERROR", (action) =>
    (error: any) => action({ error }))
};

const deleteInspection = {
  request: createAction("@inspection/DELETE_INSPECTION_REQUEST", (action) =>
    (inspectionId: string) => action({ inspectionId })),
  success: createAction("@inspection/DELETE_INSPECTION_SUCCESS", (action) =>
    (inspectionId: string) => action({ inspectionId })),
  error: createAction("@inspection/DELETE_INSPECTION_ERROR", (action) =>
    (error: any) => action({ error }))
};

const uploadImage = {
  request: createAction("@inspection/UPLOAD_IMAGE_REQUEST", (action) =>
    (body: object) => action({ body })),
  success: createAction("@inspection/UPLOAD_IMAGE_SUCCESS", (action) =>
    (body: object) => action({ body })),
  error: createAction("@inspection/UPLOAD_IMAGE_ERROR", (action) =>
    (error: any) => action({ error }))
};

export const actions = {
  loadInspections,
  loadInspection,
  createInspection,
  saveInspectionAnswers,
  deleteInspection,
  uploadImage
};

export type InspectionAction = ActionType<typeof actions>;
