import React from "react";
import { Select, Button, Icon, Spin, Popover, Input, Modal as AntModal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { cases } from "../../../../copy.json";
import { Table, PhotoThumbnail } from "../../../atoms";
import { Question, AnswerType, QuestionnaireWithSections } from "../../../../types/Questionnaire";
import withUIState, { ComponentProps } from "../../../HOCs/withUIState";
import { actions as inspectionActions } from "../../../../store/modules/inspection/actions";
import { CreateCaseModal } from "../../CasesTemplate/components";
import { CaseType, Case } from "../../../../types/Case";
import { actions as caseActions } from "../../../../store/modules/case/actions";

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface QuestionnaireTableOwnProps {
  questionnaire: QuestionnaireWithSections;
  currentSectionIdx: string;
  answers: {
    [key: string]: {
      value: AnswerType;
      caseId?: string;
      comment?: string;
      src?: any;
      _case?: object;
    }
  };

  onAnswerChange: (questionId: string, value: AnswerType) => void;
  onAnswerCommentChange: (questionId: string, comment?: string) => void;
  onAnswerPhotoChange: (questionId: string, file: File) => void;
  onAnswerCaseChange: (questionId: string, caseId: string, _case: object, isDelete?: boolean) => void;
}

type QuestionnaireTableProps = QuestionnaireTableOwnProps & ComponentProps & DispatchProps;

interface QuestionnaireTableState {
  visiblePopoverId?: string;
  currentQuestionId: string,
  currentCaseId: number,
  currentCase: object | undefined,
  createCaseModal: {
    visible: boolean;
    processing: boolean;
    inputs?: {
      [key: string]: {
        value?: string | number;
      };
    }
  };
}

class QuestionnaireTable extends React.Component<QuestionnaireTableProps, QuestionnaireTableState> {
  public state: QuestionnaireTableState = {
    visiblePopoverId: undefined,
    currentQuestionId: "0",
    currentCaseId: 1,
    currentCase: undefined,
    createCaseModal: {
      visible: false,
      processing: false,
      inputs: {
        caseDate: {
          value: new Date().getTime()
        }
      }
    }
  };

  private commentTextAreaRef?: HTMLTextAreaElement;

  public render() {
    const {
      questionnaire,
      currentSectionIdx,
      answers,
      onAnswerChange,
      componentState,
    } = this.props;
    const { currentCase } = this.state;

    if (componentState.loading) {
      return (
        <Spin>
          <Table locale={{ emptyText: "Sin preguntas" }} />
        </Spin>
      );
    }

    if (questionnaire.sections[currentSectionIdx]) {
      const { questions } = questionnaire.sections[currentSectionIdx];

      return (
        <>
          <CreateCaseModal
            onMetadataChange={this.onCaseMetadataChange}
            onInputChange={this.onInputChange}
            onSubmitCaseForm={this.onSubmitCaseForm}
            onDeleteCase={this.onDeleteCase}
            _case={currentCase as Case}
          />
          <Table
            pagination={false}
            columns={
              [
                {
                  title: "FOTO",
                  dataIndex: "photo",
                   render: (src: string, question: Question) => (
                    <PhotoThumbnail
                      src={(answers[question.id] || {}).src}
                      questionId={question.id}
                      onChangePhoto={this.handleUploadPhoto}
                    />
                  )
                },
                {
                  title: "Nº",
                  dataIndex: "id",
                  render: (id: string, question: Question, idx) => `${parseInt(currentSectionIdx, 10) + 1}.${idx + 1}`
                },
                {
                  title: "PREGUNTA",
                  dataIndex: "displayText"
                },
                {
                  title: "RESPUESTA",
                  dataIndex: "answer",
                  render: (value: boolean | null = null, question: Question) => (
                    <Select
                      style={{ width: "100%" }}
                      value={(answers[question.id] || {}).value}
                      onChange={(selectedValue: AnswerType) => onAnswerChange(question.id, selectedValue)}
                    >
                      <Select.Option value="true">Sí</Select.Option>
                      <Select.Option value="false">No</Select.Option>
                      <Select.Option value="null">No aplica</Select.Option>
                    </Select>
                  )
                },
                {
                  title: "OTROS",
                  render: (_, question: Question, idx) => (
                    <div style={{ display: "flex" }}>
                      {this.renderCommentButton(question.id)}
                      <Button shape="circle" size="large" onClick={() => this.onClickCreateCase(question.id, (answers[question.id] || {})._case)}>
                        { 
                          (answers[question.id] || {}).caseId
                            ? <Icon type="exclamation-circle" theme="filled" style={{ color: "#eb2f96"}} />
                            : <Icon type="exclamation-circle" theme="filled" />
                        }
                      </Button>
                    </div>
                  )
                }
              ]
            }
            dataSource={questions}
          />
        </>
      );
    }

    return null;
  }

  private onClickCreateCase = (questionId: string, _case: object | undefined) => {
    const { setComponentState } = this.props;
    this.setState({ 
      currentQuestionId: questionId,
      currentCase: _case
    });
    setComponentState("CREATE_CASE", { visible: true });
  }

  private onCaseMetadataChange = (type: string, value: CaseType | number) => {
    this.setState((state) => ({
      createCaseModal: {
        ...state.createCaseModal,
        inputs: {
          ...(state.createCaseModal.inputs || {}),
          [type]: { value }
        }
      }
    }));
  }

  private onInputChange = (changedField: object) => {
    this.setState((state) => ({
      createCaseModal: {
        ...state.createCaseModal,
        inputs: {
          ...(state.createCaseModal.inputs || {}),
          ...changedField
        }
      }
    }));
  }

  private onSubmitCaseForm = () => {
    const { createCaseModal, currentQuestionId, currentCaseId, currentCase } = this.state;
    const { createCase, updateCase, onAnswerCaseChange } = this.props;

    const args: { [key: string]: string | number | undefined } = {};
    const inputs = createCaseModal.inputs || {};
    Object.keys(inputs).map((input) => args[input] = inputs[input].value);

    if (
      args.assignedId !== undefined &&
      args.category !== undefined &&
      args.correctiveActions !== undefined &&
      args.description !== undefined &&
      args.implicatedId !== undefined &&
      args.localityId !== undefined &&
      args.location !== undefined &&
      args.reportedBy !== undefined
    ) {
      if (args.caseType !== undefined && args.caseDate !== undefined) {
      
        if (!currentCase) {
          createCase(
            args.additionalComments ? String(args.additionalComments) : undefined,
            String(args.assignedId),
            String(args.category),
            Number(args.caseDate),
            args.caseType as CaseType,
            String(args.correctiveActions),
            String(args.description),
            String(args.implicatedId),
            String(args.localityId),
            String(args.location),
            String(args.reportedBy),
            true
          );

          // TODO: get id when save case
          args.id = currentCaseId;
          this.setState({ currentCaseId: currentCaseId + 1 });
        }
        else
          updateCase( (currentCase as Case).id , args );
        
        onAnswerCaseChange(currentQuestionId, String(currentCaseId), args);
      } else {
        if (args.caseType === undefined) {
          AntModal.error({
            title: "Información requerida",
            content: cases.formErrors.caseType
          });
        }
      }
    }
  }

  private onDeleteCase = () => {
    const { currentQuestionId, currentCaseId } = this.state;
    const { onAnswerCaseChange, setComponentState } = this.props;

    onAnswerCaseChange(currentQuestionId, String(currentCaseId), {}, true);
    setComponentState("CREATE_CASE", { visible: false });
  }

  private renderCommentButton = (questionId: string) => {
    const { visiblePopoverId } = this.state;
    const { answers } = this.props;

    const PopoverTitle = () => (
      <div style={{
        padding: "6px 0",
        textAlign: "right"
      }}>
        <Button
          style={{ marginRight: 10 }}
          onClick={() => this.handleVisibleChange(questionId)}
        >
          Cancelar
        </Button>
        <Button
          type="primary"
          onClick={() =>
            this.handleSaveComment(
              questionId,
              this.commentTextAreaRef && this.commentTextAreaRef.value
            )
          }
        >
          Guardar
        </Button>
      </div>
    );

    const PopoverContent = () => (
      <div style={{ width: 350 }}>
        <Input.TextArea
          ref={(textArea: TextArea | null) => this.setTextAreaRef(textArea, questionId)}
          rows={3}
          defaultValue={(answers[questionId] || {}).comment}
          onPressEnter={() =>
            this.handleSaveComment(
              questionId,
              this.commentTextAreaRef && this.commentTextAreaRef.value
            )
          }
        />
      </div>
    );

    return (
      <Popover
        placement="left"
        trigger="click"
        onVisibleChange={(_) => this.handleVisibleChange(questionId)}
        title={<PopoverTitle />}
        content={<PopoverContent />}
        visible={visiblePopoverId === questionId}
      >
        <Button shape="circle" size="large" style={{ marginRight: 10 }}>
          {
            (answers[questionId] || {}).comment 
              ? <Icon type="file" theme="filled" style={{ color: "#00548F" }} />
              : <Icon type="file" theme="filled" />
          }
        </Button>
      </Popover>
    );
  }

  private setTextAreaRef = (textArea: any, id: string) => {
    const { visiblePopoverId } = this.state;

    if (visiblePopoverId === id) {
      this.commentTextAreaRef = textArea && textArea.textAreaRef;
    }
  }

  private handleVisibleChange = (id: string) =>
    this.setState((state) => {
      if (state.visiblePopoverId !== id) {
        return {
          visiblePopoverId: id
        };
      } else {
        return {
          visiblePopoverId: undefined
        };
      }
    })

  private handleSaveComment = (questionId: string, comment?: string) => {
    const { onAnswerCommentChange } = this.props;
    onAnswerCommentChange(questionId, comment);
    this.handleVisibleChange(questionId);
  }

  private handleUploadPhoto = (questionId: string, ev: any) => {
    if (questionId != "-1") {
      this.setState({ 
        currentQuestionId: questionId
      });
    } else {
      const { currentQuestionId } = this.state;
      const { onAnswerPhotoChange, uploadImage } = this.props;
  
      const file = ev.target.files[0];
      
      // upload here
      uploadImage(file);

      // TODO: replace file with the url of uploadImage 
      onAnswerPhotoChange(currentQuestionId, file);
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    uploadImage: inspectionActions.uploadImage.request,
    createCase: caseActions.createCase.request,
    updateCase: caseActions.updateCase.request,
  }, dispatch);

const ConnectedQuestionnaireTable = connect(null, mapDispatchToProps)(QuestionnaireTable);

export default withUIState("QUESTIONNAIRE_TABLE", ConnectedQuestionnaireTable);
