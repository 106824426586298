import React from "react";
import { Button, Row, Col, Icon } from "antd";
import { Link } from "react-router-dom";

import { Content, LoadingContent } from "../../atoms";
import { PageHeading, QuestionnaireCard } from "../../molecules";
import { QuestionnairesPageProps } from "../../pages/QuestionnairesPage";
import { Questionnaire } from "../../../types/Questionnaire";
import withUIState, { ComponentProps } from "../../HOCs/withUIState";

type QuestionnairesTemplateProps = QuestionnairesPageProps & ComponentProps;

class QuestionnairesTemplate extends React.Component<QuestionnairesTemplateProps> {
  public render() {
    const { componentState } = this.props;

    return (
      <Content>
        <PageHeading
          title="Cuestionarios"
          renderRight={() => (
            <Link to="../cuestionarios/nuevo">
              <Button
                id="createQuestionnaireBtn"
                type="primary"
                size="large"
                style={{ borderRadius: 8 }}
              >
                <Icon type="plus-circle" theme="filled" />
                Nuevo cuestionario
              </Button>
            </Link>
          )}
        />
        {this.renderContent(componentState.loading)}
      </Content>
    );
  }

  private renderContent = (loading: boolean = true) => {
    const { questionnaires } = this.props;

    if (loading) {
      return <LoadingContent />;
    }

    return (
      <div style={{ padding: 30 }}>
        {questionnaires.map(this.renderQuestionnairesRow)}
      </div>
    );
  }

  private renderQuestionnairesRow = (questionnaires: Questionnaire[]) =>
    <Row gutter={24} style={{ marginBottom: 24 }}>
      {questionnaires.map(this.renderQuestionnaireCard)}
    </Row>

  private renderQuestionnaireCard = (questionnaire: Questionnaire) =>
    <Col span={8}>
      <Link to={`../cuestionarios/${questionnaire.id}`}>
        <QuestionnaireCard
          title={questionnaire.title}
          description={questionnaire.description}
          sections={(questionnaire.sections || []).length}
          questions={(questionnaire.questions || []).length}
        />
      </Link>
    </Col>
}

export default withUIState("QUESTIONNAIRES", QuestionnairesTemplate);
