import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { RootState } from "../../store/modules";
import { withPageTitle } from "../HOCs";
import { ACRsTemplate } from "../templates";
import { actions as ACRActions } from "../../store/modules/ACR/actions";
import { actions as UIActions } from "../../store/modules/UI/actions";
import * as acrSelectors from "../../store/modules/ACR/selectors";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type ACRsPageProps = StateProps & DispatchProps;

class ACRsPage extends React.Component<ACRsPageProps> {
  public componentDidMount() {
    const { loadACRs } = this.props;
    loadACRs("ACRS_TABLE");
  }

  public render() {
    return <ACRsTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: RootState) => ({
  ACRs: acrSelectors.selectAcrState(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadACRs: ACRActions.loadACRs.request,
    createACR: ACRActions.createACR.request,
    setComponentState: UIActions.setComponentState,
    push
  }, dispatch);

const ConnectedACRsPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPageTitle(ACRsPage, "ACR"));

export default ConnectedACRsPage;
