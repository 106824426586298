import React from "react";
import { Button } from "antd";
import { FormProps } from "antd/lib/form";

import { Modal } from "../../../atoms";
import withUIState, { ComponentProps } from "../../../HOCs/withUIState";
import { CreateInspectionForm } from "../../../organisms/Forms";

interface CreateInspectionModalOwnProps {
  onInputChange: (changedField: object) => void;
  onSubmitInspectionForm: () => void;
}

type CreateInspectionModalProps = CreateInspectionModalOwnProps & ComponentProps;

class CreateInspectionModal extends React.Component<CreateInspectionModalProps> {
  private createInspectionForm?: React.Component<FormProps>;

  public render() {
    const { componentState, onInputChange, onSubmitInspectionForm } = this.props;

    return (
      <Modal
        title={
          <div style={{ fontSize: 30, fontWeight: 600, paddingTop: 30 }}>
            <span>Nueva inspección</span>
          </div>
        }
        width={920}
        footer={[
          <Button
            id="cancelInspectionBtn"
            size="large"
            onClick={this.closeModal}
          >
            Cancelar
          </Button>,
          <Button
            id="saveInspectionBtn"
            type="primary"
            size="large"
            form="createInspection"
            htmlType="submit"
            loading={componentState.processing}
          >
            Guardar
          </Button>
        ]}
        visible={componentState.visible}
        onCancel={this.closeModal}
        closable={false}
        centered
      >
        <CreateInspectionForm
          id="createInspection"
          wrappedComponentRef={(form: React.Component<FormProps>) => (this.createInspectionForm = form)}
          onChange={onInputChange}
          onSubmit={onSubmitInspectionForm}
        />
      </Modal>
    );
  }

  private closeModal = () => {
    const { setComponentState } = this.props;
    setComponentState("CREATE_INSPECTION", { visible: false, processing: false });

    if (this.createInspectionForm && this.createInspectionForm.props.form) {
      this.createInspectionForm.props.form.resetFields();
    }
  }
}

export default withUIState("CREATE_INSPECTION", CreateInspectionModal);
