import React from "react";
import { Provider } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Layout, LocaleProvider } from "antd";
import es_ES from "antd/lib/locale-provider/es_ES";
import moment from "moment";
import "moment/locale/es";

import { ErrorBoundary } from "./bugsnag";
import store, { history } from "./store";
import { Sidebar, ErrorBoundaryFallback } from "./components/organisms";
import * as Pages from "./components/pages";

moment.locale("es");

class App extends React.Component {
  public render() {
    return (
      <LocaleProvider locale={es_ES}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <Layout style={{ height: "100%" }}>
                <Layout>
                  <Sidebar />
                  <Switch>
                    <Route exact path="/" component={() => <Redirect to="/inspecciones" />} />
                    <Route exact path="/inspecciones" component={Pages.InspectionsPage} />
                    <Route path="/inspecciones/:id" component={Pages.InspectionDetailsPage} />
                    <Route exact path="/casos" component={Pages.CasesPage} />
                    <Route path="/casos/:id" component={Pages.CaseDetailsPage} />
                    <Route exact path="/acrs" component={Pages.ACRsPage} />
                    <Route path="/acrs/:id" component={Pages.ACRDetailsPage} />
                    <Route exact path="/socios" component={Pages.StrategicPartnersPage} />
                    <Route path="/socios/:id" component={Pages.StrategicPartnerDetailsPage} />
                    <Route exact path="/localidades/:id" component={Pages.LocalityDetailsPage} />
                    <Route path="/localidades" component={Pages.LocalitiesPage} />
                    <Route path="/usuarios" component={Pages.UsersPage} />
                    <Route exact path="/cuestionarios/nuevo" component={Pages.QuestionnairePage} />
                    <Route exact path="/cuestionarios" component={Pages.QuestionnairesPage} />
                    <Route path="/cuestionarios/:id" component={Pages.QuestionnaireDetailsPage} />
                    <Route component={Pages.NotFoundPage} />
                  </Switch>
                </Layout>
              </Layout>
            </ErrorBoundary>
          </ConnectedRouter>
        </Provider>
      </LocaleProvider>
    );
  }
}

export default App;
