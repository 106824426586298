import React from "react";
import { Select } from "antd";

const { Option } = Select;

import styles from "./styles.less";

interface Option {
  value: string;
  label: string;
}

export interface Options {
  [id: number]: Option;
}

interface TableFilterSelectProps {
  onChange?: any; // The function to excecute on this event
  options: Options;
  defaultValue?: string;
}

/**
 * Returns an array of Option components through the
 * provided options
 * @param options Values to populate the component
 */
const getOptions = (options: Options) => {
  const selectOptions = Object.values(options).map((item, index) =>
    (<Option
    className={styles.TableFilterSelectOption}
    value={item.value}
    key={index.toString()}>
    {item.label}
    </Option>));
  return selectOptions;
};

/**
 * Retrieves a Select component populated with the Options received
 * @param props Props
 * - onChange: A function that will be executed when the onChange event fires
 * - options: An interface Options object with the options to display
 * in the list
 * - defaultValue: Specifies which option will be selected by default
 */
const TableFilterSelect: React.FC<TableFilterSelectProps> = (
  { onChange, options, defaultValue }) => {
  return (
    <Select
    defaultValue={defaultValue}
    // tslint:disable-next-line: no-unsafe-any
    className={styles.TableFilterSelect}
    // tslint:disable-next-line: no-unsafe-any
    onChange={onChange}>
      {getOptions(options)}
    </Select>
  );
};

export default TableFilterSelect;

/*const option: Option = { value: "Some", label: "Some" };
  const selectOptions: Options = [
    { value: "Todas", label: "Todas" },
    { value: "Activos", label: "Activos" },
    { value: "Suspendidos", label: "Suspendidos" }
  ];
  */
