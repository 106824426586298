import { ActionType, createAction } from "typesafe-actions";

import { StrategicPartners, StrategicPartner } from "../../../types/StrategicPartner";
import { object } from "prop-types";

/**
 * Loads the strategic partner list
 */
const loadStrategicPartners = {
  request: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNERS_REQUEST", (action) =>
    (componentId?: string) => action({ componentId })),
  success: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNERS_SUCCESS", (action) =>
    (strategicPartners: StrategicPartners) => action({ strategicPartners })),
  error: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNERS_ERROR", (action) =>
    (error: any) => action({ error }))
};

/**
 * Load a single strategic partner
 */
const loadStrategicPartner = {
  request: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNER_REQUEST", (action) =>
    (strategicPartnerId: string, componentId?: string) => action({ strategicPartnerId, componentId })),
  success: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNER_SUCCESS", (action) =>
    (strategicPartner: StrategicPartner ) => action({ strategicPartner })),
  error: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNER_ERROR", (action) =>
    (error: any) => action({ error }))
};

const loadStrategicPartnerCases = {
  request: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNER_CASES_REQUEST", (action) =>
    (strategicPartnerId: string, componentId?: string) => action({ strategicPartnerId, componentId })),
  success: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNER_CASES_SUCCESS", (action) =>
    (strategicPartner: StrategicPartner ) => action({ strategicPartner })),
  error: createAction("@strategicPartner/LOAD_STRATEGIC_PARTNER_CASES_ERROR", (action) =>
    (error: any) => action({ error }))
};

export const actions = {
  loadStrategicPartners,
  loadStrategicPartner,
  loadStrategicPartnerCases
};

export type StrategicPartnerAction = ActionType<typeof actions>;
