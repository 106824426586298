import React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { push as pushAction } from "connected-react-router";
import { Tag } from "antd";
import { getHumanDate } from "../../../../utils/date";
import { ColumnProps } from "antd/lib/table/interface";

import {
  StrategicPartnerCases
} from "../../../../types/StrategicPartner";

import { Table } from "../../../atoms";
import { inspectionStatus, caseTypes } from "../../../../utils/maps";

import { default as withUIState, ComponentProps } from "../../../HOCs/withUIState";

interface IStrategicPartnersDetailsTableProps {
  strategicPartnerCases: StrategicPartnerCases;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type StrategicPartnersTableProps = IStrategicPartnersDetailsTableProps & DispatchProps & ComponentProps;

class StrategicPartnersTable extends React.Component<StrategicPartnersTableProps> {

  // this is the definition of the table columns as Ant expects
  private tableColumns: Array<ColumnProps<any>> = [
    {
      title: "NOMBRE",
      dataIndex: "inspection.title",
      key: "inspection.title",
      align: "center",
    },
    {
      title: "FECHA",
      dataIndex: "inspection.date",
      key: "inspection.date",
      align: "center",
      render: (date: number) => date
        ? getHumanDate(date)
        : <Tag color="volcano">Fecha no especificada</Tag>
    },
    {
      title: "TIPO",
      dataIndex: "caseType",
      key: "caseType",
      align: "center",
      render: (caseType: string) => caseTypes[caseType]
    },
    {
      title: "STATUS",
      dataIndex: "inspection.status",
      key: "inspection.status",
      align: "center",
      render: (statusKeyName: string) =>
            <Tag color={ this.getStatusTagColor(statusKeyName) }>
              {inspectionStatus[statusKeyName]}
            </Tag>
    }
  ];

  public render() {
    const { push, componentState, strategicPartnerCases } = this.props; // props given by the parent page
    return (
      <Table
        loading={ componentState.loading }
        pagination={false}
        columns={this.tableColumns}
        rowKey={(_, idx) => String(idx)}
        dataSource={ Object.values(strategicPartnerCases) } // this is needed because normalizr
        locale={{ emptyText: "Sin datos" }}
        scroll={{ x: false, y: false }}
      />
    );
  }

  /**
   * Retuns the color acording the status string provided
   * @param status the status string
   * @returns a color name
   */
  private getStatusTagColor = (status: string) => {
    switch (status) {
      case "RESOLVED":
        return "green";
      case "UNRESOLVED":
        return "volcano";
        default:
          return "";
    }
  }
}



const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    push: pushAction
  }, dispatch);

const ConnectedStrategicPartnerTable = connect(undefined, mapDispatchToProps)(StrategicPartnersTable);

// SPECIFY STATE STORAGE TO USE WITH THIS COMPONENT
export default withUIState("STRATEGIC_PARTNER_DETAILS", ConnectedStrategicPartnerTable);
