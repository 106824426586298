import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteChildrenProps } from "react-router";
import { push } from "connected-react-router";

import { RootState } from "../../store/modules";
import { actions as ACRActions } from "../../store/modules/ACR/actions";
import { actions as UIActions } from "../../store/modules/UI/actions";
import * as ACRSelectors from "../../store/modules/ACR/selectors";
import { ACRDetailsTemplate } from "../templates";
import withPageTitle from "../../components/HOCs/withPageTitle";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type ACRDetailsPageProps = StateProps & DispatchProps & RouteChildrenProps<{ id: string }>;

class ACRDetailsPage extends React.Component<ACRDetailsPageProps> {
  public componentDidMount() {
    const { ACR, loadACR, match } = this.props;
    if (!ACR && match) {
      loadACR(match.params.id);
    }
  }

  public render() {
    return (
      <ACRDetailsTemplate {...this.props} />
    );
  }
}

const mapStateToProps = (state: RootState, props: any) => ({
  ACR: ACRSelectors.selectAcr(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadACR: ACRActions.loadACR.request,
    setComponentState: UIActions.setComponentState,
    updateACR: ACRActions.updateACR.request,
    deleteACR: ACRActions.deleteACR.request,
    push
  }, dispatch);

const ConnectedACRDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ACRDetailsPage);

export default withPageTitle(ConnectedACRDetailsPage, "Acciones caso raíz");
