import React from "react";

import styles from "./styles.less";
import { Content, LoadingContent, Card, Title } from "../../atoms";
import { Button, Icon, Modal } from "antd";
import { QuestionnaireDetailsPageProps } from "../../pages/QuestionnaireDetailsPage";
import { PageHeading } from "../../molecules";
import { Section, Question } from "../../../types/Questionnaire";
import withUIState, { ComponentProps } from "../../HOCs/withUIState";

type QuestionnaireDetailsTemplateProps = QuestionnaireDetailsPageProps & ComponentProps;

class QuestionnaireDetailsTemplate extends React.Component<QuestionnaireDetailsTemplateProps> {
  public render() {
    const { questionnaire } = this.props;

    if (questionnaire) {
      return (
        <Content>
          <PageHeading
            title={questionnaire.title}
            backTo="../cuestionarios"
            renderRight={this.headerOptions}
          />
          <div style={{ padding: 30 }}>
            <div style={{ marginBottom: 20 }}>
              <Card>
                <Title size={18}>Descripción del cuestionario</Title>
                <div>{questionnaire.description}</div>
              </Card>
            </div>
            {(questionnaire.sections as Section[]).map(this.renderSection)}
          </div>
        </Content>
      );
    }

    return <LoadingContent />;
  }

  /**
   * Returns a stateless component
   */
  private headerOptions = () => {
    const { push } = this.props;
    return (
      <div style={{ display: "flex", width: 320 }}>
        <Button
          type="primary"
          shape="circle"
          style={{ marginRight: 20 }}
          onClick={this.showDeleteConfirm}>
          <Icon type="delete" theme="outlined" />
        </Button>
        <Button type="primary" shape="circle" style={{ marginRight: 20 }}>
          <Icon type="edit" theme="outlined" />
        </Button>
        <Button
          onClick={() => {
            push("/cuestionarios");
          }}
          size="large"
          style={{ flex: 1, borderRadius: 8 }}
          block
        >
          Cerrar
      </Button>
      </div>
    );
  }

  /**
   * Display a confirmation modal
   */
  private showDeleteConfirm = () => {
    const onDelete = this.deleteQuestionnaire;
    Modal.confirm({
      title: '¿Seguro que deseas eliminar el cuestionario?',
      content: 'Este proceso es irreversible, ¿Quieres proseguir?',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        onDelete();
      },
    });
  }

  /**
   * Removes the selected questionnaire
   */
  private deleteQuestionnaire = () => {
    let { questionnaire, deleteQuestionnaire } = this.props;

    deleteQuestionnaire(questionnaire.id, "QUESTIONNAIRE_DETAILS");
  }

  private renderSection = (section: Section, idx: number) =>
    <div style={{ marginBottom: 20 }}>
      <Card>
        <Title size={18}>Sección {idx + 1} – {section.title}</Title>
        {section.questions.map((question, questionIdx) => this.renderQuestion(idx, question, questionIdx))}
      </Card>
    </div>

  private renderQuestion = (sectionIdx: number, question: Question, idx: number) =>
    <div className={styles.questionContainer}>
      <div className={styles.questionChip}>{sectionIdx + 1}.{idx + 1}</div>
      <div className={styles.questionText}>{question.displayText}</div>
    </div>
}

export default withUIState("QUESTIONNAIRE_DETAILS", QuestionnaireDetailsTemplate);
