import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { QuestionnairesTemplate } from "../templates";
import { withPageTitle } from "../HOCs";
import { RootState } from "../../store/modules";
import { actions as questionnaireActions } from "../../store/modules/questionnaire/actions";
import * as questionnaireSelectors from "../../store/modules/questionnaire/selectors";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type QuestionnairesPageProps = StateProps & DispatchProps;

class QuestionnairesPage extends React.Component<QuestionnairesPageProps> {
  public componentDidMount() {
    const { loadQuestionnaires } = this.props;
    loadQuestionnaires("QUESTIONNAIRES");
  }

  public render() {
    return <QuestionnairesTemplate {...this.props} />;
  }
}

const mapStateToProps = (state: RootState) => ({
  questionnaires: questionnaireSelectors.selectQuestionnaires(state)
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    loadQuestionnaires: questionnaireActions.loadQuestionnaires.request
  }, dispatch);

const ConnectedQuestionnairesPage =
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withPageTitle(QuestionnairesPage, "Cuestionarios"));

export default ConnectedQuestionnairesPage;
