import React from "react";
import { Pagination } from "antd";

import styles from "./styles.less";

export const itemRenderer = (current: number, type: string, originalElement: React.ReactElement) => {
  if (type === "prev") {
    return <a>Anterior</a>;
  }

  if (type === "next") {
    return <a>Siguiente</a>;
  }

  return originalElement;
};

interface TableFooterProps {
  showing?: number;
  total?: number;
  label?: string;
  pagination?: {
    current?: number;
    total: number;
  };
  onChange?: (page: number, pageSize?: number) => void;
}

const TableFooter: React.FC<TableFooterProps> = ({ showing, total, label, pagination, onChange }) =>
  <div className={styles.tableFooter}>
    <div className={styles.tableTotals}>
      Mostrando {showing} de {total} {label}
    </div>
    {pagination && (
      <div className={styles.tablePagination}>
        <Pagination total={pagination.total} itemRender={itemRenderer} onChange={onChange} />
      </div>
    )}
  </div>;

TableFooter.defaultProps = {
  showing: 0,
  total: 0,
  label: "resultados"
};

export default TableFooter;
