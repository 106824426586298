import React from "react";
import { Empty, Layout } from "antd";

import styles from "./styles.less";

class ErrorBoundaryFallback extends React.Component {
  public render() {
    return (
      <Layout.Content className={styles.content}>
        <div className={styles.centered}>
          <Empty description="Hubo un problema, ya ha sido reportado." />
        </div>
      </Layout.Content>
    );
  }
}

export default ErrorBoundaryFallback;
