import React from "react";
import { Empty } from "antd";

const NotFoundPage: React.FC = () =>
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%"
    }}
  >
    <Empty
      description={
        <span style={{ color: "#4F4F4F" }}>
          No encontramos lo que estás buscando.
        </span>
      }
    />
  </div>;

export default NotFoundPage;
