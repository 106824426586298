import { RouteChildrenProps } from "react-router";
import { createSelector } from "reselect";
import { RootState } from "..";
/**
 * Returns the content of the locality state
 * @param state
 */
export const selectLocalityState = (state: RootState) => state.locality;

/**
 * Returns the id specified in the uri route
 */
export const selectLocalityIdFromRouteProps =
(state: RootState, props: RouteChildrenProps<{ id: string }>) => (
  // This double comparition avoid accessing to null objects and return an empty string at least
  (props != null) ?
    (props.match != null) ? props.match.params.id : ""
    : "");

/**
 * Returns all of the localities saved in the
 * locality state
 * @returns Localities
 */
export const selectLocalities = createSelector(
  [selectLocalityState], (localities) => localities
);

/**
 * Returns a locality specified by the ID
 * @returns Locality
 */
export const selectLocality = createSelector(
  [selectLocalityState, selectLocalityIdFromRouteProps],
  (localities, localityId: string) => {
   return localities !== undefined && localities[localityId];
  });
